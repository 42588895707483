import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const AppUserListHeader = ({
  breadCrumbText = [],
  reloadData = () => {},
  bulkUpdateHandler = () => {},
}) => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-2 col-lg-3 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadCrumbText} />
        </div>

        <div className="col-md-10 col-lg-9 d-flex gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-secondary rounded-0 shadow-none"
            onClick={reloadData}
          >
            <i className="material-icons">refresh</i>
          </button>

          <button
            type="button"
            className="btn btn-info rounded-0 shadow-none"
            data-bs-toggle="modal"
            data-bs-target="#appUserFilter"
          >
            <i className="material-icons">filter_alt</i>
          </button>

          <div className="dropdown">
            <button
              className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#addNewEmail"
                >
                  Send Mail
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addTagModal"
                >
                  Add Label
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addToListModal"
                >
                  Add to List
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => {
                    bulkUpdateHandler("1", true);
                  }}
                >
                  Update Ranking
                </Link>
              </li>

              {/* <li>
                  <Link className="dropdown-item" to="#">
                    Connect with me
                  </Link>
                </li> */}

              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => {
                    bulkUpdateHandler("1", false);
                  }}
                >
                  Enable User
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => {
                    bulkUpdateHandler("0", false);
                  }}
                >
                  Disable User
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserListHeader;
