/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CommonLayout from "Components/Common/Layout/CommonLayout";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import SurveyCommonHeader from "../Common/SurveyCommonHeader";
import AddTagModal from "Components/Common/Modal/AddTagModal";
import SurveyResponseHeader from "./Header/SurveyResponseHeader";
import SurveyResponseFilterModal from "./Modal/SurveyResponseFilterModal";

const SurveyResponseBody = () => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation(); //for translation

  const [name, setname] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [inviteList, setinviteList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedInviteIds, setSelectedInviteIds] = useState([]);

  const [tableColumns, setTableColumns] = useState([
    {
      accessorKey: "inviteduser",
      header: t("Name"),

      Cell: ({ row }) => (
        <div className="name border-bottom-0">
          <div className="d-flex align-items-center gap-4">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "35px", height: "35px" }}
              >
                <img
                  src={
                    row.original.inviteuserimage == ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.inviteuserimage
                  }
                  alt="Richard Hawail"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.invitedusername}
                </p>
                <p>{row.original.email}</p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      accessorKey: "responsedate",
      header: t("Response Date"),
    },
    //next question
    {
      accessorKey: "tags",
      header: t("Tags"),

      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
  ]);

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterName, setfilterName] = useState("");
  const [filterEmail, setfilterEmail] = useState("");
  const [filterTags, setfilterTags] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* ---- bread crumb text ---- */
  const breadCrumbText = [
    { title: t("Survey"), link: "/admin/survey/manage" },
    { title: `${t("Responses")}  /  ${name}` },
  ];

  //function for get survey details
  const getSurveyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setname(response.data.name);

        if (
          response.data.questionlist &&
          response.data.questionlist.length > 0
        ) {
          const updateQuestionList = response.data.questionlist.map(
            (question) => {
              return {
                accessorKey: question.code,
                header: question.name,
                size: 300,
              };
            }
          );

          setTableColumns((prevColumns) => [
            ...prevColumns,
            ...updateQuestionList,
          ]);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all invites
  const getAllResponses = async () => {
    setRowSelection({});
    setSelectedInviteIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_SURVEY_RESPONSE +
        `?token=${token}&filtersurveyid=${params.id}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterEmail != "") {
        requestUrl = requestUrl + `&filteremail=${filterEmail}`;
      }

      if (filterName != "") {
        requestUrl = requestUrl + `&filtername=${filterName}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setinviteList(response.data);
        resetFilter();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete survey invites
  const deleteMassiveSurveyInviteHandler = async () => {
    if (selectedInviteIds.length > 0) {
      const surveyInviteListToBeDelete = inviteList.filter((item) =>
        selectedInviteIds.includes(item._id)
      );

      try {
        let surveyData = {
          surveyinvitelist: surveyInviteListToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_MASSIVE_INVITES + `?token=${token}`;

        const response = await putData(requestURL, surveyData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllResponses();
          }, 500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //reset all filter states
  const resetFilter = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterName("");
    setfilterEmail("");
    setfilterTags([]);
  };

  useEffect(() => {
    if (
      (filterStartDate !== "" && filterEndDate !== "") ||
      filterEmail != "" ||
      filterName != "" ||
      filterTags.length > 0
    ) {
      getAllResponses();
    }
  }, [filterName, filterEmail, filterStartDate, filterEndDate, filterTags]);

  useEffect(() => {
    getSurveyDetails();
    getAllResponses();
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(() => tableColumns, [tableColumns, i18n.language]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInviteIds(selectedIdsArray);
    } else {
      setSelectedInviteIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <SurveyCommonHeader moduleName="response" />

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <SurveyResponseHeader
            breadCrumbText={breadCrumbText}
            deleteHandler={deleteMassiveSurveyInviteHandler}
            reloadList={getAllResponses}
          />

          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={inviteList} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>

      {/* ---- add label popup --- */}
      <AddTagModal
        moduleName="surveyresponse"
        selectedIds={selectedInviteIds}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllResponses();
          }, 2000);
        }}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <SurveyResponseFilterModal
        setfilterStartDate={setfilterStartDate}
        setfilterEndDate={setfilterEndDate}
        setfilterName={setfilterName}
        setfilterEmail={setfilterEmail}
        setfilterTags={setfilterTags}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default SurveyResponseBody;
