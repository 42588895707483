/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData, uploadSingleFile } from "Utils/Gateway";

import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";
import { joditEditorConfig, reactSelectStyle } from "Config/Config";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import languageOptions from "data/Prod/LanguageOptions.json";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";

const AppUserPersonalInfoBody = () => {
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App user"), link: "/admin/contact/allusers" },
    { title: t("Personal Info") },
  ];

  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const editor = useRef(null);

  const editorCustomConfig = joditEditorConfig();

  const editorConfig = useMemo(() => editorCustomConfig, []);

  const [skillList, setskillList] = useState([]);
  const [serviceList, setserviceList] = useState([]);

  const [profilePic, setProfilePic] = useState("");
  const [profilePicId, setProfilePicId] = useState(null);
  const [userRole, setuserRole] = useState("");
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [position, setPosition] = useState("");
  const [address, setAddress] = useState("");
  const [stateName, setstateName] = useState("");
  const [cityName, setcityName] = useState("");
  const [country, setcountry] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [rate, setRate] = useState("");
  const [telephone, setTelephone] = useState("");
  const [website, setWebsite] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const [skillValue, setSkillValue] = useState(null);
  const [skills, setSkills] = useState([]);
  const [serviceValue, setServiceValue] = useState(null);
  const [services, setServices] = useState([]);
  const [languageValue, setLanguageValue] = useState(null);
  const [language, setLanguage] = useState("");
  const [longitude, setlongitude] = useState("");
  const [latitude, setlatitude] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all skills
  const getAllSkills = async () => {
    try {
      const parentSlug = "CAT_SKILL";

      const response = await getAllCategories(parentSlug);

      if (response) {
        setskillList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all services
  const getAllServices = async () => {
    try {
      const parentSlug = "CAT_SERVICES";

      const response = await getAllCategories(parentSlug);

      if (response) {
        setserviceList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get user details
  const getUserProfileDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        const data = response.data;

        if (data) {
          setProfilePic(data.userimagepath || ""); // Ensure default value if undefined
          setProfilePicId(data.photoimage || null); // Ensure default value if undefined
          setuserRole(data.userrole || "");
          setName(data.name || "");
          setSurName(data.surname || "");
          setTelephone(data.phone || "");
          setPosition(data.position || "");
          setUserDetails(data.about || "");
          setAddress(data.address || "");
          setWebsite(data.website || "");
          setRate(data.costperhour || "0");
          setzipcode(data.zipcode || "");
          setcountry(data.country || "");
          setstateName(data.state || "");
          setcityName(data.city || "");
          setlongitude(data.longitude || "");
          setlatitude(data.latitude || "");

          if (data.language) {
            const [language] = languageOptions.filter(
              (item) => item.value === data.language
            );

            languageSelectionHandler(language);
          }

          if (data.userskills) {
            skillSelectionHandler(data.userskills);
          }

          if (data.userservices) {
            serviceSelectionHandler(data.userservices);
          }
        }
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
      setShowAlert(true);
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  //function for upload cover image
  const profileImageUploadHandler = async (e) => {
    const file = e.target.files[0];

    setIsImageUploading(true);

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      // console.log("upload response-------->", response);

      if (response.status) {
        setIsImageUploading(false);
        setProfilePic(response.data.path);
        setProfilePicId(response.data._id);
        resetProfileImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetProfileImageFile = () => {
    const file = document.getElementById("Accounts_photo_file");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select skill
  const skillSelectionHandler = (val) => {
    setSkillValue(val);
    setSkills(val.map((item) => item.value));
  };

  //function for select services
  const serviceSelectionHandler = (val) => {
    setServiceValue(val);
    setServices(val.map((item) => item.value));
  };

  //function for select language
  const languageSelectionHandler = (val) => {
    if (val) {
      setLanguageValue(val);
      setLanguage(val.value);
    } else {
      setLanguageValue(null);
      setLanguage("");
    }
  };

  //function for save/update user
  const saveUserHandler = async () => {
    try {
      setIsSaving(true);
      let userData = {
        photoimage: profilePicId,
        name,
        surname: surName,
        position,
        language,
        phone: telephone,
        address,
        about: userDetails,
        city: cityName,
        state: stateName,
        country,
        zipcode,
        costperhour: rate,
        website,
        skills,
        services,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestURL =
          requestURL +
          url.API_UPDATE_APP_USER +
          `/${params.id}` +
          `?token=${token}`;

        response = await putData(requestURL, userData);
      }

      setIsSaving(false);
      console.log(response);

      if (response.status) {
        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllSkills();
    getAllServices();
  }, []);

  useEffect(() => {
    if (params.id) {
      getUserProfileDetails();
    }
  }, [params.id]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <AppUserDetailsLayout
      breadcrumbText={breadcrumbText}
      moduleName="personalInfo"
    >
      <div className="profile_tabInnr">
        {/* <!-- ========== Start profile basic input Section ========== --> */}
        <div className="personel_profile_area">
          <div className="prsnl_profile_pic" id="profile-info-photo">
            <figure>
              <img
                src={
                  profilePic === ""
                    ? "/images/default-user.png"
                    : url.SERVER_URL + profilePic
                }
                id="photopreview"
              />
            </figure>
            <div className="upload_camera">
              <a href="#" className="upload_files">
                <div
                  id="Accounts_photo"
                  className=" upload_btn"
                  placeholder="photo"
                >
                  <div>
                    <input
                      type="file"
                      id="Accounts_photo_file"
                      className=""
                      placeholder=""
                      onChange={profileImageUploadHandler}
                    />
                  </div>
                </div>
                <span className="material-icons-outlined">photo_camera</span>
              </a>
              <Link
                to="#"
                className="upload_delete"
                onClick={() => {
                  setProfilePic("");
                  setProfilePicId(null);
                }}
              >
                <span className="material-icons-outlined">delete</span>
              </Link>
            </div>
          </div>

          <div className="personl_profiledetails">
            <div className="profile_owner_outer">
              <div className="profile_owner_text">
                <h2>
                  {name}
                  <span className="ms-1">{surName}</span>
                </h2>

                <span className="material-icons-outlined ">
                  manage_accounts
                </span>
                <span className="headinfo">{userRole}</span>

                <br />
                <span className="material-icons-outlined ">savings</span>

                <span className="headinfo" id="profile-info-points">
                  {" "}
                  Points
                </span>
              </div>
            </div>
            <div className="profile_social">
              <ul></ul>
            </div>
          </div>
        </div>
        {/* <!-- ========== End profile basic input Section ========== --> */}

        <div className="profile_graybx_Outer">
          <div className="profile_graybx_item">
            <div className="profile_fillOuter">
              {/* <!-- ========== Start name and surname Section ========== --> */}
              <div className="profile_fill_row">
                <label>Name</label>
                <div className="input_fill" style={{ marginRight: "2%" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <label>Surname</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter surname"
                    value={surName}
                    onChange={(e) => setSurName(e.target.value)}
                  />
                </div>
              </div>
              {/* <!-- ========== End name and surname Section ========== --> */}

              {/* <!-- ========== Start position Section ========== --> */}
              <div className="profile_fill_row">
                <label>Position</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter position"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>
              {/* <!-- ========== End position Section ========== --> */}

              <div className="profile_fill_row">
                <label>Skills</label>
                <div className="input_fill mt-1">
                  <Select
                    isMulti
                    styles={reactSelectStyle("6px")}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("Select Skills")}
                    options={skillList}
                    value={skillValue}
                    onChange={(val) => {
                      skillSelectionHandler(val);
                    }}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Address</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>City</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter City"
                    value={cityName}
                    onChange={(e) => setcityName(e.target.value)}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>State</label>
                <div className="input_fill">
                  <input
                    type="text"
                    value={stateName}
                    onChange={(e) => setstateName(e.target.value)}
                    className="form-control"
                    placeholder="Enter State"
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Country </label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Country"
                    value={country}
                    onChange={(e) => setcountry(e.target.value)}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Cpostal </label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zipcode"
                    value={zipcode}
                    onChange={(e) => setzipcode(e.target.value)}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Rate / Hour</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter rate")}
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Phone</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter phone number")}
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Website</label>
                <div className="input_fill">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Enter website")}
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Language</label>
                <div className="input_fill mt-1">
                  <Select
                    styles={reactSelectStyle("6px")}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("Select Language")}
                    options={languageOptions}
                    value={languageValue}
                    onChange={(val) => {
                      languageSelectionHandler(val);
                    }}
                  />
                </div>
              </div>

              {/* <div className="profile_fill_row">
                <label>Expertise</label>
                <div className="input_fill">
                  <input
                    type="number"
                    value=""
                    className="form-control"
                    placeholder="Enter Expertise"
                  />
                </div>
              </div> */}

              <div className="profile_fill_row">
                <label>Services</label>
                <div className="input_fill mt-1">
                  <Select
                    isMulti
                    styles={reactSelectStyle("6px")}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    placeholder={t("Select Services")}
                    options={serviceList}
                    value={serviceValue}
                    onChange={(val) => {
                      serviceSelectionHandler(val);
                    }}
                  />
                </div>
              </div>

              <div className="profile_fill_row">
                <label>Latitude</label>
                <div className="input_fill" style={{ marginRight: "2%" }}>
                  <input
                    type="text"
                    placeholder={t("Latitude")}
                    className="form-control"
                    value={latitude}
                    onChange={(e) => setlatitude(e.target.value)}
                  />
                </div>

                <label>Longitude</label>
                <div className="input_fill">
                  <input
                    type="text"
                    placeholder={t("Longitude")}
                    className="form-control"
                    value={longitude}
                    onChange={(e) => setlongitude(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="profile_graybx_item">
            <div className="profile_fillOuter">
              <div className="profile_fill_row">
                <div className="input_fill description_fill">
                  <label>About</label>
                  <JoditEditor
                    ref={editor}
                    value={userDetails}
                    config={editorConfig}
                    onBlur={(newContent) => {
                      // console.log("New content in on blur----->", newContent);
                      setUserDetails(newContent);
                    }}
                    onChange={(newContent, event) => {
                      // setMailDescription(newContent);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="prfile_btnOuter">
          <div className="profile_leftBtn"></div>
          <div className="profile_rightBtn">
            <Link
              to="/admin/contact/allusers"
              className="basic_btn cancel_btn"
              type="button"
            >
              Cancel
            </Link>
            <button
              className="basic_btn ylw_btn"
              type="button"
              onClick={saveUserHandler}
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </AppUserDetailsLayout>
  );
};

export default AppUserPersonalInfoBody;
