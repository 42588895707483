import React from "react";

const AddCsvModal = () => {
  return (
    <div
      className="modal fade"
      id="uploadCsvModal"
      aria-labelledby="uploadCsvModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="modal-title w-100" id="uploadCsvModalLabel">
              Upload File XLS/CSV
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 m-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-row mb-4">
                <label htmlFor="csvFile" className="fw-bold mb-2">
                  Select File
                </label>
                <input
                  type="file"
                  name="faqcsv"
                  id="csvFile"
                  className="form-control text-green rounded-0"
                  required=""
                />
              </div>

              <div className="form-action d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="btn btn-gray m-0 me-3 rounded-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-yellow d-flex align-items-center rounded-0 m-0"
                >
                  Upload
                  {/* <div
                    className="FaqUpload-Busy spinner-border spinner-border-sm text-dark upload-csv-loading"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div> */}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCsvModal;
