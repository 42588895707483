/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const SaveModuleRoleModal = ({
  afterModalCLose,
  moduleRoleId,
  setModuleRoleId,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t, i18n } = useTranslation(); //for translation

  const [moduleList, setModuleList] = useState([]);

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");

  const [moduleValue, setmoduleValue] = useState(null);
  const [selectedModule, setselectedModule] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    modulewarning: false,
  });

  /* function to validate form */
  const validate = () => {
    let isValid = true;

    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    if (selectedModule === null) {
      setValidation((prevState) => ({ ...prevState, modulewarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //get all modules
  const getAllModules = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULES +
        `?token=${token}&filterfeatured=true`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setModuleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select module
  const moduleSectionHandler = (val) => {
    if (val) {
      setValidation((prevState) => ({
        ...prevState,
        modulewarning: false,
      }));
      setmoduleValue(val);
      setselectedModule(val.value);

      if (name !== "") {
        setSlug(`${val.slug}_${name === "" ? "" : name.toUpperCase()}`);
      }
    } else {
      setmoduleValue(null);
      setselectedModule(null);
    }
  };

  //get details
  const getDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_MODULE_ROLE_DETAILS +
        `/${moduleRoleId}` +
        `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setName(response.data.name);
        setSlug(response.data.slug);
        moduleSectionHandler(response.data.moduledata);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveHandler = async () => {
    if (validate()) {
      setIsSaving(true);
      try {
        let sendingData = {
          name,
          slug,
          module: selectedModule,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (moduleRoleId) {
          requestURL =
            requestURL +
            url.API_UPDATE_MODULE_ROLE +
            `/${moduleRoleId}` +
            `?token=${token}`;

          response = await putData(requestURL, sendingData);
        } else {
          requestURL =
            requestURL + url.API_CREATE_MODULE_ROLE + `?token=${token}`;

          response = await postData(requestURL, sendingData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Modal.getInstance(
            document.querySelector("#modal_saverole")
          ).hide();
          resetHandler();
          afterModalCLose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setName("");
    setSlug("");
    setmoduleValue(null);
    setselectedModule(null);
    setModuleRoleId(null);
  };

  useEffect(() => {
    if (moduleRoleId) {
      getDetails();
    }
  }, [moduleRoleId]);

  useEffect(() => {
    getAllModules();
  }, []);

  return (
    <div className="edit_modalbx">
      <div
        className="modal fade"
        id="modal_saverole"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyword="false"
        style={{ display: "none" }}
      >
        <div className="modal-dialog" data-select2-id="select2-data-11-sw9z">
          <div className="modal-content" data-select2-id="select2-data-10-ow72">
            <div className="modal-header">
              <button
                type="button"
                className="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <span className="material-icons-outlined">close </span>
              </button>
            </div>
            <div className="modal-body edit_modalbody">
              <div className="srvay_feedhdng">
                <h2>Module Role</h2>
              </div>

              <div className="edit_fillOuter">
                <div className="row">
                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>{t("Module")} *</label>
                      <div className="model_selectBx">
                        <Select
                          styles={reactSelectStyle("6px")}
                          components={{ IndicatorSeparator: () => null }}
                          isClearable
                          placeholder={t("Select Module")}
                          options={moduleList}
                          value={moduleValue}
                          onChange={(val) => moduleSectionHandler(val)}
                        />
                      </div>
                      {validation.modulewarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-icons-outlined">
                              warning
                            </span>
                            <span>{t("Please select module")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>{t("Role")} *</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Role")}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            nameWarning: false,
                          }));
                        }}
                      />
                      {/* level warning */}
                      {validation.nameWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-icons-outlined">
                              warning
                            </span>
                            <span>{t("Please enter label")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input_fill white_fill">
                      <label>{t("Slug")}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={slug}
                        onChange={(e) => {
                          setSlug(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            slugWarning: false,
                          }));
                        }}
                        placeholder="Slug"
                      />
                      {/* slug warning */}
                      {validation.slugWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-icons-outlined">
                              warning
                            </span>
                            <span>{t("Please enter slug")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="submit_survay">
                      <button
                        type="submit"
                        className="basic_btn ylw_btn"
                        onClick={saveHandler}
                        disabled={isSaving ? true : false}
                        style={{
                          cursor: isSaving ? "not-allowed" : "pointer",
                        }}
                      >
                        {t("Save")}
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveModuleRoleModal;
