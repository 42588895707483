/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CategoryHeader from "./Header/CategoryHeader";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import SaveCategoryModal from "./Modal/SaveCategoryModal";
import CategoryListFilterModal from "./Modal/CategoryListFilterModal";

import Loading from "Components/Common/Loading/Loading";
import CommonLayout from "Components/Common/Layout/CommonLayout";

const CategoryListBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  /* ---- bread crumb text ---- */
  const breadCrumbText = [
    { title: t("Administration") },
    { title: t("Category") },
  ];

  const [categoryList, setCategoryList] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [categoryId, setCategoryId] = useState(null);

  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [filterCategorySlug, setFilterCategorySlug] = useState("");
  const [filterIsRoot, setFilterIsRoot] = useState(false);
  const [filterParents, setfilterParents] = useState([]);
  const [filterFeatured, setfilterFeatured] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectCategoryIds, setSelectCategoryIds] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [isUpdating, setIsUpdating] = useState(false);

  //function for get all tags
  const getAllCategories = async () => {
    setRowSelection({});
    setSelectCategoryIds([]);
    try {
      setIsLoading(true);
      let requestURL =
        url.API_BASE_URL + url.API_GET_CATEGORIES + `?token=${token}`;

      if (filterCategoryName !== "") {
        requestURL = requestURL + `&filtername=${filterCategoryName}`;
      }

      if (filterCategorySlug !== "") {
        requestURL = requestURL + `&filterslug=${filterCategorySlug}`;
      }

      if (filterParents.length > 0) {
        requestURL = requestURL + `&filterparents=${filterParents}`;
      }

      if (filterFeatured === true) {
        requestURL = requestURL + `&filterfeatured=${filterFeatured}`;
      }

      if (filterIsRoot === true) {
        requestURL = requestURL + `&filterisroot=${filterIsRoot}`;
      }

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setCategoryList(response.data);
        setParentCategories(response.data.filter((item) => item.isroot));
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedCategoryList = [...categoryList];

    const rowIndex = updatedCategoryList.findIndex(
      (category) => category._id.toString() === row.original._id.toString()
    );

    if (rowIndex !== -1) {
      updatedCategoryList[rowIndex].rank = value;
      setCategoryList(updatedCategoryList);
    }
  };

  //function for update rank
  const updateRankHandler = async () => {
    if (selectCategoryIds.length > 0) {
      try {
        setIsUpdating(true);

        const categoryListToBeUpdate = categoryList.filter((item) =>
          selectCategoryIds.includes(item._id)
        );

        let categoryData = {
          categorylist: categoryListToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_MASSIVE_CATEGORY +
          `?token=${token}`;

        const response = await putData(requestURL, categoryData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllCategories();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one category");
    }
    setShowAlert(true);
  };

  //function for massive delete
  const deleteCategories = async () => {
    if (selectCategoryIds.length > 0) {
      try {
        setIsUpdating(true);

        const categoryListToBeUpdate = categoryList.filter((item) =>
          selectCategoryIds.includes(item._id)
        );

        let categoryData = {
          categorylist: categoryListToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_DELETE_MASSIVE_CATEGORY +
          `?token=${token}`;

        const response = await putData(requestURL, categoryData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllCategories();
          }, 2500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one category");
    }
    setShowAlert(true);
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCategoryName("");
    setFilterCategorySlug("");
    setfilterParents([]);
    setfilterFeatured(false);
    setFilterIsRoot(false);
  };

  useEffect(() => {
    if (
      filterCategoryName != "" ||
      filterCategorySlug != "" ||
      filterParents.length > 0 ||
      filterFeatured === true ||
      filterIsRoot === true
    ) {
      getAllCategories();
    }
  }, [
    filterCategoryName,
    filterIsRoot,
    filterCategorySlug,
    filterParents,
    filterFeatured,
  ]);

  useEffect(() => {
    getAllCategories();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 50,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#saveCategoryModal"
          aria-controls="saveCategoryModal"
          onClick={() => {
            setCategoryId(row.original.value);
          }}
        >
          <i className="material-icons horz_icon">more_horiz</i>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "name",
      header: t("Name"),
      size: 300,
    },
    {
      accessorKey: "parentname",
      header: t("Parent"),
      size: 300,
    },
    {
      accessorKey: "slug",
      header: t("Slug"),
      size: 300,
    },
    {
      accessorKey: "rank",
      header: t("Ranking"),
      size: 80,
      Cell: ({ row }) => (
        <input
          type="number"
          className="form-control rounded-0 shadow-none bg-white"
          value={row.original.rank}
          onChange={(e) => rankInputChangeHandler(row, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "createddate",
      header: t("Date"),
      size: 150,
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    [categoryList, i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectCategoryIds(selectedIdsArray);
    } else {
      setSelectCategoryIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content" className="pb-4 tab_question_list">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <CategoryHeader
              breadCrumbText={breadCrumbText}
              updateRankHandler={updateRankHandler}
              deleteCategories={deleteCategories}
              // setFilterIsRoot={setFilterIsRoot}
              reloadData={getAllCategories}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={categoryList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    columnOrder,
                  }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder,
                  }}
                  // enableColumnOrdering={true}
                  //   onColumnVisibilityChange={onColumnVisiblityHandler}
                  //   onColumnOrderChange={changeColumnOrderHandler}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                  muiSearchTextFieldProps={{
                    placeholder: t("Search by Title"),
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <SaveCategoryModal
          afterModalCLose={getAllCategories}
          parentCategories={parentCategories}
          categroyId={categoryId}
          setCategoryId={setCategoryId}
        />

        {isUpdating && <Loading />}

        <CategoryListFilterModal
          parentCategories={parentCategories}
          setFilterCategoryName={setFilterCategoryName}
          setFilterCategorySlug={setFilterCategorySlug}
          setFilterIsRoot={setFilterIsRoot}
          setfilterParents={setfilterParents}
          setfilterFeatured={setfilterFeatured}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </CommonLayout>
  );
};

export default CategoryListBody;
