/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CrmCommonLayout from "Components/Crm/Common/CrmCommonLayout";
import TagHeader from "./Header/TagHeader";
import SaveTagModal from "./Modal/SaveTagModal";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import Loading from "Components/Common/Loading/Loading";

const CrmTagBody = () => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Crm") }, { title: t("Label") }];

  const [tagList, setTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTagId, setSelectedTagId] = useState(null);

  const [filterLabelName, setFilterLabelName] = useState("");
  const [filterLabelSlug, setFilterLabelSlug] = useState("");

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTagIds, setSelectedTagIds] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    setRowSelection({});
    setSelectedTagIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      // if (filterLabelName !== "") {
      //   requestURL = requestURL + `&filterlabelname=${filterLabelName}`;
      // }

      // if (filterLabelSlug !== "") {
      //   requestURL = requestURL + `&filterlabelslug=${filterLabelSlug}`;
      // }

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setTagList(response.data);
        setMessageType("success");
        // resetFilterData();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for bulk update/delete
  const bulkDeleteHandler = async () => {
    if (selectedTagIds.length > 0) {
      setIsUpdating(true);

      try {
        const listToBeUpdated = tagList.filter((item) =>
          selectedTagIds.includes(item._id)
        );

        let updatedData = {
          taglist: listToBeUpdated,
          status: "0",
        };

        let requestURL =
          url.API_BASE_URL + url.API_BULK_UPDATE_TAG + `?token=${token}`;

        const response = await putData(requestURL, updatedData);

        setIsUpdating(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllTags();
          }, 2000);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please select atleast one label."));
    }
    setShowAlert(true);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        size: 50,
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="modal"
            data-bs-target="#tagModalSave"
            aria-controls="tagModalSave"
            onClick={() => {
              setSelectedTagId(row.original.value);
            }}
          >
            <i className="material-icons horz_icon">more_horiz</i>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "label",
        header: t("Name"),
        size: 1000,
      },
      {
        accessorKey: "slug",
        header: t("Slug"),
        size: 1000,
      },
      {
        accessorKey: "createddate",
        header: t("Date"),
        size: 800,
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedTagIds(selectedIdsArray);
    } else {
      setSelectedTagIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CrmCommonLayout breadcrumbText={breadcrumbText} moduleName="label">
      <TagHeader
        breadCrumbText={breadcrumbText}
        bulkDeleteHandler={bulkDeleteHandler}
        reloadList={getAllTags}
      />

      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={tagList} // data from api to be displayed
            positionActionsColumn="last"
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) => row.value} // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
            defaultColumn={{
              minSize: 20,
              maxSize: 200,
              size: 50, //make columns wider by default
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
            muiSearchTextFieldProps={{
              placeholder: t("Search by Label Name"),
            }}
          />
        </div>
      )}

      <SaveTagModal
        tagId={selectedTagId}
        afterSave={getAllTags}
        resetTagId={() => {
          setSelectedTagId(null);
        }}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CrmCommonLayout>
  );
};

export default CrmTagBody;
