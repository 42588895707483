/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import Loading from "Components/Common/Loading/Loading";
import ChallengeDetailsCommonHeader from "../Common/Header/ChallengeDetailsCommonHeader";
import ViewResponseModal from "./Modal/ViewResponseModal";

const ChallengeResponseBody = () => {
  const today = new Date();
  const todayValue = `${String(today.getDate()).padStart(2, "0")}/${String(
    today.getMonth() + 1
  ).padStart(2, "0")}/${today.getFullYear()}`;

  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: challengeId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Challenges"), link: "/admin/challenges/manage" },
    { title: "" },
  ]);

  const [responseList, setResponseList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedResponseIds, setSelectedResponseIds] = useState([]);

  const [selectedResponseId, setSelectedResponseId] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get job details
  const getJobDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${challengeId}?token=${token}`;

      const response = await getData(requestURL);

      console.log("response in job details ----->", response);

      if (response.status) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("Challenges"), link: "/admin/challenges/manage" },
          { title: response.data.title },
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all response
  const getAllChallengeResponses = async () => {
    setRowSelection({});
    setSelectedResponseIds([]);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_RESPONSE +
        `?token=${token}&challenge=${challengeId}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setResponseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (challengeId) {
      getJobDetails();
      getAllChallengeResponses();
    }
  }, [challengeId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        size: 50,
        Cell: ({ row }) => {
          if (row.original.role === "Moderator") {
            return (
              <button className="action_btn_mui">
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          } else {
            return (
              <button
                className="action_btn_mui"
                data-bs-toggle="modal"
                data-bs-target="#jobResponseDetailsModal"
                aria-controls="jobResponseDetailsModal"
                onClick={() => {
                  setSelectedResponseId(row.original._id);
                }}
              >
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          }
        },
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "cvcandidatename",
        header: t("Response"),
        size: 350,
        Cell: ({ row }) => (
          <div className="title">
            <h6 className="text-primary mb-1 pointer">
              {row.original.candidateresumetitle}
            </h6>

            <p className="name text-gray mb-1">
              {row.original.cvcandidatename}
            </p>
            <p className="email text-gray d-flex align-items-center mb-1">
              <i className="material-icons me-1">mail_outline</i>
              <span>{row.original.cvcandidateemail}</span>
            </p>

            {row.original.iswinner && row.original.approvalstatus === "1" ? (
              <p className="status text-warning">
                <i class="material-icons-outlined">emoji_events</i>
                Winner
              </p>
            ) : row.original.approvalstatus === "2" ||
              row.original.approvalstatus === "0" ? (
              <p
                className={`status ${
                  row.original.approvalstatus === "2"
                    ? "text-danger"
                    : "text-warning"
                } `}
              >
                <i className="material-icons-outlined me-1">feedback</i>
                {row.original.approvalresponsestatus}
              </p>
            ) : row.original.hiringprocessname === "" ? (
              row.original.approvalstatus === "1" ? (
                <p className="status text-success">
                  <i className="material-icons-outlined me-1">feedback</i>
                  Accepted
                </p>
              ) : null
            ) : (
              <p className="status text-primary">
                <i className="material-icons-outlined me-1">fact_check</i>
                {row.original.hiringprocessname}
              </p>
            )}
          </div>
        ),
      },
      {
        accessorKey: "responsecode",
        header: t("C Code"),
      },
      {
        accessorKey: "winnerstatus",
        header: t("Price"),
        Cell: ({ row }) => (
          <div className="c-code">
            <p className="mb-1">{row.original.cadidateminctc} EURO</p>
            <p className="mb-1">{row.original.cadidatemaxctc} EURO</p>
            <p>{row.original.paymentduration}</p>
          </div>
        ),
      },
      // {
      //   accessorKey: "vchat",
      //   header: t("V. Chat"),
      //   Cell: ({ row }) => (
      //     <div className="v-chat">
      //       <div className="chat d-flex align-items-center">
      //         <span className="text-blue me-2">P</span>
      //         <a
      //           href="/admin/challengeresponse/chat?uid=2220"
      //           className="notification text-gray position-relative me-2"
      //           target="_blank"
      //           data-bs-toggle="tooltip"
      //           title="Provider conversation"
      //         >
      //           <img
      //             src="/images/message-icon.svg"
      //             alt="V Chat"
      //             className="img-fluid"
      //           />
      //           <span className="position-absolute top-0 start-100 translate-middle p-1 bg-yellow border border-2 border-light rounded-circle">
      //             <span className="visually-hidden">New alerts</span>
      //           </span>
      //         </a>
      //       </div>

      //       <div className="chat d-flex align-items-center mt-4">
      //         <span className="text-blue me-2">G</span>

      //         <a
      //           href="/admin/challengeresponse/groupchat/89?update=1"
      //           className="notification text-gray position-relative me-2"
      //           target="_blank"
      //           data-bs-toggle="tooltip"
      //           title="Group conversation"
      //         >
      //           <img
      //             src="/images/message-icon.svg"
      //             alt="V Chat"
      //             className="img-fluid"
      //           />
      //           <span className="position-absolute top-0 start-100 translate-middle p-1 bg-yellow border border-2 border-light rounded-circle">
      //             <span className="visually-hidden">New alerts</span>
      //           </span>
      //         </a>
      //       </div>
      //     </div>
      //   ),
      // },
      {
        accessorKey: "applieddate",
        header: t("Dates"),
        size: 250,
        Cell: ({ row }) => (
          <div className="status-count">
            <div className="status">
              <p className="mb-2">
                <span
                  className={`d-inline-block me-3 ${
                    row.original.approvalstatus === "2"
                      ? "text-danger"
                      : row.original.approvalstatus === "0"
                      ? "text-warning"
                      : row.original.approvalstatus === "1"
                      ? "text-success"
                      : ""
                  }`}
                >
                  {row.original.approvalresponsestatus}
                </span>
                <span>
                  {row.original.approvalresponsedate === "" ? todayValue : ""}
                </span>
              </p>

              <p className="mb-2">
                <Link to="#" className="d-inline-block text-grey me-3">
                  Submitted
                </Link>
                <span>{row.original.applieddate}</span>
              </p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "label",
        header: t("Label"),
      },
      {
        accessorKey: "candidateposition",
        header: t("Skills"),
        Cell: ({ row }) => (
          <div className="skills">
            {row.original.candidateskills.map((skill, index) => (
              <p className="mb-1" key={index}>
                {skill.name}
                {index !== row.original.candidateskills.length - 1 && ","}
              </p>
            ))}
          </div>
        ),
      },
    ],
    [responseList, i18n.language]
  );

  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //optionally, you can manage the row selection state yourself

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedResponseIds(selectedIdsArray);
    } else {
      setSelectedResponseIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <ChallengeDetailsCommonHeader moduleName="responses" />

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          {/* hteader */}

          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={responseList} // data from api to be displayed
              positionActionsColumn="last"
              enableGrouping // to enable grouping of column
              enableRowSelection // enable showing checkbox
              getRowId={(row) => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
              defaultColumn={{
                minSize: 20,
                maxSize: 250,
                size: 100, //make columns wider by default
              }}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
              muiSearchTextFieldProps={{
                placeholder: t("Search by Member Name"),
              }}
            />
          </div>
        </div>
      </section>

      <ViewResponseModal
        selectedResponseId={selectedResponseId}
        setSelectedResponseId={setSelectedResponseId}
        afterModalClose={getAllChallengeResponses}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default ChallengeResponseBody;
