/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import ContactTaskModal from "../Modal/ContactTaskModal";
import AddTagModal from "Components/Common/Modal/AddTagModal";

const ContactTaskTab = ({
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);

  const [selectedTaskId, setselectedTaskId] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);

  //get all tasks
  const getAllTask = async () => {
    setSelectedTaskIds([]);
    setRowSelection({});

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&filtercontact=${id}`;

      console.log(`getAllTask requestURL--->`, requestURL);

      const response = await getData(requestURL);

      console.log("response in task list------->", response);
      setIsLoading(false);

      if (response.status) {
        setTaskList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for delete mail
  const deleteBulkTaskHandler = async () => {
    if (selectedTaskIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = taskList.filter((item) =>
          selectedTaskIds.includes(item._id)
        );

        let apiData = {
          tasklist: listToBeUpdated,
          status: "0",
        };

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_BULK_TASK + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllTask();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one item");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (id) {
      getAllTask();
    }
  }, [id]);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 30,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#saveTaskModal"
          aria-controls="saveTaskModal"
          onClick={() => {
            setselectedTaskId(row.original._id);
          }}
        >
          <i className="material-icons horz_icon">more_horiz</i>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "title",
      header: t("Name"),
      size: 300,
    },
    {
      accessorKey: "assignedusername",
      header: t("Assigned To"),
      Cell: ({ row }) => (
        <div className="profile d-flex align-items-center">
          <div className="img-container me-2">
            <Link to="#">
              <img
                width={35}
                src={
                  row.original.assigneduserimage === ""
                    ? "/images/default-user.png"
                    : url.SERVER_URL + row.original.assigneduserimage
                }
                className="rounded-circle"
              />
            </Link>
          </div>
          <p className="name">
            <Link to="#" className="text-dark">
              {row.original.assignedusername}
            </Link>
          </p>
        </div>
      ),
      size: 350,
    },
    {
      accessorKey: "taskdate",
      header: t("Date"),
      size: 300,
    },
    {
      accessorKey: "taskstatus",
      header: t("Status"),
      size: 250,
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      size: 250,
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [taskList, i18n.language]);

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTaskIds(selectedIdsArray);
    } else {
      setSelectedTaskIds([]);
    }
  }, [rowSelection]);

  return (
    <div className="task-container">
      <div className="filter-container mb-3">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <div className="d-flex justify-content-sm-end align-items-center gap-2">
              <Link
                to="#"
                className="btn btn-secondary rounded-0 shadow-none  "
                onClick={getAllTask}
              >
                <i className="material-icons">refresh</i>
              </Link>
              <button
                type="button"
                className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
                data-bs-toggle="modal"
                data-bs-target="#saveTaskModal"
              >
                <i className="material-icons me-2">add</i> Add New
              </button>

              <div className="dropdown" id="module-mass-action">
                <button
                  className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Action
                </button>
                <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#addTagModal"
                    >
                      Add Label
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={deleteBulkTaskHandler}
                    >
                      Delete
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={taskList} // data from api to be displayed
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{
              rowSelection,
              columnOrder: columnOrder,
            }} //pass our managed row selection state to the table to use
            enableColumnOrdering={true}
            //   onColumnVisibilityChange={onColumnVisiblityHandler}
            //   onColumnOrderChange={changeColumnOrderHandler}
            defaultColumn={{
              minSize: 20,
              maxSize: 300,
              size: 50, //make columns wider by default
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
            muiSearchTextFieldProps={{
              placeholder: t("Search by Title"),
            }}
          />
        </div>
      )}

      <ContactTaskModal
        setTaskId={setselectedTaskId}
        taskId={selectedTaskId}
        reloadTask={getAllTask}
      />

      <AddTagModal
        moduleName="task"
        selectedIds={selectedTaskIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllTask();
          }, 2000);
        }}
      />
    </div>
  );
};

export default ContactTaskTab;
