/* eslint-disable */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import PrevilegeHeader from "Pages/Administration/ModulePrevilegeByRoles/Header/PrevilegeHeader";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const ModulePrevilegeByRoleBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Administration") },
    { title: t("Module Roles") },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [previleges, setPrevileges] = useState([]);

  const [isSaving, setisSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllRoles = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_ROLES +
        `?token=${token}&filterfeatured=${true}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setRoleList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all modules
  const getAllModulePrevileges = async () => {
    try {
      setIsLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_MODULE_ROLE_PREVILEGES +
        `?token=${token}`;

      // console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setPrevileges(response.data);
        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      console.log(error.message);
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //change previlege
  const changePrevilegeCheckHandler = (e, previlegeData, moduleData) => {
    const updatedPrevilegeModules = previleges.map((dataItem) => {
      const updatedPrevileges = dataItem.previleges.map((dataItem2) => {
        if (dataItem2.slug === previlegeData.slug) {
          return { ...dataItem2, privilege: e.target.checked };
        }
        return dataItem2;
      });

      if (dataItem.slug === moduleData.slug) {
        return { ...dataItem, previleges: updatedPrevileges };
      }

      return dataItem;
    });

    setPrevileges(updatedPrevilegeModules);
  };

  //save previlege
  const savePrevilegeHandler = async () => {
    setisSaving(true);
    try {
      let previlegeData = {
        previlegearr: previleges,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_MODULE_ROLE_PREVILEGE +
        `?token=${token}`;

      const response = await postData(requestURL, previlegeData);

      setisSaving(false);
      // console.log(response);
      if (response.status) {
        setMessageType("success");
        setShowAlert(true);
        setAlertMessage("Previlege updated successfully");
      }
    } catch (error) {
      console.log(error.message);
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    getAllRoles();
    getAllModulePrevileges();
  }, []);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <>
      <AuthHeader />

      {/* <PrevilegeHeader /> */}

      {/* <!-- ========== Start main Section ========== --> */}
      <section id="main_content" className="pb-4">
        <div className="container-fluid px-md-4">
          <div className="survey_contentArea">
            <BreadCrumb breadCrumbText={breadcrumbText} />

            <div className="filter-container mb-3">
              <div className="d-flex justify-content-end">
                <Link
                  to="#"
                  className="btn btn-secondary rounded-0 shadow-none me-2 "
                  onClick={getAllModulePrevileges}
                >
                  <i className="material-icons">refresh</i>
                </Link>
                {/* <div className="dropdown">
                  <button
                    className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Action
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                    <li>
                      <Link
                        to="/admin/roleprivileges/reset"
                        className="dropdown-item"
                      >
                        Reset
                      </Link>
                    </li>
                  </ul>
                </div> */}
                <button
                  type="button"
                  className="btn btn-info rounded-0 shadow-none"
                  onClick={savePrevilegeHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  Save Privileges
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <table
                  id="module_role_privilege"
                  className="table display nowrap"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th className="module text-gray fw-normal">
                        <span>Module</span>
                      </th>
                      {roleList.map((dataItem, index) => {
                        return (
                          <th
                            className={`${dataItem.name} text-gray fw-normal`}
                            key={index}
                          >
                            <span>{dataItem.name}</span>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  {roleList.length > 0 ? (
                    <tbody>
                      {previleges.map((dataItem, index) => {
                        return (
                          <tr
                            className={dataItem.isroot ? "parent" : "children"}
                            key={index}
                          >
                            <td className="module">
                              <span
                                className={
                                  dataItem.isroot
                                    ? "fw-bold text-primary"
                                    : "text-primary"
                                }
                              >
                                {dataItem.name}
                              </span>
                            </td>
                            {dataItem.previleges.map(
                              (previlegeData, index2) => {
                                return (
                                  <td
                                    className={previlegeData.slug}
                                    key={index2}
                                  >
                                    <div className="form-check">
                                      <input
                                        className="form-check-input check-success"
                                        type="checkbox"
                                        name={previlegeData.name}
                                        id={previlegeData.slug}
                                        checked={previlegeData.privilege}
                                        onChange={(e) =>
                                          changePrevilegeCheckHandler(
                                            e,
                                            previlegeData,
                                            dataItem
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                );
                              }
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  ) : null}
                </table>
              </div>
            )}

            {/* <div className="d-flex justify-content-end mt-2">
              <button
                type="button"
                className="btn btn-yellow rounded-0 m-0"
                onClick={savePrevilegeHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                Submit
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div> */}
          </div>
        </div>

        {isSaving && <Loading />}

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
      {/* <!-- ========== End main Section ========== --> */}
    </>
  );
};

export default ModulePrevilegeByRoleBody;
