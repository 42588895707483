/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const CrmListFilterModal = ({
  setfilterTitle,
  setfilterSlug,
  setfilterTags,
}) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const token = localStorage.getItem("token");

  const [tagList, setTagList] = useState([]);

  const [title, settitle] = useState("");
  const [slug, setslug] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [tags, setTags] = useState([]);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select event tags
  const tagSelectionHandler = (data) => {
    settagValue(data);
    setTags(data.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setfilterTitle(title);
    setfilterSlug(slug);
    setfilterTags(tags);
    3;

    // close the modal
    let filterModal = document.querySelector("#crmListFilterModal");
    let bootstrapModal = bootstrap.Modal.getInstance(filterModal);
    bootstrapModal.hide();
    resetHandler();
  };
  const resetHandler = () => {
    settitle("");
    setslug("");
    settagValue(null);
    setTags([]);
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="modal fade"
      id="crmListFilterModal"
      tabIndex="-1"
      aria-labelledby="crmListFilterModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyword="false"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0 mb-2">
            <h2 className="text-green w-100" id="eventfilterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-4">
                <label
                  htmlFor="labelName"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Name")}
                </label>
                <input
                  type="text"
                  id="labelName"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter here")}
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
              </div>

              {/* slug */}
              <div className="form-group mb-4">
                <label
                  htmlFor="slugID"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Slug")}
                </label>
                <input
                  type="text"
                  id="slug"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter here")}
                  value={slug}
                  onChange={(e) => {
                    setslug(e.target.value);
                  }}
                />
              </div>

              {/* tags  */}
              <div className="form-row mb-3">
                <label htmlFor="tags" className="fw-bold mb-2">
                  Labels
                </label>
                <Select
                  styles={reactSelectStyle("6px", "4px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  placeholder={t("Select tags")}
                  options={tagList}
                  value={tagValue}
                  onChange={(val) => {
                    tagSelectionHandler(val);
                  }}
                />
              </div>

              <div className="action d-flex justify-content-between gap-3 my-4">
                <button
                  onClick={searchHandler}
                  type="button"
                  className="btn btn-info rounded-0 shadow-none w-100"
                >
                  {t("Search")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmListFilterModal;
