/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, uploadSingleFile } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const CreateEmailPopup = ({
  contactName = "",
  reloadList = () => {},
  contactPersonEmail = "",
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");
  // loggedin user information
  const currentUser = JSON.parse(localStorage.getItem("userInfo"));

  // ------------ hooks for user Inputs --------------------------------
  const [mailTemplateList, setMailTemplateList] = useState([]);

  /* ------------- Language translation imports starts here ------------- */
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [receivermails, setReceivermails] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [mailDescription, setMailDescription] = useState(""); //for ck editor
  const [mailSending, setMailSending] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    emailWarning: false,
    subjectWarning: false,
  });

  const editor = useRef(null);

  // const editorCustomConfig = joditEditorConfig();
  const editorCustomConfig = {
    readonly: false,
    placeholder: "",
    height: 350,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setMailDescription(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const editorConfig = useMemo(() => editorCustomConfig, []);

  // validate =========================
  const validationHandler = () => {
    let isValid = true;

    if (receivermails === "") {
      if (contactPersonEmail === "") {
        setValidation((prevState) => ({ ...prevState, emailWarning: true }));
        isValid = false;
      }
    }

    if (mailSubject === "") {
      setValidation((prevState) => ({ ...prevState, subjectWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all mail templates
  const getAllMailTemplates = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_MAIL_TEMPLATES + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setMailTemplateList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select template
  const templateSelectionHandler = (val) => {
    if (val) {
      //assign template value for select
      setSelectedTemplate(val);

      //set value for send mail
      setMailSubject(val.subject);

      // setEditorContent(val.description);
      setMailDescription(val.description);
    }
  };

  const saveMailHandler = async () => {
    if (validationHandler()) {
      const templateId = selectedTemplate ? selectedTemplate.value : null;

      let mailData = {
        receivermails:
          contactPersonEmail !== "" ? contactPersonEmail : receivermails,
        subject: mailSubject,
        sender: currentUser._id,
        description: mailDescription,
        messagetemplate: templateId,
      };

      console.log("mailData=======>", mailData);

      try {
        setMailSending(true);

        let requestURL =
          url.API_BASE_URL + url.API_ADD_MESSAGE + `?token=${token}`;

        const response = await postData(requestURL, mailData);

        console.log("Mail Response", response);

        setMailSending(false);

        if (response.status) {
          setMessageType("success");

          resetHandler();

          reloadList();

          window.bootstrap.Offcanvas.getInstance(
            document.querySelector("#addNewEmail")
          ).hide();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please fill up all required fields");
    }
    setShowAlert(true);
  };

  /* reset */
  const resetHandler = () => {
    setMailDescription("");
    setSelectedTemplate(null);
    setReceivermails("");
    setMailSubject("");
    setValidation({
      emailWarning: false,
      subjectWarning: false,
    });
  };

  useEffect(() => {
    getAllMailTemplates();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="addNewEmail"
      aria-labelledby="addNewEmailLabel"
    >
      <div className="offcanvas-header justify-content-start p-4 pb-0">
        <button
          type="button"
          className="d-flex align-items-center justify-content-center rounded-0 shadow-none bg-gray-100 text-primary border-0 p-0 me-4"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        >
          <i className="material-icons">close</i>
        </button>
        <div className="email_userInfo">
          <h3>Send mail </h3>
          {/* {contactName === "" ? null : (
            <Link to="#">
              {contactName} - ({contactPersonEmail})
            </Link>
          )} */}
        </div>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-row mb-3">
            {contactPersonEmail && contactPersonEmail !== "" ? (
              <input
                type="text"
                className="form-control rounded-0 border-gray-600"
                value={contactPersonEmail}
                readOnly
              />
            ) : (
              <input
                type="text"
                className="form-control rounded-0 border-gray-600"
                placeholder={t("Enter mail address")}
                value={receivermails}
                onChange={(e) => {
                  setReceivermails(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    emailWarning: false,
                  }));
                }}
              />
            )}
            {validation.emailWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a mail id!")}</span>
                </p>
              </div>
            )}
          </div>
          <div className="form-row mb-3">
            <Select
              isClearable
              styles={reactSelectStyle("5px", "0px", true)}
              placeholder={t("Select A template")}
              options={mailTemplateList}
              value={selectedTemplate}
              onChange={(val) => {
                templateSelectionHandler(val);
              }}
            />
          </div>
          <div className="form-row mb-3">
            <input
              type="text"
              className="form-control rounded-0 border-gray-600"
              placeholder={t("Subject")}
              value={mailSubject}
              onChange={(e) => {
                setMailSubject(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  subjectWarning: false,
                }));
              }}
            />
            {validation.subjectWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a subject!")}</span>
                </p>
              </div>
            )}
          </div>
          <div className="form-row mb-3">
            <JoditEditor
              ref={editor}
              value={mailDescription}
              config={editorConfig}
              onBlur={(newContent) => {
                // console.log("New content in on blur----->", newContent);
                setMailDescription(newContent);
              }}
              onChange={(newContent, event) => {
                // setMailDescription(newContent);
              }}
            />
          </div>

          <div className="actions d-flex align-items-center mt-1 justify-content-end">
            <button
              className="d-block btn btn-info rounded-0"
              onClick={saveMailHandler}
              style={{ cursor: mailSending ? "not-allowed" : "pointer" }}
              disabled={mailSending ? true : false}
            >
              {t("Send")}

              {mailSending ? (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <i className="material-icons ms-2">send</i>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateEmailPopup;
