/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

// Material UI components
import { MaterialReactTable } from "material-react-table";
import { MenuItem, Box } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import SurveyListHeader from "./Header/SurveyListHeader";
import SaveSurveyModal from "./Modal/SaveSurveyModal";
import UpdateSurveyModal from "./Modal/UpdateSurveyModal";
import DeleteWarningModal from "Components/Common/Modal/DeleteWarningModal";
import AddTagModal from "Components/Common/Modal/AddTagModal";
import SurveyListFilterModal from "./Modal/SurveyListFilterModal";

const SurveyListBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const breadCrumbText = [{ title: t("Survey"), link: "#" }];

  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [surveyList, setSurveyList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedSurveyIds, setSelectedSurveyIds] = useState([]);

  const [selectedSurveyId, setSelectedSurveyId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const [filterSurveyName, setFilterSurveyName] = useState("");
  const [filterSurveyCode, setFilterSurveyCode] = useState("");
  const [filterSurveyDateRange, setfilterSurveyDateRange] = useState("");
  const [filterSurveyTags, setfilterSurveyTags] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  /* --- function to navigate to survey question --- */
  const getAllSurvey = async () => {
    setRowSelection({});
    setSelectedSurveyIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_SURVEY + `?token=${token}`;

      if (filterSurveyName != "") {
        requestUrl = requestUrl + `&filtername=${filterSurveyName}`;
      }
      if (filterSurveyCode != "") {
        requestUrl = requestUrl + `&filtercode=${filterSurveyCode}`;
      }
      if (filterSurveyDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterSurveyDateRange}`;
      }
      if (filterSurveyTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterSurveyTags}`;
      }

      const response = await getData(requestUrl);

      setIsLoading(false);

      // console.log(response);
      if (response.status) {
        setSurveyList(response.data);
        resetFilter();
        setMessageType("success");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for delete survey
  const deleteMassiveSurveyHandler = async () => {
    if (selectedSurveyIds.length > 0) {
      const surveyListToBeDelete = surveyList.filter((item) =>
        selectedSurveyIds.includes(item._id)
      );

      try {
        let surveyData = {
          surveylist: surveyListToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_MASSIVE_SURVEY + `?token=${token}`;

        const response = await putData(requestURL, surveyData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllSurvey();
          }, 2500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //reset filter
  const resetFilter = () => {
    setFilterSurveyName("");
    setFilterSurveyCode("");
    setfilterSurveyDateRange("");
    setfilterSurveyTags([]);
  };

  //copy url function
  const copyUrlHandler = (surveyId = null) => {
    if (surveyId) {
      let copiedUrl = `${url.BACKEND_BASE_URL}/admin/survey/feedback/${surveyId}`;

      clipboardCopy(copiedUrl);

      setAlertMessage(t("Url copied successfully"));
      setMessageType("success");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (
      filterSurveyName != "" ||
      filterSurveyCode != "" ||
      filterSurveyDateRange != "" ||
      filterSurveyTags.length > 0
    ) {
      getAllSurvey();
    }
  }, [
    filterSurveyName,
    filterSurveyCode,
    filterSurveyDateRange,
    filterSurveyTags,
  ]);

  useEffect(() => {
    getAllTags();
    getAllSurvey();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: t("Name"),
        size: 250,
        Cell: ({ row }) => (
          <div className="d-flex align-items-center gap-1">
            <div className="action border-bottom-0">
              <span>{row.original.name}</span>
            </div>
            <Link
              to="#"
              className="d-flex align-items-center text-gray"
              data-bs-toggle="modal"
              data-bs-target="#copyUpdateSurveyModal"
              onClick={() => {
                setSelectedSurveyId(row.original._id);
                setIsEdit(true);
              }}
            >
              <span className="material-icons-outlined icon-sm">edit</span>
            </Link>
            <Link
              to="#"
              className="d-flex align-items-center text-gray"
              data-bs-toggle="modal"
              data-bs-target="#copyUpdateSurveyModal"
              onClick={() => {
                setSelectedSurveyId(row.original._id);
                setIsEdit(false);
              }}
            >
              <span className="material-icons-outlined icon-sm">
                content_copy
              </span>
            </Link>
            {/* <button
              className="btn btn-secondary rounded-5 shadow-none"
              onClick={() => {
                copyUrlHandler(row.original._id);
              }}
            >
              Copy Url
            </button> */}
          </div>
        ),
      },
      {
        accessorKey: "code",
        header: t("Code"),
      },
      {
        accessorKey: "modulename",
        header: t("Module"),
      },
      {
        accessorKey: "componentname",
        header: t("Component"),
      },
      {
        accessorKey: "moderatorname",
        header: t("Moderator"),
        Cell: ({ row }) => (
          <div className="moderator border-bottom-0">
            <div className="profile d-flex align-items-center mb-2">
              <div className="img-container rounded-circle overflow-hidden me-2">
                <img
                  src={
                    row.original.moderatorimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.moderatorimage
                  }
                  className="img-fluid thumbnail"
                  width="25"
                  height="25"
                />
              </div>
              <p className="name">{row.original.moderatorname}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "responsecount",
        header: t("Responses"),
        // Cell: ({ row }) => (
        //   <div className="responces border-bottom-0">
        //     <div className="d-flex align-items-center gap-2">
        //       <p className="position-relative mb-0">
        //         <span className="notification-dot d-block position-absolute bg-primary border border border-white rounded-circle end-0 top-0"></span>
        //         <span className="d-block material-symbols-outlined icon-md">
        //           sms
        //         </span>
        //       </p>
        //       <p className="mb-0">{row.original.responsecount}</p>
        //     </div>
        //   </div>
        // ),
      },
      {
        accessorKey: "surveydate",
        header: t("Created Date"),
      },
      {
        accessorKey: "tagstring",
        header: t("Tags"),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectedSurveyIdArr = selectedIdsArray.map((id) => {
        const [surveyId] = id.split("|");
        return surveyId;
      });

      setSelectedSurveyIds(selectedSurveyIdArr);
    } else {
      setSelectedSurveyIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content">
        <div className="container-fluid px-md-4">
          <SurveyListHeader
            breadCrumbText={breadCrumbText}
            reloadList={getAllSurvey}
            deleteMassiveSurveyHandler={deleteMassiveSurveyHandler}
          />

          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={surveyList} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row._id}|${row.moderatorid}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                enableRowActions // show the action column
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                renderRowActionMenuItems={({ row }) => {
                  const [surveyId, ownerId] = row.id.split("|"); // Extracts the _id part

                  const menuItems = [
                    <MenuItem key="#">
                      <Link
                        className="dropdown-item"
                        to={`/admin/survey/question/list/${surveyId}`}
                      >
                        {t("Save Question To Survey")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="#">
                      <Link
                        className="dropdown-item"
                        to={`/admin/survey/invite/list/${surveyId}`}
                      >
                        {t("Survey Invites")}
                      </Link>
                    </MenuItem>,
                    <MenuItem key="#">
                      <Link
                        className="dropdown-item"
                        to={`/admin/survey/response/list/${surveyId}`}
                      >
                        {t("Survey Responses")}
                      </Link>
                    </MenuItem>,
                  ];

                  if (
                    userInfo.role.slug === "ADMIN" ||
                    userInfo.role.slug === "SUPER_ADMIN" ||
                    ownerId.toString() === userInfo._id.toString()
                  ) {
                    return menuItems;
                  } else {
                    return [];
                  }
                }} // action columns menu items
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>

      <SaveSurveyModal
        afterModalClose={getAllSurvey}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        setShowAlert={setShowAlert}
      />

      <UpdateSurveyModal
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        selectedSurveyId={selectedSurveyId}
        setSelectedSurveyId={setSelectedSurveyId}
        afterModalClose={getAllSurvey}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        setShowAlert={setShowAlert}
      />

      {/* --- filter popup --- */}
      <SurveyListFilterModal
        setFilterSurveyName={setFilterSurveyName}
        setFilterSurveyCode={setFilterSurveyCode}
        setfilterSurveyDateRange={setfilterSurveyDateRange}
        setfilterSurveyTags={setfilterSurveyTags}
        tagList={tagList}
      />

      {/* --- add label modal start --- */}
      <AddTagModal
        moduleName="survey"
        selectedIds={selectedSurveyIds}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllSurvey();
          }, 2500);
        }}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />
      {/* --- add label modal end --- */}

      {/* ----------- delete warning modal ------------ */}
      <DeleteWarningModal
        onCancelDelete={() => {
          setRowSelection({});
          setSelectedSurveyIds([]);
        }}
        onDelete={deleteMassiveSurveyHandler}
        moduleName={t("survey")}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default SurveyListBody;
