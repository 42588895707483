/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import { downloadFileHandler } from "Helper/CommonHelper/CommonHelper";
import { reactSelectStyle } from "Config/Config";
import currencyOptions from "data/Prod/Currency.json";

const ViewResponseModal = ({
  selectedResponseId,
  setSelectedResponseId,
  afterModalClose,
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: challengeId } = useParams();

  const [processList, setprocessList] = useState([]);

  const [companyName, setCompanyname] = useState("");
  const [resumetitle, setResumetitle] = useState("");
  const [approvalStatus, setapprovalStatus] = useState("");
  const [jobtitle, setJobtitle] = useState("");
  const [jobprice, setJobprice] = useState("");
  const [companyaddress, setCompanyaddress] = useState("");
  const [jobskills, setJobskills] = useState([]);
  const [jobtype, setJobtype] = useState("");
  const [jobrequiredexperience, setJobrequiredexperience] = useState("");
  const [remotejob, setRemotejob] = useState("");
  const [responseActivitylist, setResponseActivitylist] = useState([]);
  const [candidateProfilePic, setCandidateProfilePic] = useState("");
  const [candidatename, setCandidatename] = useState("");
  const [candidatesurnname, setCandidatesurnname] = useState("");
  const [candidateemail, setCandidateemail] = useState("");
  const [candidatephone, setCandidatephone] = useState("");
  const [candidateskills, setCandidateskills] = useState([]);
  const [resumesummary, setResumesummary] = useState("");
  const [resumedetails, setResumedetails] = useState("");
  const [minctc, setMinctc] = useState("");
  const [maxctc, setMaxctc] = useState("");
  const [currency, setCurrency] = useState("");
  const [resumewithdatapath, setResumewithdatapath] = useState("");
  const [resumewihdatafilename, setresumewihdatafilename] = useState("");
  const [resumewihoutdatapath, setresumewihoutdatapath] = useState("");
  const [resumewihoutdatafilename, setresumewihoutdatafilename] = useState("");
  const [videoresumepath, setvideoresumepath] = useState("");
  const [videoresumefilename, setvideoresumefilename] = useState("");
  const [resumecomments, setResumecomments] = useState("");

  const [selectedHringprocessvalue, setSelectedHiringprocessvalue] =
    useState(null);
  const [selectedHiringProcessId, setSelectedHiringProcessId] = useState(null);

  const [isWinner, setIsWinner] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const [validation, setvalidation] = useState({
    hiringProcessError: false,
  });

  const validationHandler = () => {
    let isValid = true;

    if (selectedHiringProcessId === null) {
      setvalidation((prevState) => ({
        ...prevState,
        hiringProcessError: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all hiring process
  const getAllChallengeProcess = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_PROCESS +
        `?token=${token}&challenge=${challengeId}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setprocessList(response.data);
      }
    } catch (error) {}
  };

  //function for select process
  const processSelectionHandler = (val) => {
    if (val) {
      setvalidation((prevState) => ({
        ...prevState,
        hiringProcessError: false,
      }));
      setSelectedHiringprocessvalue(val);
      setSelectedHiringProcessId(val.value);
    }
  };

  //function for get response details
  const getResponseDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_RESPONSE_DETAILS +
        `/${selectedResponseId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        const data = response.data;

        setCompanyname(data.companyname);
        setResumetitle(data.resumetitle);
        setapprovalStatus(data.approvalstatus);
        setJobtitle(data.jobtitle);
        setJobprice(data.jobprice);
        setCompanyaddress(data.companyaddress);
        setJobskills(data.jobskills);
        setJobtype(data.jobtype);
        setJobrequiredexperience(data.jobrequiredexperience);
        setRemotejob(data.remotejob);
        setResponseActivitylist(data.activitylist);
        setCandidateProfilePic(data.candidateprofilepicturepath);
        setCandidatename(data.candidatename);
        setCandidatesurnname(data.candidatesurnname);
        setCandidateemail(data.candidateemail);
        setCandidatephone(data.candidatephone);
        setCandidateskills(data.candidateskills);
        setResumesummary(data.resumesummary);
        setResumedetails(data.resumedetails);
        setMinctc(data.minctc);
        setMaxctc(data.maxctc);
        const filteredCurrency = currencyOptions.filter(
          (option) => option.value === data.currency
        );
        setCurrency(filteredCurrency[0].label);
        setResumewithdatapath(data.resumewithdatapath);
        setresumewihdatafilename(data.resumewihdatafilename);
        setresumewihoutdatapath(data.resumewihoutdatapath);
        setresumewihoutdatafilename(data.resumewihoutdatafilename);
        setvideoresumepath(data.videoresumepath);
        setvideoresumefilename(data.videoresumefilename);
        setResumecomments(data.resumecomments);

        if (data.hiringprocessvalue) {
          processSelectionHandler(data.hiringprocessvalue);
        }

        setIsWinner(data.iswinner);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for promote
  const promoteResponseHandler = async (status = "0") => {
    try {
      setIsSaving(true);

      let promoteData = {};

      if (status === "0") {
        promoteData.hiringprocessid = selectedHiringProcessId ?? null;
      }

      if (status === "1" || status === "2") {
        promoteData.approvalstatus = status;
      }

      console.log(promoteData);

      let requestURL = url.API_BASE_URL;

      if (isWinner) {
        requestURL +=
          url.API_CHALLENGE_WINNER_SELECTION +
          `/${selectedResponseId}?token=${token}`;
      } else {
        requestURL +=
          url.API_CHANGE_RESPONSE_STATUS +
          `/${selectedResponseId}?token=${token}`;
      }

      const response = await putData(requestURL, promoteData);

      console.log(response);

      setIsSaving(false);

      if (response.status) {
        // close the modal
        let currentModal = document.querySelector("#jobResponseDetailsModal");
        let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
        bootstrapModal.hide();

        resetHandler();
        afterModalClose();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setSelectedResponseId(null);
  };

  useEffect(() => {
    if (selectedResponseId) {
      getResponseDetails();
      getAllChallengeProcess();
    }
  }, [selectedResponseId]);

  return (
    <div
      className="modal fade"
      id="jobResponseDetailsModal"
      tabIndex="-1"
      aria-labelledby="jobResponseDetailsModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header bg-primary p-4">
            <div className="job-title">
              <p className="company d-flex align-items-center text-white mb-2">
                <i className="material-icons me-2">business</i>{" "}
                <span id="ofr_company">{companyName}</span>
              </p>

              <h3 className="fw-bold text-white mb-3">{resumetitle}</h3>

              <div className="action d-flex align-items-center gap-3">
                {approvalStatus === "1" ? null : (
                  <button
                    className="btn btn-yellow rounded-0 approveResponse"
                    onClick={() => {
                      promoteResponseHandler("1");
                    }}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                  >
                    Approve
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                )}

                {approvalStatus === "1" ? (
                  <p className=" align-items-center text-white mb-2 alreadyApproved">
                    Accepted by Moderator
                  </p>
                ) : null}

                <button
                  className="btn btn-outline-danger rounded-0 me-3 rejectResponse"
                  onClick={() => {
                    promoteResponseHandler("2");
                  }}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  Reject
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none position-absolute top-0 end-0 opacity-100 mt-3 me-3 p-2"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body px-3 py-0">
            <div className="row border-bottom mb-3">
              <div
                className="col-md-4 px-4 py-3 border-md-end"
                style={{ background: "#fff" }}
              >
                <div className="company-details mb-3">
                  <h5
                    className="designation fw-bold text-primary mb-2"
                    id="ofr_name"
                  >
                    {jobtitle}
                  </h5>
                  <h6 className="salary fw-bold text-info mb-3" id="ofr_price">
                    {jobprice}.00
                  </h6>
                  <h6 className="location d-flex align-items-center">
                    <i className="material-icons d-block icon-sm me-2">
                      location_on
                    </i>{" "}
                    <span id="ofr_location">{companyaddress}</span>
                  </h6>

                  <div
                    className="skills d-flex flex-wrap gap-2 my-4"
                    id="ofr_skills"
                  >
                    {jobskills.map((skill, index) => {
                      return (
                        <span
                          className="d-block border border-gray text-primary p-2"
                          key={index}
                        >
                          {skill.name}
                        </span>
                      );
                    })}
                  </div>

                  <ul className="d-flex align-items-center">
                    <li className="border-end border-gray pe-4 me-4">
                      <p className="text-primary job_type" id="ofr_jobtype">
                        {jobtype}
                      </p>
                    </li>
                    <li className="border-end border-gray pe-4 me-4">
                      Exp :
                      <span className="text-primary" id="ofr_experience">
                        {jobrequiredexperience}
                      </span>{" "}
                      Yrs
                    </li>
                    <li className="text-primary d-flex align-items-center gap-1">
                      WFH :{" "}
                      <p className="text-primary job_type" id="ofr_whf">
                        {remotejob}
                      </p>
                    </li>
                  </ul>
                </div>

                {/* <!-- status history --> */}
                <div className="company-details border-top border-gray py-3">
                  <h5 className="designation fw-bold text-primary mb-2">
                    Response Status History{" "}
                  </h5>

                  <ul className="align-items-center" id="resp_history">
                    {responseActivitylist.map((activity, index) => {
                      return (
                        <li className="py-2" key={index}>
                          <span className="material-icons-outlined">
                            history
                          </span>{" "}
                          <span className="text-primary">
                            {activity.activitydate}
                          </span>{" "}
                          :{" "}
                          <span className="text-primary">
                            {activity.comments}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="col-md-8 px-4 py-3 border-start">
                <div className="prsnl_detailsArea pb-4 mb-4 border-bottom">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="profile-avatar rounded-circle overflow-hidden mx-auto border">
                        <img
                          src={
                            candidateProfilePic === ""
                              ? "/images/default-user.png"
                              : url.SERVER_URL + candidateProfilePic
                          }
                          id="resp_image"
                          className="img-fluid w-100 h-100 avatar"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row mb-0">
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill mb-4">
                            <label>First Name</label>
                            <p className="form-control bg-transparent border-0 p-0 text-dark">
                              {candidatename}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill mb-4">
                            <label>Last Name</label>
                            <p className="form-control bg-transparent border-0 p-0 text-dark">
                              {candidatesurnname}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill">
                            <label>Email</label>
                            <p className="form-control bg-transparent border-0 p-0 text-dark">
                              {candidateemail}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input_fill jobinput_fill">
                            <label>Phone No.</label>
                            <p className="form-control bg-transparent border-0 p-0 text-dark">
                              {candidatephone}
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-12">
                          <div className="input_fill jobinput_fill">
                            <label>Skills</label>
                            <div
                              className="skills d-flex flex-wrap gap-2 my-1"
                              id="resp_skills"
                            >
                              {candidateskills.map((skill, index) => {
                                return (
                                  <span
                                    className="d-block border border-gray text-primary p-2"
                                    key={index}
                                  >
                                    {skill.name}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="prsnl_detailsArea pb-4 mb-4">
                  <div className="others_detailsArea px-3">
                    <div className="details_row mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="details_input_txt">
                            <label className="fw-bold text-primary">
                              Resume Summary
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="input_fill jobinput_fill description_fill">
                            <p
                              id="resp_summary"
                              style={{ textAlign: "justify" }}
                            >
                              {resumesummary}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="details_row mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="details_input_txt">
                            <label className="fw-bold text-primary">
                              Resume Details
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="input_fill jobinput_fill description_fill">
                            <p
                              id="resp_details"
                              style={{ textalign: "justify" }}
                              dangerouslySetInnerHTML={{
                                __html: resumedetails,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="details_row mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="details_input_txt">
                            <label className="fw-bold text-primary">
                              Expected CTC
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="row job_fillOuter">
                            <div className="col-4 input_fill jobinput_fill">
                              Min : <span id="resp_ctc">{minctc}</span>
                            </div>
                            <div className="col-4 input_fill jobinput_fill">
                              Max : <span id="resp_maxctc">{maxctc}</span>
                            </div>
                            <div className="col-4 input_fill jobinput_fill">
                              <span id="ofr_currency">{currency}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="details_row attached_row mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="details_input_txt">
                            <label className="fw-bold text-primary">
                              Resume <i>(with data)</i>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <Link
                            to="#"
                            onClick={() => {
                              if (
                                resumewihdatafilename !== "" &&
                                resumewithdatapath !== ""
                              ) {
                                const fileObj = {
                                  name: resumewihdatafilename,
                                  path: url.SERVER_URL + resumewithdatapath,
                                };
                                downloadFileHandler(fileObj);
                              }
                            }}
                          >
                            <span className="material-icons">
                              file_download
                            </span>{" "}
                            <span id="resp_cv_data_name">
                              {resumewihdatafilename}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="details_row attached_row mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="details_input_txt">
                            <label className="fw-bold text-primary">
                              Resume <i>(without data)</i>
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <Link
                            to="#"
                            onClick={() => {
                              if (
                                resumewihoutdatafilename !== "" &&
                                resumewihoutdatapath !== ""
                              ) {
                                const fileObj = {
                                  name: resumewihoutdatafilename,
                                  path: url.SERVER_URL + resumewihoutdatapath,
                                };
                                downloadFileHandler(fileObj);
                              }
                            }}
                          >
                            <span className="material-icons">
                              file_download
                            </span>{" "}
                            <span id="resp_cv_nodata_name"></span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="details_row mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="details_input_txt">
                            <label className="fw-bold text-primary">
                              Video Resume
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="input_fill jobinput_fill d-flex align-items-center justify-content-between">
                            <video
                              width="220"
                              height="140"
                              controls=""
                              src={url.SERVER_URL + videoresumepath}
                              id="resp_cv_video"
                            >
                              Your browser does not support the video tag.
                            </video>
                          </div>

                          <div className="">
                            <Link
                              to="#"
                              className="text-gray"
                              onClick={() =>
                                window.open(
                                  url.SERVER_URL + videoresumepath,
                                  "_blank"
                                )
                              }
                            >
                              <span className="material-icons-outlined">
                                link
                              </span>
                              <span id="resp_video_url"></span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="details_row mb-3">
                      <div className="row">
                        <div className="col-lg-3">
                          <div className="details_input_txt">
                            <label className="fw-bold text-primary">
                              Comments
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="input_fill jobinput_fill description_fill">
                            <p
                              className="form-control bg-transparent border-0 p-0 text-dark"
                              style={{ minHeight: "unset" }}
                              id="resp_cv_comment"
                            >
                              {resumecomments}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="details_row">
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="details_input_txt">
                              <label className="fw-bold text-primary">
                                Promote to phase
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <Select
                              styles={reactSelectStyle("6px")}
                              components={{
                                IndicatorSeparator: () => null,
                              }}
                              placeholder=""
                              options={processList}
                              value={selectedHringprocessvalue}
                              onChange={(val) => {
                                processSelectionHandler(val);
                              }}
                            />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-3">
                            <div className="details_input_txt">
                              <label className="fw-bold text-primary">
                                Mark Winner
                              </label>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <label className="radio-check">
                              <input
                                type="checkbox"
                                name="iswinner"
                                id="ofr_iswinner"
                                checked={isWinner}
                                onChange={(e) => {
                                  setIsWinner(e.target.checked);
                                }}
                              />
                              <span></span>
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3"></div>
                          <div className="col-lg-9 d-flex align-items-center gap-1">
                            <button
                              type="button"
                              className="btn btn-yellow rounded-0 mt-2"
                              onClick={() => {
                                promoteResponseHandler("0");
                              }}
                              disabled={isSaving ? true : false}
                              style={{
                                cursor: isSaving ? "not-allowed" : "pointer",
                              }}
                            >
                              Promote
                              {isSaving && (
                                <div
                                  className="mx-2 spinner-border spinner-border-sm"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              )}
                            </button>

                            {/* {validation.hiringProcessError && (
                              <div className="error-message">
                                <p className="d-flex align-items-center gap-1 text-danger">
                                  <span className="material-icons-outlined">
                                    warning
                                  </span>
                                  <span>
                                    {t("Please select a phase for promote")}
                                  </span>
                                </p>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewResponseModal;
