import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const SurveyCommonHeader = ({ moduleName = "" }) => {
  const params = useParams();

  const { t } = useTranslation(); //for translation

  return (
    <section className="course_tablinks_outer learning_coursetablinks">
      <div className="container-fluid">
        <div className="tab_links">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link
                to={`/admin/survey/question/list/${params.id}`}
                className={`nav-link ${moduleName === "question" && "active"} `}
              >
                <i className="material-icons-outlined">contact_support</i>
                {t("Save Questions to Survey")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/admin/survey/invite/list/${params.id}`}
                className={`nav-link ${moduleName === "invite" && "active"} `}
              >
                <i className="material-icons-outlined">list_alt</i>
                {t("Survey Invites")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/admin/survey/response/list/${params.id}`}
                className={`nav-link ${moduleName === "response" && "active"} `}
              >
                <i className="material-icons-outlined">person_add_alt</i>
                {t("Survey Responses")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SurveyCommonHeader;
