/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CrmCommonLayout from "Components/Crm/Common/CrmCommonLayout";
import ListHeader from "./Header/ListHeader";
import SaveListModal from "./Modal/SaveListModal";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import Loading from "Components/Common/Loading/Loading";
import CrmListFilterModal from "./Modal/CrmListFilterModal";
import AddTagModal from "Components/Common/Modal/AddTagModal";

const CrmListBody = () => {
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem("token");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Crm") }, { title: t("List") }];

  const [isLoading, setIsLoading] = useState(false);
  const [allList, setAllList] = useState([]);

  const [filterTitle, setfilterTitle] = useState("");
  const [filterSlug, setfilterSlug] = useState("");
  const [filterTags, setfilterTags] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedListIds, setselectedListIds] = useState([]);
  const [selectedListId, setselectedListId] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get all lsit
  const getAllList = async () => {
    setselectedListIds([]);
    setRowSelection({});

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_LIST + `?token=${token}`;

      if (filterTitle != "") {
        requestURL = requestURL + `&filtertitle=${filterTitle}`;
      }

      if (filterSlug !== "") {
        requestURL = requestURL + `&filterslug=${filterSlug}`;
      }

      if (filterTags.length > 0) {
        requestURL = requestURL + `&filtertags=${filterTags}`;
      }

      // console.log(requestURL);

      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        setAllList(response.data);
        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for bulk update/delete
  const bulkUpdateHandler = async () => {
    if (selectedListIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = allList.filter((item) =>
          selectedListIds.includes(item._id)
        );

        let updatedData = {
          alllist: listToBeUpdated,
          status: "0",
        };

        let requestURL =
          url.API_BASE_URL + url.API_BULK_UPDATE_LIST + `?token=${token}`;

        const response = await putData(requestURL, updatedData);

        setIsUpdating(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllList();
          }, 2000);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please select atleast one list."));
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (filterTitle != "" || filterTags.length > 0 || filterSlug !== "") {
      getAllList();
    }
  }, [filterTitle, filterSlug, filterTags]);

  useEffect(() => {
    getAllList();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      size: 30,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="modal"
          data-bs-target="#saveListModal"
          aria-controls="saveListModal"
          onClick={() => {
            setselectedListId(row.original._id);
          }}
        >
          <i className="material-icons horz_icon">more_horiz</i>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "title",
      header: t("Title"),
    },
    {
      accessorKey: "slug",
      header: t("Slug"),
    },
    {
      accessorKey: "contactcount",
      // header: t("Contacts"),
      header: t("No of records"),

      // Cell: ({ row }) => (
      //   <Link
      //     to={`/admin/list/member/manage/${row.original._id}`}
      //     className="text-info d-flex align-items-center"
      //   >
      //     {" "}
      //     {row.original.contactcount} Members
      //     <i className="material-icons ms-1">users</i>
      //   </Link>
      // ),
    },
    {
      accessorKey: "moderatorname",
      header: t("Moderator"),

      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          <img
            className="rounded-circle"
            height={40}
            width={45}
            src={
              row.original.moderatorimage == ""
                ? "/images/default-user.png"
                : url.SERVER_URL + row.original.moderatorimage
            }
            alt="Babcock"
          />
          <p>
            <span className="d-block fw-bold">
              {row.original.moderatorname}
            </span>
            <span className="d-block">{row.original.moderatoremail}</span>
          </p>
        </div>
      ),
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
    },
    {
      accessorKey: "listdate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setselectedListIds(selectedIdsArray);
    } else {
      setselectedListIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CrmCommonLayout breadcrumbText={breadcrumbText} moduleName="list">
      <ListHeader
        breadCrumbText={breadcrumbText}
        bulkUpdateHandler={bulkUpdateHandler}
        reloadList={getAllList}
      />

      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={allList} // data from api to be displayed
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{
              rowSelection,
              columnOrder: columnOrder,
            }} //pass our managed row selection state to the table to use
            initialState={{
              rowSelection,
              columnOrder: columnOrder,
            }}
            enableColumnOrdering={true}
            //   onColumnVisibilityChange={onColumnVisiblityHandler}
            //   onColumnOrderChange={changeColumnOrderHandler}
            defaultColumn={{
              minSize: 20,
              maxSize: 300,
              size: 50, //make columns wider by default
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
            muiSearchTextFieldProps={{
              placeholder: t("Search by Title"),
            }}
          />
        </div>
      )}

      <SaveListModal
        afterSave={() => {
          setTimeout(() => {
            getAllList();
          }, 2000);
        }}
        listId={selectedListId}
        resetListId={() => {
          setselectedListId(null);
        }}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <CrmListFilterModal
        setfilterTitle={setfilterTitle}
        setfilterSlug={setfilterSlug}
        setfilterTags={setfilterTags}
      />

      <AddTagModal
        moduleName="list"
        selectedIds={selectedListIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllList();
          }, 2000);
        }}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CrmCommonLayout>
  );
};

export default CrmListBody;
