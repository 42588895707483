/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";
const CategoryListFilterModal = ({
  parentCategories,
  setFilterCategoryName,
  setFilterCategorySlug,
  setfilterParents,
  setfilterFeatured,
  setFilterIsRoot,
}) => {
  const { t } = useTranslation(); //for translation
  const [name, setname] = useState("");
  const [slug, setslug] = useState("");
  const [parentValue, setparentValue] = useState(null);
  const [parentIds, setparentIds] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isRoot, setIsRoot] = useState(false);

  const parentSelectionHandler = (val) => {
    setparentValue(val);
    setparentIds(val.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setFilterCategoryName(name);
    setFilterCategorySlug(slug);
    setfilterParents(parentIds);
    setfilterFeatured(isFeatured);
    setFilterIsRoot(isRoot);

    // close the offcanvas of filter
    let bootstrapModal = document.querySelector("#categoryFilterModal");
    let currentModal = bootstrap.Modal.getInstance(bootstrapModal);
    currentModal.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    //     removeActiveClass();
    setname("");
    setslug("");
    setparentValue(null);
    setparentIds([]);
    setIsFeatured(false);
    setIsRoot(false);
  };

  return (
    <div
      className="modal fade"
      id="categoryFilterModal"
      tabIndex="-1"
      aria-labelledby="categoryFilterModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyword="false"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0 mb-2">
            <h2 className="text-green w-100" id="eventfilterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <form
              onSubmit={(e) => e.preventDefault()}
              className="d-flex flex-column h-100"
            >
              <div className="fields-container flex-fill">
                <div className="form-group mb-4">
                  <label
                    htmlFor="labelName"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Name")}
                  </label>
                  <input
                    type="text"
                    id="labelName"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Enter here")}
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>

                {/* slug */}
                <div className="form-group mb-4">
                  <label
                    htmlFor="slugID"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Slug")}
                  </label>
                  <input
                    type="text"
                    id="slug"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Enter here")}
                    value={slug}
                    onChange={(e) => {
                      setslug(e.target.value);
                    }}
                  />
                </div>

                {/* parent  */}
                <div className="form-group mb-4">
                  <label
                    htmlFor="slugID"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Select Parent")}
                  </label>
                  <Select
                    styles={reactSelectStyle("6px", "5px")}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    isMulti
                    placeholder={t("Select Parent")}
                    options={parentCategories}
                    value={parentValue}
                    onChange={(val) => parentSelectionHandler(val)}
                  />
                </div>

                {/* is root */}
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                  <input
                    type="checkbox"
                    checked={isRoot}
                    onChange={(e) => {
                      setIsRoot(e.target.checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="private">
                    {t("Is Root")}
                  </label>
                </div>

                {/* is featured */}
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                  <input
                    type="checkbox"
                    checked={isFeatured}
                    onChange={(e) => setIsFeatured(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="private">
                    {t("Is Featured")}
                  </label>
                </div>
              </div>
              <div className="action d-flex justify-content-between gap-3 mb-4">
                <button
                  onClick={searchHandler}
                  type="button"
                  className="btn btn-info rounded-0 shadow-none w-100"
                >
                  {t("Search")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryListFilterModal;
