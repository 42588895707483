/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { reactSelectStyle } from "Config/Config";

const SurveyListFilterModal = ({
  tagList,
  setFilterSurveyName,
  setFilterSurveyCode,
  setfilterSurveyDateRange,
  setfilterSurveyTags,
}) => {
  const { t } = useTranslation(); //for translation
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [name, setname] = useState("");
  const [code, setcode] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [tagValues, settagValues] = useState([]);

  //function for select event tags
  const tagSelectionHandler = (data) => {
    settagValue(data);
    settagValues(data.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "" && toDate != "" && fromDate < toDate) {
      setfilterSurveyDateRange(fromDate + "," + toDate);
    } else if (fromDate != "") {
      setfilterSurveyDateRange(fromDate);
    }

    setFilterSurveyName(name);
    setFilterSurveyCode(code);
    setfilterSurveyTags(tagValues);

    // close the modal
    let currentModal = document.querySelector("#filterSurveyModal");
    let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
    bootstrapModal.hide();

    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    setname("");
    setfromDate("");
    settoDate("");
    settagValue(null);
    settagValues([]);
    setcode("");
  };

  return (
    <div
      className="modal fade"
      id="filterSurveyModal"
      aria-labelledby="filterSurveyModalLabel"
      data-bs-backdrop="static"
      data-bs-keyword="false"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      {" "}
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0 mb-2">
            <h2 className="text-green w-100" id="eventfilterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row mb-3">
                <div className="col-6">
                  <label htmlFor="startDate" className="fw-bold mb-2">
                    From Date
                  </label>
                  <input
                    type="date"
                    name="fromdate"
                    id="startDate"
                    className="form-control text-green rounded-0"
                    value={fromDate}
                    onChange={(e) => setfromDate(e.target.value)}
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="endDate" className="fw-bold mb-2">
                    To Date
                  </label>
                  <input
                    type="date"
                    name="todate"
                    id="endDate"
                    className="form-control text-green rounded-0"
                    value={toDate}
                    onChange={(e) => settoDate(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  id="fname"
                  name="query"
                  placeholder="Name"
                  className="form-control text-green rounded-0"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  {t("Code")}
                </label>
                <input
                  type="text"
                  id="fname"
                  name="query"
                  placeholder="Code"
                  className="form-control text-green rounded-0"
                  value={code}
                  onChange={(e) => setcode(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="status" className="fw-bold mb-2">
                  labels
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  placeholder={t("Select tags")}
                  options={tagList}
                  value={tagValue}
                  onChange={(val) => {
                    tagSelectionHandler(val);
                  }}
                />
              </div>
            </form>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={searchHandler}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyListFilterModal;
