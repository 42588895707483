/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";

// clipboardCopy for mail variables
import clipboardCopy from "clipboard-copy";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CommonLayout from "Components/Common/Layout/CommonLayout";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import SurveyCommonHeader from "../Common/SurveyCommonHeader";
import AddTagModal from "Components/Common/Modal/AddTagModal";
import SurveyInviteHeader from "./Header/SurveyInviteHeader";
import SurveyInvitePopup from "./Popup/SurveyInvitePopup";
import SurveyInviteListFilterModal from "./Modal/SurveyInviteListFilterModal";

const SurveyInviteBody = () => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  const [surveyId, setsurveyId] = useState(null);
  const [name, setname] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [inviteList, setinviteList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedInviteIds, setSelectedInviteIds] = useState([]);

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterName, setfilterName] = useState("");
  const [filterEmail, setfilterEmail] = useState("");
  const [filterTags, setfilterTags] = useState([]);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* ---- bread crumb text ---- */
  const breadCrumbText = [
    { title: t("Survey"), link: "/admin/survey/manage" },
    { title: `${t("Invite")} / ${name}` },
  ];

  //function for get survey details
  const getSurveyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status) {
        setsurveyId(response.data._id);
        setname(response.data.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all invites
  const getAllInvites = async () => {
    setRowSelection({});
    setSelectedInviteIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_INVITES +
        `?token=${token}&filtersurveyid=${params.id}`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterEmail != "") {
        requestUrl = requestUrl + `&filteremail=${filterEmail}`;
      }

      if (filterName != "") {
        requestUrl = requestUrl + `&filtername=${filterName}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setinviteList(response.data);
        resetFilter();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset all filter states
  const resetFilter = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterName("");
    setfilterEmail("");
    setfilterTags([]);
  };

  //copy url function
  const copyUrlHandler = () => {
    if (surveyId) {
      let copiedUrl = `${url.BACKEND_BASE_URL}/admin/survey/feedback/${surveyId}`;

      clipboardCopy(copiedUrl);

      setAlertMessage(t("Url copied successfully"));
      setMessageType("success");
      setShowAlert(true);
    }
  };

  useEffect(() => {
    if (params.id) {
      getSurveyDetails();
      getAllInvites();
    }
  }, [params.id]);

  useEffect(() => {
    if (
      (filterStartDate !== "" && filterEndDate !== "") ||
      filterEmail != "" ||
      filterName != "" ||
      filterTags.length > 0
    ) {
      getAllInvites();
    }
  }, [filterName, filterEmail, filterStartDate, filterEndDate, filterTags]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "invitedusername",
        header: t("Name"),
        Cell: ({ row }) => (
          <div className="name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="profile d-flex align-items-center gap-2">
                <div
                  className="avatar rounded-circle overflow-hidden"
                  style={{ width: "35px", height: "35px" }}
                >
                  <img
                    src={
                      row.original.inviteuserimage == ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + row.original.inviteuserimage
                    }
                    alt="Richard Hawail"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <div>
                  <p className="fs-md fw-semibold mb-0">
                    {row.original.invitedusername}
                  </p>
                  <p>{row.original.email}</p>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "inviterusername",
        header: t("Invited By"),
        Cell: ({ row }) => (
          <div className="name border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <div className="profile d-flex align-items-center gap-2">
                <div
                  className="avatar rounded-circle overflow-hidden"
                  style={{ width: "35px", height: "35px" }}
                >
                  <img
                    src={
                      row.original.inviteruserimage == ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + row.original.inviteruserimage
                    }
                    alt="Richard Hawail"
                    className="w-100 h-100 object-fit-cover object-center"
                  />
                </div>
                <div>
                  <p className="fs-md fw-semibold mb-0">
                    {row.original.inviterusername}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "questionsubmitted",
        header: t("Question Attended"),
      },
      {
        accessorKey: "invitedate",
        header: t("Date"),
      },
      {
        accessorKey: "tagstring",
        header: t("Tags"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInviteIds(selectedIdsArray);
    } else {
      setSelectedInviteIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <SurveyCommonHeader moduleName="invite" />

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <SurveyInviteHeader
            breadCrumbText={breadCrumbText}
            reloadList={getAllInvites}
            name={name}
            copyUrlHandler={copyUrlHandler}
          />

          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={inviteList} // data from api to be displayed
                enableGrouping
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection }} //pass our managed row selection state to the table to use
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>

      {/* ---- add label popup --- */}
      <AddTagModal
        moduleName="surveyinvite"
        selectedIds={selectedInviteIds}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllInvites();
          }, 2000);
        }}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <SurveyInvitePopup
        afterCloseModalHandler={getAllInvites}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <SurveyInviteListFilterModal
        setfilterStartDate={setfilterStartDate}
        setfilterEndDate={setfilterEndDate}
        setfilterName={setfilterName}
        setfilterEmail={setfilterEmail}
        setfilterTags={setfilterTags}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default SurveyInviteBody;
