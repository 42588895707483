/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CreateEmailPopup from "Components/Common/Popup/CreateEmailPopup";
import ViewAndReplyEmailPopup from "Components/Common/Popup/ViewAndReplyEmailPopup";
import AddTagModal from "Components/Common/Modal/AddTagModal";

const ConversationTab = ({
  contactData = {},
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [mailList, setMailList] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMailIds, setSelectedMailIds] = useState([]);
  const [selectedMailId, setSelectedMailId] = useState(null);

  //function for get all mails
  const getAllMails = async () => {
    setRowSelection({});
    setSelectedMailIds([]);

    try {
      setIsLoading(true);

      let requestURL = `${url.API_BASE_URL}${url.API_GET_ALL_MESSAGE}?token=${token}&filterpersonemail=${contactData.contactemail}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMailList(response.data);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for delete mail
  const deleteBulkMailHandler = async () => {
    if (selectedMailIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = mailList.filter((item) =>
          selectedMailIds.includes(item._id)
        );

        let apiData = {
          messagelist: listToBeUpdated,
          status: "0",
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_BULK_MESSAGE + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllMails();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one item");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (contactData.contactemail && contactData.contactemail !== "") {
      getAllMails();
    }
  }, [contactData]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            onClick={() => {
              document.body.classList.toggle("reply_openPopup");
              setSelectedMailId(row.original._id);
            }}
          >
            <span className="d-block material-icons-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "sendername",
        header: t("Sender"),

        Cell: ({ row }) => (
          <div className="gmail_textOuter">
            <div className="marking_star me-3">
              {/* <span
                className={`material-icons-outlined ${
                  row.original.tracker === "2" ? "text-gray" : ""
                }`}
                data-toggle="tooltip"
                data-placement="top"
                title="Mail read by recepient"
              >
                {" "}
                {row.original.tracker === "2"
                  ? "mark_email_read"
                  : "mark_email_unread"}
              </span> */}
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "22px", height: "22px" }}
              >
                <img
                  src={
                    row.original.senderimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.senderimage
                  }
                  alt="Alan Benjamin"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
            </div>

            <div className="gmail_text">
              {row.original.sendername}
              <p className="text-gray-600">{row.original.sendermail}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "receivername",
        header: t("Receiver"),

        Cell: ({ row }) => (
          <div className="gmail_textOuter">
            <div className="marking_star me-3">
              {/* <span
                className={`material-icons-outlined ${
                  row.original.tracker === "2" ? "text-gray" : ""
                }`}
                data-toggle="tooltip"
                data-placement="top"
                title="Mail read by recepient"
              >
                {" "}
                {row.original.tracker === "2"
                  ? "mark_email_read"
                  : "mark_email_unread"}
              </span> */}
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "22px", height: "22px" }}
              >
                <img
                  src={
                    row.original.recieverimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.recieverimage
                  }
                  alt="Alan Benjamin"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
            </div>

            <div className="gmail_text">
              {row.original.receivername}
              <p className="text-gray-600">{row.original.receivermail}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "subject",
        header: t("Mail Subject"),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
      },
      {
        accessorKey: "maildate",
        header: t("Date"),
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedMailIds(selectedIdsArray);
    } else {
      setSelectedMailIds([]);
    }
  }, [rowSelection]);

  return (
    <div className="mail-container">
      <div className="filter-container mb-3">
        <div className="row align-items-center">
          <div className="col-sm-12">
            <div className="d-flex align-items-center justify-content-sm-end gap-2">
              <Link
                to="#"
                className="btn btn-secondary rounded-0 shadow-none  "
                onClick={getAllMails}
              >
                <i className="material-icons">refresh</i>
              </Link>
              <Link
                type="button"
                to="#addNewEmail"
                className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="addNewEmail"
              >
                <i className="material-icons me-2">add</i> Compose Mail{" "}
              </Link>
              <div className="dropdown" id="module-mass-action">
                <button
                  className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Action
                </button>
                <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#addTagModal"
                    >
                      Add Label
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="#"
                      onClick={deleteBulkMailHandler}
                    >
                      Delete
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={mailList} // data from api to be displayed
            positionActionsColumn="last"
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) => row._id} // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
            defaultColumn={{
              minSize: 20,
              maxSize: 200,
              size: 50, //make columns wider by default
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
          />
        </div>
      )}

      <CreateEmailPopup
        reloadList={getAllMails}
        contactName={contactData.contactname}
        contactPersonEmail={contactData.contactemail}
      />

      <ViewAndReplyEmailPopup
        selectedMailId={selectedMailId}
        setSelectedMailId={setSelectedMailId}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        reloadList={getAllMails}
        repliedToUserEmail={contactData.contactemail}
      />

      <AddTagModal
        moduleName="conversation"
        selectedIds={selectedMailIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllMails();
          }, 2000);
        }}
      />
    </div>
  );
};

export default ConversationTab;
