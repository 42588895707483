/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*import url and gateway methods */
import { getData, putData } from "Utils/Gateway";
import * as url from "Helper/UrlHelper";

import CrmCommonLayout from "Components/Crm/Common/CrmCommonLayout";
import ConversationHeader from "./Header/ConversationHeader";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CreateEmailPopup from "Components/Common/Popup/CreateEmailPopup";
import AddTagModal from "Components/Common/Modal/AddTagModal";
import ViewAndReplyEmailPopup from "Components/Common/Popup/ViewAndReplyEmailPopup";
import ConversationFilterModal from "./Modal/ConversationFilterModal";
import Loading from "Components/Common/Loading/Loading";

const ConversationBody = () => {
  const token = localStorage.getItem("token");

  const location = useLocation();
  const moduleName = location.pathname;
  const currentModule = moduleName.split("/").pop(); // Split by '/' and get the last element

  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("CRM") },
    { title: t("Conversation") },
    { title: t(currentModule) },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [mailList, setMailList] = useState([]);

  const [filterMailType, setfilterMailType] = useState("0");
  const [filterPersonQuery, setfilterPersonQuery] = useState("");
  const [filterSubject, setfilterSubject] = useState("");
  const [filterTags, setfilterTags] = useState([]);
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");

  const [resetFilterState, setresetFilterState] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMailIds, setSelectedMailIds] = useState([]);
  const [selectedMailId, setSelectedMailId] = useState(null);

  //** alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all mails
  const getAllMails = async () => {
    setRowSelection({});
    setSelectedMailIds([]);

    try {
      setIsLoading(true);

      let requestURL = `${url.API_BASE_URL}${url.API_GET_ALL_MESSAGE}?token=${token}&currentmodule=${currentModule}&filtermailtype=${filterMailType}`;

      if (filterPersonQuery !== "") {
        requestURL += `&filterpersonquery=${filterPersonQuery}`;
      }

      if (filterSubject !== "") {
        requestURL += `&filtersubject=${filterSubject}`;
      }

      if (filterTags.length > 0) {
        requestURL += `&filtertags=${filterTags.join(",")}`;
      }

      if (filterStartDate !== "") {
        requestURL += `&filterstartdate=${filterStartDate}`;
      }

      if (filterEndDate !== "") {
        requestURL += `&filterenddate=${filterEndDate}`;
      }

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMailList(response.data);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for delete mail
  const deleteBulkMailHandler = async () => {
    if (selectedMailIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = mailList.filter((item) =>
          selectedMailIds.includes(item._id)
        );

        let apiData = {
          messagelist: listToBeUpdated,
          status: "0",
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_BULK_MESSAGE + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllMails();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one item");
    }
    setShowAlert(true);
  };

  //reset all filters
  const resetFilters = () => {
    setfilterMailType("0");
    setfilterPersonQuery("");
    setfilterSubject("");
    setfilterTags("");
    setfilterStartDate("");
    setfilterEndDate(" ");
  };

  const resetAction = () => {
    setresetFilterState(true);
  };

  useEffect(() => {
    if (resetFilterState) {
      getAllMails();
      resetFilters();
    }
  }, [resetFilterState]);

  useEffect(() => {
    if (
      filterMailType ||
      filterPersonQuery !== "" ||
      filterSubject !== "" ||
      filterTags.length > 0 ||
      filterStartDate !== "" ||
      filterEndDate !== ""
    ) {
      getAllMails();
    }
  }, [
    filterMailType,
    filterPersonQuery,
    filterSubject,
    filterTags,
    filterStartDate,
    filterEndDate,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            onClick={() => {
              document.body.classList.toggle("reply_openPopup");
              setSelectedMailId(row.original._id);
            }}
          >
            <span className="d-block material-icons-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "sendername",
        header: currentModule === "sent" ? t("Send to") : t("Sender"),
        size: 250,
        Cell: ({ row }) => (
          <div className="gmail_textOuter">
            <div className="marking_star me-3">
              {/* <span
                className={`material-icons-outlined ${
                  row.original.tracker === "2" ? "text-gray" : ""
                }`}
                data-toggle="tooltip"
                data-placement="top"
                title="Mail read by recepient"
              >
                {" "}
                {row.original.tracker === "2"
                  ? "mark_email_read"
                  : "mark_email_unread"}
              </span> */}
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "22px", height: "22px" }}
              >
                <img
                  src={
                    currentModule === "sent"
                      ? row.original.recieverimage === ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + row.original.recieverimage
                      : row.original.senderimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.senderimage
                  }
                  alt="Alan Benjamin"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
            </div>

            <div className="gmail_text">
              {currentModule === "sent"
                ? `To : ${row.original.receivername}`
                : row.original.sendername}

              <p className="text-gray-600">
                {currentModule === "sent"
                  ? row.original.receivermail
                  : row.original.sendermail}
              </p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "subject",
        header: t("Mail Subject"),
        size: 250,
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        size: 200,
      },
      {
        accessorKey: "maildate",
        header: t("Date"),
        size: 250,
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedMailIds(selectedIdsArray);
    } else {
      setSelectedMailIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CrmCommonLayout moduleName="conversation">
      <ConversationHeader
        resetAction={resetAction}
        breadCrumbText={breadcrumbText}
        deleteHandler={deleteBulkMailHandler}
      />

      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={mailList} // data from api to be displayed
            positionActionsColumn="last"
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) => row._id} // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
            defaultColumn={{
              minSize: 20,
              maxSize: 200,
              size: 50, //make columns wider by default
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
            muiSearchTextFieldProps={{
              placeholder: t("Search by name / email / subject"),
            }}
          />
        </div>
      )}

      <CreateEmailPopup reloadList={getAllMails} />

      <ViewAndReplyEmailPopup
        selectedMailId={selectedMailId}
        setSelectedMailId={setSelectedMailId}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        reloadList={getAllMails}
      />

      <ConversationFilterModal
        currentModule={currentModule}
        resetFilterState={resetFilterState}
        setresetFilterState={setresetFilterState}
        setfilterMailType={setfilterMailType}
        setfilterPersonQuery={setfilterPersonQuery}
        setfilterSubject={setfilterSubject}
        setfilterTags={setfilterTags}
        setfilterStartDate={setfilterStartDate}
        setfilterEndDate={setfilterEndDate}
      />

      <AddTagModal
        moduleName="conversation"
        selectedIds={selectedMailIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllMails();
          }, 2000);
        }}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CrmCommonLayout>
  );
};

export default ConversationBody;
