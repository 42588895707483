/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData, uploadSingleFile } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import ChallengeDetailsCommonHeader from "../Common/Header/ChallengeDetailsCommonHeader";
import { downloadFileHandler } from "Helper/CommonHelper/CommonHelper";

const ChallengeDocBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: challengeId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Challenges"), link: "/admin/challenges/manage" },
    { title: "" },
  ]);

  const [uploadedDoc, setUploadedDoc] = useState(null);

  const [challengeDocList, setChallengeDocList] = useState([]);
  const [challengeDocIds, setChallengeDocIds] = useState([]);

  const [isDocAdding, setIsDocAdding] = useState(false);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get job details
  const getJobDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${challengeId}?token=${token}`;

      const response = await getData(requestURL);

      console.log("response in job details ----->", response);

      if (response.status) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("Challenges"), link: "/admin/challenges/manage" },
          { title: response.data.title },
        ]);

        setChallengeDocIds(response.data.uploads);
        setChallengeDocList(response.data.uploadedfiles);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for add to challenge
  const addToChallengeHandler = async () => {
    try {
      setIsDocAdding(true);

      const filterequestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const fileResponse = await uploadSingleFile(filterequestURL, uploadedDoc);

      if (fileResponse.status) {
        const challengeData = {
          uploads: [...challengeDocIds, fileResponse.data._id],
        };

        if (challengeId) {
          const requestURL =
            url.API_BASE_URL +
            url.API_UPDATE_CHALLENGE +
            `/${challengeId}?token=${token}`;

          const response = await putData(requestURL, challengeData);

          setIsDocAdding(false);

          if (response.status) {
            setMessageType("success");
            setTimeout(() => {
              setUploadedDoc(null);
              resetDoc();
              getJobDetails();
            }, 1500);
          } else {
            setMessageType("error");
          }
          setAlertMessage(response.message);
        }
      } else {
        setMessageType("error");
        setAlertMessage(fileResponse.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //reset doc
  const resetDoc = () => {
    const file = document.getElementById("challengedocumentupload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //delete doc
  const deleteDocHandler = async (index) => {
    const filteredBlock = [...challengeDocList];
    const filterIds = [...challengeDocIds];

    filteredBlock.splice(index, 1);
    filterIds.splice(index, 1);

    setChallengeDocIds(filterIds);
    setChallengeDocList(filteredBlock);

    try {
      let apiData = {
        uploads: filterIds,
      };

      let requestURL = url.API_BASE_URL;

      let response = {};

      if (eventId) {
        requestURL +=
          url.API_UPDATE_CHALLENGE + `/${challengeId}?token=${token}`;
        response = await putData(requestURL, apiData);
      }

      if (response.status) {
        setMessageType("success");
        setAlertMessage(t("Image deleted successfully"));
        setTimeout(() => {
          getJobDetails();
        }, 1500);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  useEffect(() => {
    if (challengeId) {
      getJobDetails();
    }
  }, [challengeId]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <ChallengeDetailsCommonHeader moduleName="docs" />

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="event_gallery_wrapper" className="p-4 bg-gray-100">
            <div className="image-upload-container mb-5">
              <label
                htmlFor="featuredImage"
                className="d-block fw-bold text-primary mb-3"
              >
                Documents
              </label>

              <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3 mb-4">
                <label
                  htmlFor="logoUpload"
                  className="text-nowrap me-4 text-primary"
                >
                  Upload Document
                </label>
                <div className="fields-container d-flex flex-fill">
                  <input
                    type="file"
                    id="challengedocumentupload"
                    name="documents"
                    className="form-control rounded-0 shadow-none"
                    onChange={(e) => {
                      setUploadedDoc(e.target.files[0]);
                    }}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-yellow rounded-0"
                  onClick={addToChallengeHandler}
                  disabled={isDocAdding ? true : false}
                  style={{
                    cursor: isDocAdding ? "not-allowed" : "pointer",
                  }}
                >
                  Add to Challenge
                  {isDocAdding && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>

            <div className="gallery-images">
              <h6 className="text-primary fw-bold mb-3">Add Documents</h6>
              {/* <ul className="images d-flex flex-wrap">

														
								
								
								
								
								

							<li className="image">
								<div className="img-container position-relative">
									<img src="/bundles/indra-backend/images/doc.svg" className="img-fluid docthumb"/>

									<div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
										<a href="/uploads/documents/1671096201-Política de Cookies ChampionsATM_v.1.0 (1).docx" className="text-white" download=""><i className="d-block material-icons icon-sm">download</i></a>

										<a href="/admin/challenge/deletemedia?id=84&amp;did=5468" className="text-white"><i className="d-block material-icons icon-sm">delete</i></a>

									</div>

								</div>
							</li>
							
								
								
																									
								
								

							<li className="image">
								<div className="img-container position-relative">
									<img src="/bundles/indra-backend/images/pdf.svg" className="img-fluid docthumb"/>

									<div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
										<a href="/uploads/documents/1737722009-sample.pdf" className="text-white" download=""><i className="d-block material-icons icon-sm">download</i></a>

										<a href="/admin/challenge/deletemedia?id=84&amp;did=5973" className="text-white"><i className="d-block material-icons icon-sm">delete</i></a>

									</div>

								</div>
							</li>
																					
						</ul> */}

              <ul className="images d-flex flex-wrap">
                {challengeDocList.map((doc, index) => {
                  const fileExtension = doc.name.split(".").pop();

                  const updatedDoc = {
                    ...doc,
                    path: url.SERVER_URL + doc.path,
                  };
                  return (
                    <li className="image" key={index}>
                      <div className="img-container position-relative">
                        <img
                          src={
                            fileExtension === "pdf"
                              ? "/images/pdf.svg"
                              : fileExtension === "xlsx"
                              ? "/images/xls.svg"
                              : "/images/doc.svg"
                          }
                          className="img-fluid docthumb"
                        />

                        <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 bottom-0">
                          <Link
                            to="#"
                            className="text-white"
                            onClick={() => {
                              downloadFileHandler(updatedDoc);
                            }}
                          >
                            <i className="d-block material-icons icon-sm">
                              download
                            </i>
                          </Link>

                          <Link
                            to="#"
                            className="text-white"
                            onClick={() => deleteDocHandler(index)}
                          >
                            <i className="d-block material-icons icon-sm">
                              delete
                            </i>
                          </Link>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default ChallengeDocBody;
