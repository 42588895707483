/* eslint-disable */
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

const SaveTagModal = ({
  afterSave = () => {},
  tagId = null,
  resetTagId,
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [labelName, setLabelName] = useState("");
  const [labelSlug, setLabelSlug] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  //validation
  const validationHandler = () => {
    let isValid = true;

    if (labelName === "") {
      setShowAlert(true);
      setAlertMessage(t("Please enter title"));
      setMessageType("error");
      isValid = false;
    }

    if (labelSlug === "") {
      setShowAlert(true);
      setAlertMessage(t("Please enter slug"));
      setMessageType("error");
      isValid = false;
    }

    return isValid;
  };

  //get tag details
  const getTagDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_TAG_DETAILS +
        `/${tagId}` +
        `?token=${token}`;

      const response = await getData(requestURL);

      // console.log(response);

      if (response.status) {
        setLabelName(response.data.title);
        setLabelSlug(response.data.slug);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for create new tag
  const saveTagHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);

      try {
        let tagData = {
          title: labelName,
          slug: labelSlug.toUpperCase(),
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (tagId) {
          requestURL =
            requestURL + url.API_UPDATE_TAG + `/${tagId}` + `?token=${token}`;

          response = await putData(requestURL, tagData);
        } else {
          requestURL = requestURL + url.API_CREATE_NEW_TAG + `?token=${token}`;

          response = await postData(requestURL, tagData);
        }

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          // close the modal
          let currentModal = document.querySelector("#tagModalSave");
          let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
          bootstrapModal.hide();

          resetHandler();
          afterSave();
        } else {
          setMessageType("error");
          setErrorResponseMessage(response.message);
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    }
    setShowAlert(true);
  };
  /* reset */
  const resetHandler = () => {
    setLabelName("");
    setLabelSlug("");
    resetTagId();
  };

  useEffect(() => {
    if (tagId) {
      getTagDetails();
    }
  }, [tagId]);

  return (
    <div class="edit_modalbx">
      <div
        class="modal fade"
        id="tagModalSave"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        style={{ display: "none" }}
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close_btn ylw_closeBtn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetHandler}
              >
                <span class="material-icons-outlined">close </span>
              </button>
            </div>
            <div class="modal-body edit_modalbody">
              <div class="srvay_feedhdng">
                <h2>Save Label</h2>
              </div>

              <div class="edit_fillOuter">
                <div class="row">
                  <div class="col-md-12">
                    <div class="input_fill white_fill">
                      <label>Name *</label>
                      <input
                        placeholder={t("Enter Name")}
                        type="text"
                        name="name"
                        class="form-control"
                        value={labelName}
                        onChange={(e) => setLabelName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="input_fill white_fill">
                      <label>Slug *</label>
                      <input
                        placeholder={t("Enter slug")}
                        type="text"
                        class="form-control"
                        value={labelSlug}
                        onChange={(e) => setLabelSlug(e.target.value)}
                      />
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="submit_survay">
                      <button
                        type="button"
                        class="basic_btn ylw_btn"
                        disabled={isSaving ? true : false}
                        style={{
                          cursor: isSaving ? "not-allowed" : "pointer",
                        }}
                        onClick={saveTagHandler}
                      >
                        {t("Save")}
                        {isSaving && (
                          <div
                            className="mx-2 spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveTagModal;
