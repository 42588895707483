// --------------------------------LOCAL SERVER -------------------------------------
//dev server url
export const API_BASE_URL = "https://api.v2.habitatm.es/api"; // NODE SERVER URL FOR API
export const SERVER_URL = "https://api.v2.habitatm.es/";
export const BACKEND_BASE_URL = "https://admin.v2.habitatm.es"; // Backend urlgit
export const FRONTEND_BASE_URL = "https://v2.habitatm.es/"; // For frontend

//local server url
// export const API_BASE_URL = "http://localhost:4008/api"; // NODE SERVER URL FOR API
// export const SERVER_URL = "http://localhost:4008";
// export const BACKEND_BASE_URL = "http://localhost:4800"; // Backend urlgit
// export const FRONTEND_BASE_URL = "http://localhost:4206"; // For frontend

/*=========== all Demo Data API URL start here ===========*/
export const DEMO_GET = "/demo/demoget"; // for get demo data

export const DEMO_POST = "/demo/demopost"; // for testing post data
/*=========== all Demo Data API URL end here ===========*/

/*======= login and registration url start =======*/
export const API_LOGIN = "/auth/login"; //login url

export const API_REGISTRATION = "/auth/register"; //registration url

export const API_GET_OTP = "/auth/getotp"; //for getting otp

export const API_VALIDATE_OTP = "/auth/validateotp"; //for validating otp

export const API_UPDATE_PASSSWORD = "/auth/updatepassword"; //for update passowrd

export const API_AUTH_VALIDATE = "/auth/validateuser"; //validation url

/*======= File upload url start =======*/
export const API_SINGLE_FILE_UPLOAD = "/upload/singleupload"; //for single file upload

export const API_MULTIPLE_FILE_UPLOAD = "/upload/multiupload"; //for multiple file upload

/*======= File upload url end =======*/

/** Slide url start */
//create slide
export const API_CREATE_SLIDE = "/slide/createslide";

//get all slides
export const API_GET_ALL_SLIDES = "/slide/getallslides";

//get slide details
export const API_GET_SLIDE_DETAILS = "/slide/getslidedetails";

//update slide
export const API_UPDATE_SLIDE = "/slide/updateslide";

//updateblukslide
export const API_UPDATE_BULK_SLIDE = "/slide/updatebulkslide";

//delete slide
export const API_DELETE_SLIDE = "/slide/deleteslide";
/** Slide url end */

// category url start
//add category
export const API_ADD_CATEGORY = "/category/createcategory";

//get all Categories
export const API_GET_CATEGORIES = "/category/getcategories";

//get category details
export const API_GET_CATEGORY_DETAILS = "/category/getcategorydetails";

//update category
export const API_UPDATE_CATEGORY = "/category/updatecategory";

//updatemassivecategory
export const API_UPDATE_MASSIVE_CATEGORY = "/category/updatemassivecategory";

//deletemassivecategory
export const API_DELETE_MASSIVE_CATEGORY = "/category/deletemassivecategory";

// category url end

/* module url start */
//add module
export const API_CREATE_MODULE = "/module/createmodule";

//get modules
export const API_GET_ALL_MODULES = "/module/getallmodules";

//get module details
export const API_GET_MODULE_DETAILS = "/module/getmoduledetails";

//update module
export const API_UPDATE_MODULE = "/module/updatemodule";

//updatebulkmodule
export const API_UPDATE_BULK_MODULE = "/module/updatebulkmodule";

/* module url end */

// module roles url start
//add module
export const API_CREATE_MODULE_ROLE = "/modulerole/createmodulerole";

//get modules
export const API_GET_ALL_MODULE_ROLE = "/modulerole/getallmoduleroles";

//get module details
export const API_GET_MODULE_ROLE_DETAILS = "/modulerole/getmoduleroledetails";

//update module
export const API_UPDATE_MODULE_ROLE = "/modulerole/updatemodulerole";

//updatebulkmoduleroles
export const API_UPDATE_BULK_MODULE_ROLE = "/modulerole/updatebulkmoduleroles";

// module roles url end

//module role previlege url
//update
export const API_UPDATE_MODULE_ROLE_PREVILEGE =
  "/moduleroleprevilege/updatemoduleroleprevilege";

//get all
export const API_GET_ALL_MODULE_ROLE_PREVILEGES =
  "/moduleroleprevilege/getallmoduleroleprevilege";

//get all
export const API_GET_ALL_MODULE_ACCESS = "/moduleroleprevilege/getmoduleaccess";

/*======= app user url start ========*/

//get all app user
export const API_GET_ALL_APP_USER = "/account/getallappusers";

//get user details
export const API_GET_USER_DETAILS = "/account/getuserdetails";

//update user details
export const API_UPDATE_APP_USER = "/account/updateuserdetails";

//updatebulkuser
export const API_UPDATE_BULK_USER = "/account/updatebulkuser";

//addtagtouser
export const API_ADD_TAG_TO_USER = "/account/addtagtouser";

/*======= app user url end ========*/

/*====== contact url start ======*/
//get all contact
export const API_GET_ALL_CONTACTS = "/contact/getallcontacts";

//get user list options from contact list for select
export const API_GET_USER_CONTACT_LIST_OPTIONS =
  "/contact/getusercontactlistoptions";

//accept/reject invite
export const API_ACCEPT_INVITE = "/contact/updatecontact";

//cancel/reject request
export const API_REJECT_CONTACT_REQUEST = "/contact/rejectrequest";

//unfriend contact
export const API_UNFRIEND_CONTACT = "/contact/unfriendcontact";

//resume contact
export const API_RESUME_CONTACT = "/contact/resumecontact";

//get contact details
export const API_GET_CONTACT_DETAILS = "/contact/getcontactdetails";

//get timeline
export const API_GET_CONTACT_TIMELINE = "/contact/gettimeline";

//addtagtocontact
export const API_ADD_TAG_TO_CONTACT = "/contact/addtagtocontact";

/*====== contact url end ======*/

// role url start

//add role
export const API_ADD_ROLE = "/role/createrole";

export const API_GET_ALL_ROLES = "/role/getroles"; //get app user roles

//get role details
export const API_GET_ROLE_DETAILS = "/role/getroledetails";

//update role
export const API_UPDATE_ROLE = "/role/updaterole";
// role url end

//note url start
//add note
export const API_ADD_NEW_NOTE = "/note/createnote";

//get all note
export const API_GET_ALL_NOTE = "/note/getallnotes";

//get note details
export const API_GET_NOTE_DETAILS = "/note/getnotedetails";

//update note
export const API_UPDATE_NOTE = "/note/updatenote";

//update massivenotes
export const API_UPDATE_MASSIVE_NOTES = "/note/updatemassivenotes";

//delete note
export const API_DELETE_NOTE = "/note/deletenote";

//delete massivenotes
export const API_DELETE_MASSIVE_NOTES = "/note/deletemassivenotes";

//addremovetagnote
export const API_ADD_REMOVE_TAG_NOTE = "/note/addremovetagnote";

//note url end

/*===== list url start =====*/

//create list
export const API_CREATE_LIST = "/list/addnewlist";

//get all list
export const API_GET_ALL_LIST = "/list/getalllist";

//get list details
export const API_LIST_DETAILS = "/list/getlistdetails";

//update list
export const API_UPDATE_A_LIST = "/list/updatelist";

//bulkupdatelist
export const API_BULK_UPDATE_LIST = "/list/bulkupdatelist";

//add tag list
export const API_ADD_REMOVE_TAG_LIST = "/list/addtagtolist";

/*===== list url end =====*/

/*----- tag/label url start ------*/
//create tag
export const API_CREATE_NEW_TAG = "/tag/createtag";

//get all tag
export const API_GET_ALL_TAGS = "/tag/getalltags";

//get tag details
export const API_GET_TAG_DETAILS = "/tag/gettagdetails";

//update tag
export const API_UPDATE_TAG = "/tag/updatetag";

//bulkupdatetag
export const API_BULK_UPDATE_TAG = "/tag/bulkupdatetag";

/*----- tag/label url end ------*/

// task url start
//save task
export const API_ADD_TASK = "/task/addtask";

//getalltasks
export const API_GET_ALL_TASKS = "/task/getalltasks";

//gettaskdetails
export const API_GET_TASK_DETAILS = "/task/gettaskdetails";

//update task
export const API_UPDATE_TASK = "/task/updatetask";

//updatebulktask
export const API_UPDATE_BULK_TASK = "/task/updatebulktask";

//addremovetagtask
export const API_ADD_REMOVE_TAG_TASK = "/task/addremovetagtask";

//task url end

/*===== mail template url start ======*/

//create template
export const API_CREATE_MAIL_TEMPLATE = "/messagetemplate/createmailtemplate";

//get all template
export const API_GET_ALL_MAIL_TEMPLATES = "/messagetemplate/getalltemplates";

//get template details
export const API_GET_TEMPLATE_DETAILS = "/messagetemplate/gettemplatedetails";

//update template
export const API_UPDATE_MAIL_TEMPLATE = "/messagetemplate/updatetemplate";

//updatemassivetemplate
export const API_UPDATE_MASSIVE_TEMPLATE =
  "/messagetemplate/updatemassivetemplate";

//add/remove tag template
export const API_ADD_TAG_TO_TEMPLATE = "/messagetemplate/addtagtotemplate";

/*===== mail template url end ======*/

/*==== mail conversation url start ====*/
//create
export const API_ADD_MESSAGE = "/message/savemail";

//get all
export const API_GET_ALL_MESSAGE = "/message/getallmessages";

//get mail details
export const API_GET_MESSAGE_DETAILS = "/message/getmessagedetails";

//update mail
export const API_UPDATE_MESSAGE = "/message/updatemessage";

//delete
export const API_DELETE_MESSAGE = "/message/deletemail";

//deletebulkmessage
export const API_DELETE_BULK_MESSAGE = "/message/deletebulkmessage";

//add tag mail
export const API_ADD_TAG_MAIL = "/message/addtagtomessage";

/*==== mail conversation url end ====*/

/** survey url start */
export const API_ADD_NEW_SURVEY = "/survey/createsurvey";

//get all survey
export const API_GET_ALL_SURVEY = "/survey/getallsurvey";

//invite user
export const API_INVITE_USER_SURVEY = "/survey/invitesurveytouser";

//getallsurveyinvites
export const API_GET_SURVEY_INVITES = "/survey/getallsurveyinvites";

//api get survey details
export const API_GET_SURVEY_DETAILS = "/survey/getsurveydetails";

// /getsurveyinvitedetails
export const API_GET_SURVEY_INVITE_DETAILS = "/survey/getsurveyinvitedetails";

//update survey
export const API_UPDATE_SURVEY = "/survey/updatesurvey";

export const API_SAVE_RESPONSE_SURVEY = "/survey/addresponsetosurvey";

export const API_GET_SURVEY_INVITE_RESPONSE =
  "/survey/getallsurveyinvitewithresponses";

//getallsurveyresponselist
export const API_GET_ALL_SURVEY_RESPONSE = "/survey/getallsurveyresponselist";

//tag update
export const API_ADD_REMOVE_TAG_SURVEY = "/survey/addremovetagsurvey";
export const API_ADD_REMOVE_TAG_SURVEY_INVITE =
  "/survey/addremovetagsurveyinvite";

//get all question type
export const API_GET_ALL_QUESTIONTYPES = "/survey/getallquestiontype";

//add question to survey
export const API_ADD_QUESTION_TO_SURVEY = "/survey/addquestiontosurvey";

//get question details
export const API_GET_QUESTION_DETAILS = "/survey/getquestiondetails";

//update question
export const API_UPDATE_QUESTION = "/survey/updatequestion";

//delete question from survey
export const API_DELETE_QUESTION = "/survey/deletequestion";

//deletemassivesurvey
export const API_DELETE_MASSIVE_SURVEY = "/survey/deletemassivesurvey";

//deletemassiveinvites
export const API_DELETE_MASSIVE_INVITES = "/survey/deletemassiveinvites";

/*==== group url start =====*/
//create group
export const API_CREATE_GROUP = "/group/creategroup";

//add member in group
export const API_ADD_NEW_GROUP_MEMBER = "/group/addnewmember";

//get all group
export const API_GET_ALL_GROUPS = "/group/getallgroups";

//get all group options
export const API_GET_ALL_GROUP_OPTIONS = "/group/getgrouplistoptions";

//get group details
export const API_GET_GROUP_DETAILS = "/group/getgroupdetails";

//get all group members
export const API_GET_ALL_GROUP_MEMBERS = "/group/getallmembers";

//get group member details
export const API_GET_GROUP_MEMBER_DETAILS = "/group/getmemberdetails";

//update group
export const API_UPDATE_GROUP = "/group/updategroup";

//updatemassivegroup
export const API_UPDATE_MASSIVE_GROUP = "/group/updatemassivegroup";

//update group member
export const API_UPDATE_GROUP_MEMBER = "/group/updategroupmember";

//updateassivegroupmember
export const API_UPDATE_MASSIVE_GROUP_MEMBER = "/group/updateassivegroupmember";

//add/remove tag to group
export const API_ADD_REMOVE_TAG_GROUP = "/group/addremovetaggroup";

//addremovetaggroupmember
export const API_ADD_REMOVE_TAG_GROUP_MEMBER = "/group/addremovetaggroupmember";

//add gallery images
export const API_ADD_GROUP_GALLERY_IMAGES = "/group/addgalleryimage";

//deletemassivegroup
export const API_DELETE_MASSIVE_GROUP = "/group/deletemassivegroup";

//delete massive group members
export const API_DELETE_MASSIVE_GROUP_MEMBERS =
  "/group/deletemassivegroupmember";

//delete gallery image
export const API_DELETE_GROUP_GALLERY_IMAGE = "/group/deletegalleryimage";

/*==== group url end =====*/

/*====== event url start ======*/
//get all event type
export const API_GET_ALL_EVENT_TYPES = "/eventtype/getalleventtypes";

//create event
export const API_CREATE_EVENT = "/event/createevent";

//get all event
export const API_GET_ALL_EVENTS = "/event/getallevents";

//get parnet events
export const API_GET_PARENT_EVENTS = "/event/getparentevents";

//get event list for select
export const API_GET_EVENT_LIST_OPTIONS = "/event/getalleventoptions";

//get event details
export const API_GET_EVENT_DETAILS = "/event/geteventdetails";

//geteventmemberdetailsbyquery
export const API_GET_EVENT_MEMBER_DETAILS_BY_QUERY =
  "/event/geteventmemberdetailsbyquery";

//update event
export const API_UPDATE_EVENT = "/event/updateevent";

//updatemassiveevent
export const API_UPDATE_MASSIVE_EVENT = "/event/updatemassiveevent";

//delete event
export const API_DELETE_EVENT = "/event/deleteevent";

//delete mass events
export const API_DELETE_EVENTS = "/event/deletemassevents";

//join event
export const API_JOIN_EVENT = "/event/joinevent";

//add/remove tag
export const API_ADD_REMOVE_TAG_EVENT = "/event/addremovetagevent";

//add member in event
export const API_ADD_NEW_EVENT_MEMBER = "/event/addnewmember";

//add sponsor
export const API_ADD_NEW_EVENT_COMPANY = "/event/addnewcompany";

//get all event member
export const API_GET_ALL_EVENT_MEMBER = "/event/getallmembers";

//getallsponsors
export const API_GET_ALL_EVENT_PARTNERS = "/event/getallsponsors";

//get member details
export const API_GET_EVENT_MEMBER_DETAILS = "/event/getmemberdetails";

//update event member
export const API_UPDATE_EVENT_MEMBER = "/event/updateeventmember";

//updateassiveeventmember
export const API_UPDATE_MASSIVE_EVENT_MEMBER = "/event/updateassiveeventmember";

//add/remove tag to event memeber
export const API_ADD_REMOVE_TAG_EVENT_MEMBER = "/event/addremovetageventmember";

//add gallery images
export const API_ADD_EVENT_GALLERY_IMAGES = "/event/addgalleryimage";

//delete gallery image
export const API_DELETE_EVENT_GALLERY_IMAGE = "/event/deletegalleryimage";

/*====== event url end ======*/

//course url start

//add new course
export const API_ADD_NEW_COURSE = "/course/createcourse";

//add new chapter
export const API_ADD_NEW_COURSE_CHAPTER = "/course/addnewcoursechapter";

//add new lesson
export const API_ADD_NEW_COURSE_LESSON = "/course/addnewcourselesson";

//add new member
export const API_ADD_NEW_COURSE_MEMBER = "/course/addnewmember";

//add new subscriber
export const API_ADD_NEW_COURSE_SUBSCRIBER = "/course/addnewsubscriber";

//add new course lesson task
export const API_ADD_NEW_COURSE_LESSON_TASK = "/course/addnewcourselessonTask";

//get all course
export const API_GET_ALL_COURSE_RECORDS = "/course/getallcourserecords";

//get all parent course
export const API_GET_ALL_PARENT_COURSES = "/course/getallparentcourse";

//getcourselistforoptions
export const API_GET_COURSE_LIST_FOR_OPTIONS =
  "/course/getcourselistforoptions";

//getmemberlistoptions
export const API_GET_COURSE_MEMBER_LIST_OPTIONS =
  "/course/getmemberlistoptions";

//get all chapters
export const API_GET_ALL_COURSE_CHAPTERS = "/course/getallcoursechapters";

//getchapterlistoptions
export const API_GET_COURSE_CHAPTER_LIST_OPTIONS =
  "/course/getchapterlistoptions";

//get all lessons
export const API_GET_ALL_COURSE_LESSONS = "/course/getallcourselessons";

//getlessonlistoptions
export const API_GET_COURSE_LESSON_LIST_OPTIONS =
  "/course/getlessonlistoptions";

//get all course members
export const API_GET_ALL_COURSE_MEMBERS = "/course/getallcoursemembers";

//get all course subscribers
export const API_GET_ALL_COURSE_SUBSCRIBERS = "/course/getallcoursesubscribers";

//getallsubmittedtasks
export const API_GET_ALL_COURSE_LESSON_TASKS = "/course/getallcourselesontasks";

//getallcoursetask
export const API_GET_ALL_COURSE_TASKS = "/course/getallcoursetask";

//getallcoursefaqlist
export const API_GET_ALL_COURSE_FAQ_LIST = "/course/getallcoursefaqlist";

//getcoursecertificatelist
export const API_GET_COURSE_CERTIFICATE_LIST =
  "/course/getcoursecertificatelist";

//get content details
export const API_GET_CONTENT_DETAILS = "/course/getcontentdetails";

//get course details
export const API_GET_COURSE_DETAILS = "/course/getcoursedetails";

//getcoursememberdetails
export const API_GET_COURSE_MEMBER_DETAILS = "/course/getcoursememberdetails";

//getcoursesubsriberdetails
export const API_GET_COURSE_SUBSCRIBER_DETAILS =
  "/course/getcoursesubsriberdetails";

//getcoursechapterdetails
export const API_GET_COURSE_CHAPTER_DETAILS = "/course/getcoursechapterdetails";

//getcourselessondetails
export const API_GET_COURSE_LESSON_DETAILS = "/course/getcourselessondetails";

//getcoursetaskdetails
export const API_GET_COURSE_TASK_DETAILS = "/course/getlessontaskdetails";

//getcertificatedetails
export const API_GET_CERTIFICATE_DETAILS = "/course/getcertificatedetails";

//update content
export const API_UPDATE_CONTENT = "/course/updatecontent";

//update course
export const API_UPDATE_COURSE = "/course/updatecourse";

//update massive course
export const API_UPDATE_MASSIVE_COURSE = "/course/updatemassivecourse";

//updatecoursemember
export const API_UPDATE_COURSE_MEMBER = "/course/updatecoursemember";

//updatecoursechapter
export const API_UPDATE_COURSE_CHAPTER = "/course/updatecoursechapter";

//updatecoursesubscriber
export const API_UPDATE_COURSE_SUBSCRIBER = "/course/updatecoursesubscriber";

//updatemassivecoursechapters
export const API_UPDATE_MASSIVE_COURSE_CHAPTERS =
  "/course/updatemassivecoursechapters";

//updatecourselesson
export const API_UPDATE_COURSE_LESSON = "/course/updatecourselesson";

//updatemassivecourselessons
export const API_UPDATE_MASSIVE_COURSE_LESSONS =
  "/course/updatemassivecourselessons";

//update lesson task
export const API_UPDATE_COURSE_LESSON_TASK = "/course/updatetask";

//updatelessonassignment
export const API_UPDATE_LESSON_ASSIGNMENT = "/course/updatelessonassignment";

//updatemassivetask
export const API_UPDATE_MASSIVE_COURSE_LESSON_TASK =
  "/course/updatemassivetask";

//delete course
export const API_DELETE_COURSE = "/course/deletecourse";

//delete massive course
export const API_DELETE_MASSIVE_COURSE = "/course/deletemassivecourse";

//deletecoursechapter
export const API_DELETE_COURSE_CHAPTER = "/course/deletecoursechapter";

//delete massive course chapter
export const API_DELETE_MASSIVE_COURSE_CHAPTERS =
  "/course/deletemassivecoursechapters";

//deletecourselesson
export const API_DELETE_COURSE_LESSON = "/course/deletecourselesson";

//delete massive course lesson
export const API_DELETE_MASSIVE_COURSE_LESSONS =
  "/course/deletemassivecourselessons";

//delete content
export const API_DELETE_CONTENT = "/course/deletecontent";

//deletemassivetasks
export const API_DELETE_MASSIVE_COURSE_LESSON_TASKS =
  "/course/deletemassivetasks";

//addremovetagcourse
export const API_ADD_REMOVE_TAG_COURSE = "/course/addremovetagcourse";

//end course

/*====== blog url start ======*/
//create new blog
export const API_CREATE_NEW_BLOG = "/blog/createblog";

//get all blog
export const API_GET_ALL_BLOG = "/blog/getallblogs";

//get blog details
export const API_GET_BLOG_DETAILS = "/blog/getblogdetails";

//update blog
export const API_UPDATE_BLOG = "/blog/updateblog";

//updatebulkblog
export const API_UPDATE_BULK_BLOG = "/blog/updatebulkblog";

//delete blog
export const API_DELETE_BLOG = "/blog/deleteblog";

//add or remove label/tag to blog
export const API_ADD_REMOVE_TAG_BLOG = "/blog/addremovetagblog";

/*====== blog url end ======*/

//start faq

//createfaq
export const API_CREATE_FAQ = "/faq/createfaq";

//generatedebate
export const API_GENERATE_DEBATE = "/faq/generatedebate";

//getallfaqs
export const API_GET_ALL_FAQS = "/faq/getallfaqs";

//getfaqdetails
export const API_GET_FAQ_DETAILS = "/faq/detailsfaq";

//updatefaq
export const API_UPDATE_FAQ = "/faq/updatefaq";

//updatemassivefaq
export const API_UPDATE_MASSIVE_FAQ = "/faq/updatemassivefaq";

//deletefaq
export const API_DELETE_FAQ = "/faq/deletefaq";

//deletemassivefaq
export const API_DELETE_MASSIVE_FAQ = "/faq/deletemassivefaq";

//addremovetagfaq
export const API_ADD_REMOVE_TAG_FAQ = "/faq/addremovetagfaq";

//end faq

/* challenge url start */
//create challenge
export const API_CREATE_CHALLENGE = "/challenge/createchallenge";

//addnewmember
export const API_ADD_NEW_CHALLENGE_MEMBER = "/challenge/addnewmember";

//addnewchallengeprocess
export const API_ADD_NEW_CHALLENGE_PROCESS = "/challenge/addnewprocess";

//get all challenge
export const API_GET_ALL_CHALLENGE = "/challenge/getallchallenges";

//getchallengelistforoptions
export const API_GET_CHALLENGE_LIST_FOR_OPTIONS =
  "/challenge/getchallengelistforoptions";

//getallmembers
export const API_GET_ALL_CHALLENGE_MEMBERS = "/challenge/getallmembers";

//getallchallengeprocess
export const API_GET_ALL_CHALLENGE_PROCESS = "/challenge/getallprocess";

//getallresponses
export const API_GET_ALL_CHALLENGE_RESPONSE = "/challenge/getallresponses";

//get challenge details
export const API_GET_CHALLENGE_DETAILS = "/challenge/getchallengedetails";

//getmemberdetails
export const API_GET_CHALLENGE_MEMBER_DETAILS = "/challenge/getmemberdetails";

//getchallengeprocessdetails
export const API_GET_CHALLENGE_PROCESS_DETAILS = "/challenge/getprocessdetails";

//getchallengeresponsedetails
export const API_GET_CHALLENGE_RESPONSE_DETAILS =
  "/challenge/getchallengeresponsedetails";

//update challenge
export const API_UPDATE_CHALLENGE = "/challenge/updatechallenge";

//updatebulkchallenge
export const API_UPDATE_BULK_CHALLENGE = "/challenge/updatebulkchallenge";

//addremovetagchallenge
export const API_ADD_REMOVE_TAG_CHALLENGE = "/challenge/addremovetagchallenge";

//updatechallengemember
export const API_UPDATE_CHALLENGE_MEMBER = "/challenge/updatechallengemember";

//updatebulkchallengemember
export const API_UPDATE_BULK_CHALLENGE_MEMBER =
  "/challenge/updatebulkchallengemember";

//addremovetagchallengemember
export const API_ADD_REMOVE_TAG_CHALLENGE_MEMBER =
  "/challenge/addremovetagchallengemember";

//updatechallengeprocess
export const API_UPDATE_CHALLENGE_PROCESS = "/challenge/updateprocess";

//changepromotestatus
export const API_CHANGE_RESPONSE_STATUS = "/challenge/changeresponsestatus";

//challengewinnerselection
export const API_CHALLENGE_WINNER_SELECTION =
  "/challenge/challengewinnerselection";

/* challenge url end */

/** chat url start */
//askgpt
export const API_GET_RESPONSE_FROM_AI = "/chat/askgpt";
/** chat url end */
