/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";

import publishOptions from "data/Prod/PublishOptions.json";

const BlogDetailsBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation();

  const { id: blogId } = useParams();
  const history = useHistory();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("CMS"), link: "/admin/blog/post/list" },
    { title: "" },
  ]);

  const [parentBaseCategories, setParentBaseCategories] = useState([]);
  const [levelTwoLoading, setlevelTwoLoading] = useState(false);
  const [levelTwoCategories, setLevelTwoCategories] = useState([]);
  const [levelThreeLoading, setLevelThreeLoading] = useState(false);
  const [levelThreeCategories, setLevelThreeCategories] = useState([]);
  const [levelFourLoading, setLevelFourLoading] = useState(false);
  const [levelFourCategories, setLevelFourCategories] = useState([]);

  const [selectedBaseCategoryId, setselectedBaseCategoryId] = useState(null);
  const [selectedLevelTwoCategoryId, setselectedLevelTwoCategoryId] =
    useState(null);
  const [selectedLevelThreeCategoryId, setselectedLevelThreeCategoryId] =
    useState(null);
  const [selectedLevelFourCategoryId, setselectedLevelFourCategoryId] =
    useState(null);

  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [title, setTitle] = useState("");
  const [slug, setslug] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [categoryValue, setCategoryValue] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metakeywords, setMetaKeywords] = useState("");
  const [metadescription, setMetaDescription] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [selectedTags, setselectedTags] = useState([]);
  const [uploadedBannerImage, setuploadedBannerImage] = useState("");
  const [uplaodedBannerId, setuplaodedBannerId] = useState(null);
  const [isPrivate, setisPrivate] = useState(false);
  const [publishStatusValue, setpublishStatusValue] = useState(null);
  const [publishStatus, setpublishStatus] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hooks for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    slugWarning: false,
    // imageWarning: false,
    summaryWarning: false,
    detailsWarning: false,
    categoryWarning: false,
  });

  const editor = useRef(null);

  const detailsEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    height: "315",
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const detailsEditorConfig = useMemo(() => detailsEditorCustomConfig, []);

  // validate function
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (slug === "") {
      setValidation((prevState) => ({ ...prevState, slugWarning: true }));
      isValid = false;
    }

    // if (!uplaodedBannerId) {
    //   setValidation((prevState) => ({ ...prevState, imageWarning: true }));
    //   isValid = false;
    // }

    if (summary === "") {
      setValidation((prevState) => ({ ...prevState, summaryWarning: true }));
      isValid = false;
    }

    if (details === "") {
      setValidation((prevState) => ({ ...prevState, detailsWarning: true }));
      isValid = false;
    }

    // if (
    //   !selectedBaseCategoryId &&
    //   !selectedLevelTwoCategoryId & !selectedLevelThreeCategoryId &&
    //   !selectedLevelFourCategoryId
    // ) {
    //   setValidation((prevState) => ({ ...prevState, categoryWarning: true }));
    //   isValid = false;
    // }

    if (!categoryValue) {
      setValidation((prevState) => ({ ...prevState, categoryWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get all category
  const getAllCategoryList = async (
    parentSlug = "CMS_BLOG",
    parentColumnSlug = ""
  ) => {
    try {
      setValidation((prevState) => ({
        ...prevState,
        categoryWarning: false,
      }));

      // if (parentSlug !== "CMS_BLOG" && parentColumnSlug === "BASE") {
      //   setlevelTwoLoading(true);
      // } else if (parentSlug !== "CMS_BLOG" && parentColumnSlug === "LEVEL2") {
      //   setLevelThreeLoading(true);
      // } else if (parentSlug !== "CMS_BLOG" && parentColumnSlug === "LEVEL3") {
      //   setLevelFourLoading(true);
      // }

      const response = await getAllCategories(parentSlug);

      // setlevelTwoLoading(false);
      // setLevelThreeLoading(false);
      // setLevelFourLoading(false);

      if (response) {
        setCategoryList(response);

        // if (parentSlug === "CMS_BLOG" && parentColumnSlug === "") {
        //   setParentBaseCategories(response);
        // } else if (parentSlug !== "CMS_BLOG" && parentColumnSlug === "BASE") {
        //   setLevelTwoCategories(response);
        // } else if (parentSlug !== "CMS_BLOG" && parentColumnSlug === "LEVEL2") {
        //   setLevelThreeCategories(response);
        // } else if (parentSlug !== "CMS_BLOG" && parentColumnSlug === "LEVEL3") {
        //   setLevelFourCategories(response);
        // }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //upload banner
  const bannerUploadHandler = async (e) => {
    const file = e.target.files[0];

    setValidation((prevState) => ({ ...prevState, imageWarning: false }));

    try {
      const requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestURL, file);

      console.log("upload response-------->", response);

      if (response.status) {
        resetBannerImageFile();
        setuplaodedBannerId(response.data._id);
        setuploadedBannerImage(response.data.path);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for clear file value
  const resetBannerImageFile = () => {
    const file = document.getElementById("postphotoupload");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function tag selection
  const tagSelectionHandler = (val) => {
    settagValue(val);

    setselectedTags(val.map((item) => item.value));
  };

  //function for select category
  const categorySelectionHandler = (selectedValues) => {
    setCategoryValue(selectedValues);
    const selectedCategories = selectedValues.map((item) => item.value);
    setSelectedCategories(selectedCategories);
  };

  //function for select pubsih status
  const publishStatusSelectionHandler = (val) => {
    if (val) {
      setpublishStatusValue(val);
      setpublishStatus(val.value);
    } else {
      setpublishStatusValue(null);
      setpublishStatus("");
    }
  };

  //function for get blog details
  const getBlogDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_BLOG_DETAILS +
        `/${blogId}` +
        `?userid=${userInfo._id}`;

      const response = await getData(requestURL);

      console.log("response if blog details---->", response);

      if (response.status) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("CMS"), link: "/admin/blog/post/list" },
          { title: data.name },
        ]);

        setTitle(data.name);
        setslug(data.slug);
        setsummary(data.summary);
        setdetails(data.description);
        setMetaTitle(data.metadatatitle);
        setMetaKeywords(data.metadatakeywords);
        setMetaDescription(data.metadatadescription);

        if (data.tagvalues) {
          tagSelectionHandler(data.tagvalues);
        }

        setuplaodedBannerId(data.coverimageid);
        setuploadedBannerImage(data.coverimageurl);
        setisPrivate(data.isprivate);

        for (let publish of publishOptions) {
          if (publish.value === data.publish) {
            publishStatusSelectionHandler(publish);
          }
        }

        //assign category
        categorySelectionHandler(response.data?.categorylist);

        // if (data.categoryidlist && data.categoryidlist.length > 0) {
        //   setSelectedCategoryIds(data.categoryidlist);

        //   const baseResponse = await getAllCategories("CMS_BLOG");

        //   if (baseResponse.length > 0) {
        //     // Find matching categories
        //     const matchingBaseCategories = baseResponse.filter((category) =>
        //       data.categoryidlist.includes(category._id)
        //     );

        //     if (matchingBaseCategories.length > 0) {
        //       setselectedBaseCategoryId(matchingBaseCategories[0]._id);

        //       setlevelTwoLoading(true);

        //       const levelTwoResponse = await getAllCategories(
        //         matchingBaseCategories[0].slug
        //       );

        //       setlevelTwoLoading(false);

        //       setLevelTwoCategories(levelTwoResponse);

        //       if (levelTwoResponse.length > 0) {
        //         const matchingLevelTwoCategories = levelTwoResponse.filter(
        //           (category) => data.categoryidlist.includes(category._id)
        //         );

        //         if (matchingLevelTwoCategories.length > 0) {
        //           setselectedLevelTwoCategoryId(
        //             matchingLevelTwoCategories[0]._id
        //           );

        //           setLevelThreeLoading(true);

        //           const levelThreeResponse = await getAllCategories(
        //             matchingLevelTwoCategories[0].slug
        //           );

        //           setLevelThreeLoading(false);

        //           setLevelThreeCategories(levelThreeResponse);

        //           if (levelThreeResponse.length > 0) {
        //             const matchingLevelThreeCategories =
        //               levelThreeResponse.filter((category) =>
        //                 data.categoryidlist.includes(category._id)
        //               );

        //             if (matchingLevelThreeCategories.length > 0) {
        //               setselectedLevelThreeCategoryId(
        //                 matchingLevelThreeCategories[0]._id
        //               );

        //               setLevelFourLoading(true);

        //               const levelFourResponse = await getAllCategories(
        //                 matchingLevelThreeCategories[0].slug
        //               );

        //               setLevelFourLoading(false);

        //               setLevelFourCategories(levelFourResponse);

        //               if (levelFourResponse.length > 0) {
        //                 const matchingLevelFourCategories =
        //                   levelFourResponse.filter((category) =>
        //                     data.categoryidlist.includes(category._id)
        //                   );

        //                 if (matchingLevelFourCategories.length > 0) {
        //                   setselectedLevelFourCategoryId(
        //                     matchingLevelFourCategories[0]._id
        //                   );
        //                 }
        //               }
        //             }
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create/update blog
  const saveBlogHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        let blogData = {
          author: userInfo._id,
          name: title,
          slug,
          coverimage: uplaodedBannerId,
          summary: summary,
          description: details,
          categoryids: selectedCategories,
          uploads: [],
          tags: selectedTags,
          publish: publishStatus,
          metadatatitle: metaTitle,
          metadatakeywords: metakeywords,
          metadatadescription: metadescription,
          isprivate: isPrivate,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (blogId) {
          requestURL =
            requestURL + url.API_UPDATE_BLOG + `/${blogId}` + `?token=${token}`;

          response = await putData(requestURL, blogData);
        } else {
          requestURL = requestURL + url.API_CREATE_NEW_BLOG + `?token=${token}`;

          response = await postData(requestURL, blogData);
        }

        console.log("response in save blog---->", response);

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          setAlertMessage(response.message);

          // Redirect after a short delay
          setTimeout(() => {
            history.push("/admin/blog/post/list");
          }, 1500);
        } else {
          setMessageType("error");
          setAlertMessage(response.message);
        }
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up the required fields"));
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (blogId) {
      getBlogDetails();
    }
  }, [blogId]);

  useEffect(() => {
    getAllCategoryList("CMS_BLOG", "");
    getAllTags();
  }, []);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="add_blog_form_wrapper" className="p-4 bg-gray-100">
            {/* <!-- ========== Start title Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="title"
                className="d-block fw-bold text-primary mb-3"
              >
                Title
              </label>
              <input
                type="text"
                className="form-control rounded-0 shadow-none"
                placeholder=""
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                }}
              />
              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>{t("Please enter title")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* <!-- ========== End title Section ========== --> */}

            {/* <!-- ========== Start slug Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="seoUrl"
                className="d-block fw-bold text-primary mb-3"
              >
                SEO URL
              </label>
              <input
                type="text"
                className="form-control rounded-0 shadow-none"
                placeholder=""
                value={slug}
                onChange={(e) => {
                  setslug(e.target.value);
                }}
              />
              {validation.slugWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>{t("Please enter slug")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* <!-- ========== End slug Section ========== --> */}

            {/* <!-- ========== Start summary Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="content"
                className="d-block fw-bold text-primary mb-3"
              >
                Summary
              </label>
              <textarea
                className="form-control rounded-0 shadow-none"
                placeholder=""
                rows="6"
                value={summary}
                onChange={(e) => {
                  setsummary(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    summaryWarning: false,
                  }));
                }}
              ></textarea>
              {validation.summaryWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>{t("Please enter summary")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* <!-- ========== End summary Section ========== --> */}

            {/* <!-- ========== Start details Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="content"
                className="d-block fw-bold text-primary mb-3"
              >
                Content{" "}
              </label>
              <JoditEditor
                ref={editor}
                value={details}
                config={detailsEditorConfig}
                onBlur={(newContent) => {
                  // console.log("New content in on blur----->", newContent);
                  setdetails(newContent);
                  setValidation((prevState) => ({
                    ...prevState,
                    detailsWarning: false,
                  }));
                }}
                onChange={(newContent, event) => {
                  // setMailDescription(newContent);
                }}
              />
              {validation.detailsWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>{t("Please enter details")}!</span>
                  </p>
                </div>
              )}
            </div>
            {/* <!-- ========== End details Section ========== --> */}

            {/* <!-- ========== Start category Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="category"
                className="d-block fw-bold text-primary mb-1"
              >
                Category
              </label>
              <div className="select_parent_area">
                {/* <div className="form-group row">
                  <div className="col-sm-2 tree-view">
                    <h4 className="sub-title">Base Category</h4>
                    <div className="jstree jstree-7 jstree-default">
                      <ul className="jstree-container-ul jstree-children">
                        {parentBaseCategories.map((category, index) => {
                          return (
                            <li className="jstree-node jstree-leaf" key={index}>
                              <Link
                                className={`jstree-anchor text-gray-600 ${
                                  selectedBaseCategoryId === category._id
                                    ? "jstree-clicked"
                                    : ""
                                }`}
                                to="#"
                                onClick={() => {
                                  setLevelFourCategories([]);
                                  setLevelThreeCategories([]);
                                  setLevelTwoCategories([]);
                                  setselectedLevelTwoCategoryId(null);
                                  setselectedLevelThreeCategoryId(null);
                                  setselectedLevelFourCategoryId(null);
                                  getAllCategoryList(category.slug, "BASE");
                                  setSelectedCategoryIds([category._id]);
                                  setselectedBaseCategoryId(category._id);
                                }}
                              >
                                {category.label}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-2 tree-view">
                    <h4 className="sub-title">Level 2</h4>
                    <div className="jstree jstree-7 jstree-default">
                      <ul className="jstree-container-ul jstree-children">
                        {levelTwoLoading ? (
                          <li className="jstree-node jstree-leaf">
                            <Link
                              className={`jstree-anchor text-gray-600 `}
                              to="#"
                            >
                              Loading...
                            </Link>
                          </li>
                        ) : (
                          levelTwoCategories.map((category, index) => {
                            return (
                              <li
                                className="jstree-node jstree-leaf"
                                key={index}
                              >
                                <Link
                                  className={`jstree-anchor text-gray-600 ${
                                    selectedLevelTwoCategoryId === category._id
                                      ? "jstree-clicked"
                                      : ""
                                  }`}
                                  to="#"
                                  onClick={() => {
                                    setLevelFourCategories([]);
                                    setLevelThreeCategories([]);
                                    setselectedLevelThreeCategoryId(null);
                                    setselectedLevelFourCategoryId(null);
                                    getAllCategoryList(category.slug, "LEVEL2");
                                    setSelectedCategoryIds((prevIds) => [
                                      ...prevIds,
                                      category._id,
                                    ]);
                                    setselectedLevelTwoCategoryId(category._id);
                                  }}
                                >
                                  {category.label}
                                </Link>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-2 tree-view">
                    <h4 className="sub-title">Level 3</h4>
                    <div className="jstree jstree-7 jstree-default">
                      <ul className="jstree-container-ul jstree-children">
                        {levelThreeLoading ? (
                          <li className="jstree-node jstree-leaf">
                            <Link
                              className={`jstree-anchor text-gray-600 `}
                              to="#"
                            >
                              Loading...
                            </Link>
                          </li>
                        ) : (
                          levelThreeCategories.map((category, index) => {
                            return (
                              <li
                                className="jstree-node jstree-leaf"
                                key={index}
                              >
                                <Link
                                  className={`jstree-anchor text-gray-600 ${
                                    selectedLevelThreeCategoryId ===
                                    category._id
                                      ? "jstree-clicked"
                                      : ""
                                  }`}
                                  to="#"
                                  onClick={() => {
                                    setLevelFourCategories([]);
                                    setselectedLevelFourCategoryId(null);
                                    getAllCategoryList(category.slug, "LEVEL3");
                                    setselectedLevelThreeCategoryId(
                                      category._id
                                    );
                                    setSelectedCategoryIds((prevIds) => [
                                      ...prevIds,
                                      category._id,
                                    ]);
                                  }}
                                >
                                  {category.label}
                                </Link>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-2 tree-view">
                    <h4 className="sub-title">Level 4</h4>
                    <div className="jstree jstree-7 jstree-default">
                      <ul className="jstree-container-ul jstree-children">
                        {levelFourLoading ? (
                          <li className="jstree-node jstree-leaf">
                            <Link
                              className={`jstree-anchor text-gray-600 `}
                              to="#"
                            >
                              Loading...
                            </Link>
                          </li>
                        ) : (
                          levelFourCategories.map((category, index) => {
                            return (
                              <li
                                className="jstree-node jstree-leaf"
                                key={index}
                              >
                                <Link
                                  className={`jstree-anchor text-gray-600 ${
                                    selectedLevelFourCategoryId === category._id
                                      ? "jstree-clicked"
                                      : ""
                                  }`}
                                  to="#"
                                  onClick={() => {
                                    setselectedLevelFourCategoryId(
                                      category._id
                                    );
                                    setSelectedCategoryIds((prevIds) => [
                                      ...prevIds,
                                      category._id,
                                    ]);
                                  }}
                                >
                                  {category.label}
                                </Link>
                              </li>
                            );
                          })
                        )}
                      </ul>
                    </div>
                  </div>
                </div> */}
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => {
                    categorySelectionHandler(val);
                    setValidation((prevState) => ({
                      ...prevState,
                      categoryWarning: false,
                    }));
                  }}
                />
                {validation.categoryWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please select category")}!</span>
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* <!-- ========== End category Section ========== --> */}

            {/* <!-- ========== Start meta title Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="seoInfo"
                className="d-block fw-bold text-primary mb-3"
              >
                Meta Data - Title
              </label>
              <textarea
                id="Post_metatitle"
                name="Post[metatitle]"
                className="form-control rounded-0 shadow-none"
                placeholder=""
                value={metaTitle}
                onChange={(e) => {
                  setMetaTitle(e.target.value);
                }}
              ></textarea>
            </div>
            {/* <!-- ========== End meta title Section ========== --> */}

            {/* <!-- ========== Start meta keywords Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="seoInfo"
                className="d-block fw-bold text-primary mb-3"
              >
                Meta Data - Keywords
              </label>

              <textarea
                id="Post_metakeywords"
                name="Post[metakeywords]"
                className="form-control rounded-0 shadow-none"
                placeholder=""
                value={metakeywords}
                onChange={(e) => {
                  setMetaKeywords(e.target.value);
                }}
              ></textarea>
            </div>
            {/* <!-- ========== End meta keywords Section ========== --> */}

            {/* <!-- ========== Start meta descriptionSection ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="seoInfo"
                className="d-block fw-bold text-primary mb-3"
              >
                Meta Data - Description
              </label>
              <textarea
                id="Post_metadescription"
                name="Post[metadescription]"
                className="form-control rounded-0 shadow-none"
                placeholder=""
                value={metadescription}
                onChange={(e) => {
                  setMetaDescription(e.target.value);
                }}
              ></textarea>
            </div>
            {/* <!-- ========== End meta descriptionSection ========== --> */}

            {/* <!-- ========== Start tag Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="seoInfo"
                className="d-block fw-bold text-primary mb-3"
              >
                Labels
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isMulti
                placeholder="Select Labels"
                options={tagList}
                value={tagValue}
                onChange={(val) => {
                  tagSelectionHandler(val);
                }}
              />
            </div>
            {/* <!-- ========== End tag Section ========== --> */}

            {/* <!-- ========== Start blog banner Section ========== --> */}
            <div className="form-row mb-4">
              <label
                htmlFor="seoInfo"
                className="d-block fw-bold text-primary mb-3"
              >
                Featured photo
              </label>

              <div className="fields-container d-flex flex-fill">
                <input
                  type="file"
                  id="postphotoupload"
                  placeholder="JPG, PNG, GIF"
                  accept="image/*"
                  className="form-control rounded-0 shadow-none"
                  onChange={bannerUploadHandler}
                />
              </div>

              {/* {validation.imageWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>{t("Please upload banner image")}!</span>
                  </p>
                </div>
              )} */}

              <div className="mt-4 img-container position-relative">
                <img
                  src={url.SERVER_URL + uploadedBannerImage}
                  alt=""
                  id="photopreview"
                  className="img-fluid"
                />

                {uploadedBannerImage === "" ? null : (
                  <div className="preview-action d-flex align-items-center gap-2 p-2 position-absolute start-0 end-0 top-0">
                    <Link
                      to="#"
                      className="text-white"
                      onClick={() => {
                        setuplaodedBannerId(null);
                        setuploadedBannerImage("");
                      }}
                    >
                      <i className="d-block material-icons icon-sm">cancel</i>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {/* <!-- ========== End blog banner Section ========== --> */}

            {/* <!-- ========== Start is private Section ========== --> */}
            <div className="form-row mb-4">
              <div className="form-check d-inline-flex align-items-center me-5">
                <input
                  type="checkbox"
                  className="form-check-input me-2"
                  checked={isPrivate}
                  onChange={(e) => setisPrivate(e.target.checked)}
                />
                <label className="form-check-label text-primary fw-bold">
                  Mark as private post
                </label>
              </div>
            </div>
            {/* <!-- ========== End is private Section ========== --> */}

            <div className="form-row mb-4">
              <label
                htmlFor="seoInfo"
                className="d-block fw-bold text-primary mb-3"
              >
                Status
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isClearable
                placeholder="Select Status"
                options={publishOptions}
                value={publishStatusValue}
                onChange={(val) => publishStatusSelectionHandler(val)}
              />
            </div>

            <div className="action-container d-flex align-items-center justify-content-end">
              <Link
                to="/admin/blog/post/list"
                className="btn btn-gray rounded-0 me-3"
              >
                Cancel
              </Link>
              <button
                type="button"
                className="btn btn-yellow rounded-0"
                onClick={saveBlogHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                Save
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default BlogDetailsBody;
