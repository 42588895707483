import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AppUserDetailsCommonHeader = ({ moduleName = "" }) => {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <section className="course_tablinks_outer learning_coursetablinks">
      <div className="container-fluid">
        <div className="tab_links">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <Link
                to={`/admin/userdetails/manage/personalinfo/${params.id}`}
                className={`nav-link ${
                  moduleName === "personalInfo" ? "active" : ""
                }`}
              >
                {t("Personal Info")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to={`/admin/userdetails/manage/experience/${params.id}`}
                className={`nav-link ${
                  moduleName === "experience" ? "active" : ""
                }`}
              >
                {t("Experince")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "interest" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/interest/${params.id}`}
              >
                {t("Interest")}
              </Link>
            </li> */}
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "improvements" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/improvements/${params.id}`}
              >
                {t("Improvements")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "surveys" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/surveys/${params.id}`}
              >
                {t("Survey Invites")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "courses" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/courses/${params.id}`}
              >
                {t("Courses")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "certifications" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/certification/${params.id}`}
              >
                {t("Certification")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "password" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/password/${params.id}`}
              >
                {t("Password")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "account" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/account/${params.id}`}
              >
                {t("Account")}
              </Link>
            </li> */}
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "privacy" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/privacy/${params.id}`}
              >
                {t("Privacy")}
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link
                className={`nav-link ${
                  moduleName === "ecoins" ? "active" : ""
                }`}
                to={`/admin/userdetails/manage/ecoins/${params.id}`}
              >
                {t("Dracoins")}
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default AppUserDetailsCommonHeader;
