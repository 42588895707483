/* eslint-disable */
import { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";

const BlogListFilterModal = ({
  setFilterStartDate,
  setFilterEndDate,
  setFilterBlogTitle,
  setFilterSlug,
  setFilterBlogCategory,
  setFilterBlogLabels,
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [categoryList, setcategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [blogFromDate, setblogFromDate] = useState("");
  const [blogToDate, setblogToDate] = useState("");
  const [blogTitle, setblogTitle] = useState("");
  const [blogSlug, setBlogSlug] = useState("");
  const [categoryValue, setcategoryValue] = useState(null);
  const [selectedBlogCategory, setselectedBlogCategory] = useState([]);
  const [blogLabelvalue, setblogLabelvalue] = useState(null);
  const [selectedBlogLabels, setselectedBlogLabels] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    endDateSmallerWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true; // Initialize the validity flag

    // Check if the start date is provided but the end date is missing
    if (blogFromDate === "" && blogToDate !== "") {
      setValidation((prevState) => ({ ...prevState, startDateWarning: true }));
      // Indicate that the validation has failed
      isValid = false;
    }
    if (blogFromDate !== "" && blogToDate === "") {
      setValidation((prevState) => ({ ...prevState, endDateWarning: true }));

      isValid = false;
    }

    if (blogToDate !== "" && blogToDate < blogFromDate) {
      setValidation((prevState) => ({
        ...prevState,
        endDateSmallerWarning: true,
      }));
      isValid = false;
    }
    // Return the validity flag
    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all category
  const getAllCategoryList = async () => {
    try {
      const parentSlug = "CMS_BLOG";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setcategoryList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select event tags
  const tagSelectionHandler = (data) => {
    setblogLabelvalue(data);
    setselectedBlogLabels(data.map((item) => item.value));
  };

  //select categroy
  const categorySelectionHandler = (val) => {
    setcategoryValue(val);
    setselectedBlogCategory(val.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    if (validationHandler()) {
      setFilterStartDate(blogFromDate);
      setFilterEndDate(blogToDate);
      setFilterBlogTitle(blogTitle);
      setFilterSlug(blogSlug);
      setFilterBlogCategory(selectedBlogCategory);
      setFilterBlogLabels(selectedBlogLabels);
      resetHandler();

      //hide modal
      const filterModal = document.querySelector("#blogfilterModal");

      const modal = bootstrap.Modal.getInstance(filterModal);
      modal.hide();
    }
  };

  const resetHandler = () => {
    setblogFromDate("");
    setblogToDate("");
    setblogTitle("");
    setBlogSlug("");
    setcategoryValue(null);
    setselectedBlogCategory([]);
    setblogLabelvalue(null);
    setselectedBlogLabels([]);

    setValidation({
      startDateWarning: false,
      endDateWarning: false,
      endDateSmallerWarning: false,
    });
  };

  useEffect(() => {
    getAllTags();
    getAllCategoryList();
  }, []);

  return (
    <div
      className="modal fade"
      id="blogfilterModal"
      aria-labelledby="blogfilterModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyword="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0 mb-2">
            <h2 className="text-green w-100" id="blogfilterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* title  */}
              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  Blog Title
                </label>
                <input
                  type="text"
                  id="fname"
                  name="query"
                  placeholder="Name"
                  className="form-control text-green rounded-0"
                  value={blogTitle}
                  onChange={(e) => setblogTitle(e.target.value)}
                />
              </div>

              {/* seo url  */}
              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  Seo Url
                </label>
                <input
                  type="text"
                  id="fname"
                  name="query"
                  placeholder="Name"
                  className="form-control text-green rounded-0"
                  value={blogSlug}
                  onChange={(e) => setBlogSlug(e.target.value)}
                />
              </div>

              {/* category  */}
              <div className="form-row mb-3">
                <label htmlFor="interest" className="fw-bold mb-2">
                  Category
                </label>
                <Select
                  styles={reactSelectStyle("5px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={t("Select Interest")}
                  className="w-100"
                  isMulti
                  options={categoryList}
                  value={categoryValue}
                  onChange={(val) => categorySelectionHandler(val)}
                />
              </div>

              {/* tags  */}
              <div className="form-row mb-3">
                <label htmlFor="tags" className="fw-bold mb-2">
                  Labels
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  placeholder={t("Select tags")}
                  options={tagList}
                  value={blogLabelvalue}
                  onChange={(val) => {
                    tagSelectionHandler(val);
                  }}
                />
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  <label htmlFor="startDate" className="fw-bold mb-2">
                    From Date
                  </label>
                  <input
                    type="date"
                    name="fromdate"
                    id="startDate"
                    className="form-control text-green rounded-0"
                    value={blogFromDate}
                    onChange={(e) => {
                      setblogFromDate(e.target.value);
                    }}
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="endDate" className="fw-bold mb-2">
                    To Date
                  </label>
                  <input
                    type="date"
                    name="todate"
                    id="endDate"
                    className="form-control text-green rounded-0"
                    value={blogToDate}
                    onChange={(e) => setblogToDate(e.target.value)}
                  />
                </div>
              </div>

              {validation.endDateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>Please enter end date!</span>
                  </p>
                </div>
              )}
              {validation.startDateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>Please enter start date!</span>
                  </p>
                </div>
              )}
              {validation.endDateSmallerWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>End date should not be greater than start date!</span>
                  </p>
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={searchHandler}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogListFilterModal;
