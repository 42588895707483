/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";
import CourseListHeader from "./Header/CourseListHeader";

const AppuserCourseBody = () => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { id: appUserId } = useParams();

  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);

  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterTitle, setFilterTitle] = useState("");
  const [filterLanguage, setFilterLanguage] = useState("");
  const [filterRank, setfilterRank] = useState("");
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterCategories, setFilterCategories] = useState([]);

  const [isRefresh, setIsRefresh] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App user"), link: "/admin/contact/allusers" },
    { title: t("Courses") },
  ];

  //get course list
  const getAllCourses = async () => {
    setRowSelection({});
    setSelectedCourseIds([]);

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_COURSE_RECORDS +
        `?token=${token}&modulefrom=backend&modulename=appuser&filterusers=${appUserId}`;

      if (filterRank != "") {
        requestURL += "&filterrank=" + filterRank;
      }

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestURL += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterTitle != "") {
        requestURL += `&filtertitle=${filterTitle}`;
      }

      if (filterLanguage != "") {
        requestURL += `&filterlanguage=${filterLanguage}`;
      }

      if (filterCategories.length > 0) {
        requestURL += `&filtercategories=${filterCategories.join(",")}`;
      }

      if (filterSkills.length > 0) {
        requestURL += `&filterskills=${filterSkills.join(",")}`;
      }

      console.log("Course url", requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setCourseList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedList = [...courseList];

    const rowIndex = updatedList.findIndex(
      (course) => course._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedList[rowIndex].updatedrank = value;
      setCourseList(updatedList);
    }
  };

  //function for update rank
  const updateBulkHandler = async (e) => {
    if (selectedCourseIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdate = courseList.filter((item) =>
          selectedCourseIds.includes(item._id)
        );

        let apiData = {
          courselist: listToBeUpdate,
        };

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_MASSIVE_COURSE + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllCourses();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
    }
    setShowAlert(true);
  };

  //function for delete course
  const deleteMassiveHandler = async () => {
    if (selectedCourseIds.length > 0) {
      const listToBeDelete = courseList.filter((item) =>
        selectedCourseIds.includes(item._id)
      );

      try {
        let apiData = {
          courselist: listToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_MASSIVE_COURSE + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllCourses();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //reset filter
  const resetFilter = () => {
    setfilterRank("");
    setFilterTitle("");
    setFilterStartDate("");
    setFilterEndDate("");
    setFilterLanguage("");
    setFilterCategories([]);
    setFilterSkills([]);

    setIsRefresh(true);
  };

  useEffect(() => {
    if (
      filterRank !== "" ||
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterTitle !== "" ||
      filterLanguage !== "" ||
      filterCategories.length > 0 ||
      filterSkills.length > 0
    ) {
      getAllCourses();
    }
  }, [
    filterRank,
    filterStartDate,
    filterEndDate,
    filterTitle,
    filterLanguage,
    filterCategories,
    filterSkills,
  ]);

  useEffect(() => {
    if (isRefresh) {
      getAllCourses();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    getAllCourses();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "title",
      header: t("Name"),
      size: 300,
      Cell: ({ row }) => (
        <div>
          <p className="text-primary mb-3">{row.original.title}</p>
          <div className="d-flex align-items-center">
            <div className="img-container me-2">
              <Link to="#" className="">
                <img
                  height={25}
                  width={25}
                  src={
                    row.original.moderatorimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.moderatorimage
                  }
                  className="img-fluid rounded-circle object-cover object-center"
                />
              </Link>
            </div>
            <p className="name">{row.original.moderatorname}</p>
          </div>

          <div className="d-flex align-items-center mt-1">
            {row.original.rank === 0 ? (
              <p className="d-flex align-items-center fw-medium  reviewing">
                <i className="material-icons me-2">access_time</i>
                <span>Pending Publish</span>
              </p>
            ) : (
              <p className="d-flex align-items-center fw-medium  text-success">
                <i className="material-icons me-2">check_circle_outline</i>
                <span>Published</span>
              </p>
            )}
          </div>
        </div>
      ),
    },
    {
      accessorKey: "courselanguage",
      header: t("Language"),
      size: 150,
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      size: 100,
      Cell: ({ row }) => (
        <input
          type="number"
          className="form-control rounded-0 shadow-none bg-white"
          value={row.original.updatedrank ?? row.original.rank}
          onChange={(e) => rankInputChangeHandler(row, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "lessoncount",
      header: t("Lessons"),
    },
    {
      accessorKey: "membercount",
      header: t("Team"),
    },
    {
      accessorKey: "subscribercount",
      header: t("Subsribers"),
      size: 250,
    },
    {
      accessorKey: "taskcount",
      header: t("Tasks"),
    },
    {
      accessorKey: "coursedate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    [courseList, i18n.language]
  );

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedCourseIds(selectedIdsArray);
    } else {
      setSelectedCourseIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <AppUserDetailsLayout moduleName="courses" breadcrumbText={breadcrumbText}>
      <CourseListHeader reloadData={() => setIsRefresh(true)} />
      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper mb-5">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={courseList} // data from api to be displayed
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{
              rowSelection,
            }} //pass our managed row selection state to the table to use
            initialState={{
              rowSelection,
            }}
            enableRowActions={true}
            enableColumnOrdering={true}
            renderRowActionMenuItems={({ row }) => {
              const menuItems = [
                <MenuItem key="details">
                  <a
                    className="dropdown-item"
                    href={`${url.FRONTEND_BASE_URL}course/details/${row.id}`}
                    target="_blank"
                  >
                    {t("Details")}
                  </a>
                </MenuItem>,
              ];

              return menuItems;
            }} // action columns menu items
            //   onColumnVisibilityChange={onColumnVisiblityHandler}
            //   onColumnOrderChange={changeColumnOrderHandler}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
            muiSearchTextFieldProps={{
              placeholder: t("Search by name / moderator"),
            }}
          />
        </div>
      )}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </AppUserDetailsLayout>
  );
};

export default AppuserCourseBody;
