/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import Loading from "Components/Common/Loading/Loading";
import ChallengeDetailsCommonHeader from "../Common/Header/ChallengeDetailsCommonHeader";
import UpdateProcessModal from "./Modal/UpdateProcessModal";

const ChallengeProcessBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const glowCount = [1, 2, 3];

  const { t, i18n } = useTranslation();

  const { id: challengeId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Challenges"), link: "/admin/challenges/manage" },
    { title: "" },
  ]);

  const [isLoading, setIsLoading] = useState("");
  const [processList, setProcessList] = useState([]);

  const [selectedProcessId, setSelectedProcessId] = useState(null);

  const [processTitle, setProcessTitle] = useState("");
  const [processLocation, setProcessLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [processOrder, setProcessOrder] = useState(processList.length + 1);

  const [isSaving, setIsSaving] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [validation, setValidation] = useState({
    titleError: false,
  });

  //function for validation
  const validationHandler = () => {
    let isValid = true;

    if (processTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get job details
  const getJobDetails = async () => {
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${challengeId}?token=${token}`;

      const response = await getData(requestURL);

      console.log("response in job details ----->", response);

      setIsLoading(false);

      if (response.status) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("Challenges"), link: "/admin/challenges/manage" },
          { title: data.title },
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllChallengeProcess = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_PROCESS +
        `?token=${token}&challenge=${challengeId}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        setProcessList(response.data);
        setProcessOrder(response.data.length + 1);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      console.log(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  // Handle delete operation
  const deleteProcessHandler = async (id) => {
    const result = window.confirm(t("Are you sure you want to delete?"));
    if (result) {
      try {
        const processData = {
          status: "0",
          challengeid: challengeId,
        };

        const requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_CHALLENGE_PROCESS +
          `/${id}?token=${token}`;

        const response = await putData(requestURL, processData);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          setProcessList(processList.filter((process) => process._id !== id));
          setProcessOrder(processOrder - 1);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    }
  };

  //add new process
  const addNewProcessHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        const processData = {
          challengeid: challengeId,
          title: processTitle,
          location: processLocation,
          startdate: startDate,
          enddate: endDate,
          order: processOrder,
          tags: [],
          ecosystemid: userInfo.ecosystemid,
          status: "1",
        };

        const requestURL =
          url.API_BASE_URL +
          url.API_ADD_NEW_CHALLENGE_PROCESS +
          `?token=${token}`;

        const response = await postData(requestURL, processData);

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            setProcessList((prevData) => [...prevData, response.data]);
            setProcessOrder(processOrder + 1);
            resetHandler();
          }, 1500);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setAlertMessage("Please enter process title");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  const resetHandler = () => {
    setProcessTitle("");
    setProcessLocation("");
    setStartDate("");
    setEndDate("");

    setValidation({
      titleError: false,
    });
  };

  useEffect(() => {
    if (challengeId) {
      getJobDetails();
      getAllChallengeProcess();
    }
  }, [challengeId]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <ChallengeDetailsCommonHeader moduleName="process" />

      <section id="main_content" className="pb-4">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="hiring_process_wrapper">
            <div className="row">
              {/* Left Column */}
              <div className="col-lg-8">
                <div className="hiring-process-form-container">
                  {/* Saved Phases */}
                  <div className="hiring-process">
                    {isLoading
                      ? glowCount.map((item, index) => {
                          return (
                            <div
                              className="hiring-fields p-4 bg-gray-100 mb-4"
                              key={index}
                            >
                              <div className="survey_edit_delbtns mb-2">
                                <ul>
                                  <li className="me-4">
                                    <Link to="#" aria-label="Delete Phase">
                                      <i className="material-icons-outlined">
                                        delete
                                      </i>
                                      <span className="placeholder col-12"></span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" aria-label="Edit Phase">
                                      <i className="material-icons-outlined">
                                        edit
                                      </i>
                                      <span className="placeholder col-12"></span>
                                    </Link>
                                  </li>
                                </ul>
                              </div>

                              <div className="row gx-2 mb-3">
                                <div className="col-md-10">
                                  <div className="form-row mb-3 mb-md-0 d-flex align-items-center border-0 placeholder-glow">
                                    <span className="placeholder col-12"></span>
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-row d-flex align-items-center border-0 placeholder-glow">
                                    <span className="placeholder col-12"></span>
                                  </div>
                                </div>
                              </div>

                              <div className="row gx-2">
                                <div className="col-md-6">
                                  <div className="form-row mb-3 mb-md-0 d-flex align-items-center border-0 placeholder-glow">
                                    <span className="placeholder col-12"></span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-row d-flex align-items-center border-0 placeholder-glow">
                                    <span className="placeholder col-6"></span>
                                    <span className="placeholder col-6"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : processList.map((process, index) => (
                          <div
                            className="hiring-fields p-4 bg-gray-100 mb-4"
                            key={index}
                          >
                            <div className="survey_edit_delbtns mb-2">
                              <ul>
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      deleteProcessHandler(process._id)
                                    }
                                    aria-label="Delete Phase"
                                  >
                                    <i className="material-icons-outlined">
                                      delete
                                    </i>
                                    <span>Delete</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#updateProcessModal"
                                    aria-label="Edit Phase"
                                    onClick={() =>
                                      setSelectedProcessId(process._id)
                                    }
                                  >
                                    <i className="material-icons-outlined">
                                      edit
                                    </i>
                                    <span>Edit</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>

                            <div className="row gx-2 mb-3">
                              <div className="col-md-10">
                                <div className="form-row mb-3 mb-md-0 d-flex align-items-center border-0">
                                  <p className="form-control shadow-none rounded-0 border-0">
                                    {process.title}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="form-row d-flex align-items-center border-0">
                                  <p className="form-control shadow-none rounded-0 border-0">
                                    {process.order}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="row gx-2">
                              <div className="col-md-6">
                                <div className="form-row mb-3 mb-md-0 d-flex align-items-center border-0">
                                  <p className="form-control shadow-none rounded-0 border-0">
                                    {process.location}
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-row d-flex align-items-center border-0">
                                  <p className="form-control shadow-none rounded-0 border-0">
                                    {process.processstartdate}
                                  </p>
                                  <p className="form-control shadow-none rounded-0 border-0">
                                    {process.processenddate}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>

                  {/* Add new Form */}

                  <div className="hiring-process">
                    <div className="hiring-fields p-4 bg-gray-100 mb-4">
                      <div className="row gx-2 mb-3">
                        <div className="col-md-10">
                          <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 pe-3">
                            <input
                              type="text"
                              name="title"
                              className="form-control shadow-none rounded-0 border-0"
                              placeholder="Process Name"
                              value={processTitle}
                              onChange={(e) => {
                                setProcessTitle(e.target.value);
                                setValidation((prevState) => ({
                                  ...prevState,
                                  titleError: false,
                                }));
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                            <input
                              type="number"
                              name="order"
                              className="form-control shadow-none rounded-0 border-0"
                              min="1"
                              placeholder="Order"
                              value={processOrder}
                              onChange={(e) => {
                                setProcessOrder(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row gx-2">
                        <div className="col-md-6">
                          <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 pe-3">
                            <input
                              type="text"
                              id="location"
                              name="location"
                              className="form-control shadow-none rounded-0 border-0"
                              placeholder="Location"
                              value={processLocation}
                              onChange={(e) => {
                                setProcessLocation(e.target.value);
                              }}
                            />
                            <i className="d-block material-icons text-gray-600">
                              my_location
                            </i>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                            <input
                              type="date"
                              name="fromdate"
                              className="form-control border-0 rounded-none shadow-none"
                              value={startDate}
                              onChange={(e) => {
                                setStartDate(e.target.value);
                              }}
                            />
                            <input
                              type="date"
                              name="todate"
                              className="form-control border-0 rounded-none shadow-none"
                              value={endDate}
                              onChange={(e) => {
                                setEndDate(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="action d-flex flex-wrap align-items-center justify-content-between">
                    {validation.titleError && (
                      <div className="error-message">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-icons-outlined">
                            warning
                          </span>
                          <span>{t("Please enter title")}</span>
                        </p>
                      </div>
                    )}{" "}
                    <button
                      type="button"
                      className="btn btn-yellow rounded-0 ms-auto"
                      onClick={addNewProcessHandler}
                      disabled={isSaving ? true : false}
                      style={{
                        cursor: isSaving ? "not-allowed" : "pointer",
                      }}
                    >
                      <i className="material-icons me-2">save</i> Add Process
                      {isSaving && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* Right Column (Flow Section) */}
              <div className="col-md-4">
                <div className="hiring-flow h-100 bg-gray-100 p-4">
                  <h6 className="text-primary fw-bold mb-4">Flow</h6>
                  <ul className="flow">
                    {isLoading
                      ? glowCount.map((item, index) => {
                          return (
                            <li key={index}>
                              <h6 className="text-primary fw-bold mb-3 placeholder-glow">
                                <span className="placeholder col-6"></span>
                              </h6>
                              <div className="badges d-flex flex-wrap gap-2 mb-3">
                                <span className="location d-flex align-items-center bg-info text-white p-2 placeholder-glow">
                                  <i className="d-block material-icons me-1">
                                    location_on
                                  </i>
                                  <span className="placeholder col-4"></span>
                                </span>
                                <span className="date d-flex align-items-center bg-info text-white p-2 placeholder-glow">
                                  <i className="d-block material-icons me-1">
                                    date_range
                                  </i>
                                  <span className="placeholder col-4"></span> -{" "}
                                  <span className="placeholder col-4"></span>
                                </span>
                              </div>
                            </li>
                          );
                        })
                      : processList.map((process, index) => (
                          <li key={index}>
                            <h6 className="text-primary fw-bold mb-3">
                              {process.title}
                            </h6>
                            <div className="badges d-flex flex-wrap gap-2 mb-3">
                              <span className="location d-flex align-items-center bg-info text-white p-2">
                                <i className="d-block material-icons me-1">
                                  location_on
                                </i>{" "}
                                {process.location}
                              </span>
                              <span className="date d-flex align-items-center bg-info text-white p-2">
                                <i className="d-block material-icons me-1">
                                  date_range
                                </i>{" "}
                                {process.processstartdate} -{" "}
                                {process.processenddate}
                              </span>
                            </div>
                          </li>
                        ))}
                    <li>
                      <h6 className="fw-bold text-gray">Unnamed</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateProcessModal
        selectedProcessId={selectedProcessId}
        setSelectedProcessId={setSelectedProcessId}
        processList={processList}
        setProcessList={setProcessList}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default ChallengeProcessBody;
