/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

const ViewResponsePopup = ({ viewInviteId, setViewInviteId }) => {
  const starCount = [1, 2, 3, 4, 5];
  const { t } = useTranslation(); //for translation
  const [questionList, setquestionList] = useState([]);
  const [surveyResponsed, setSurveyResponsed] = useState(0);

  //get invite details
  const getSurveyInviteDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_SURVEY_INVITE_DETAILS +
        `/${viewInviteId}`;

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setquestionList(response.data.questionlist);
        setSurveyResponsed(response.data.surveyresponses.length);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (viewInviteId) {
      getSurveyInviteDetails();
    }
  }, [viewInviteId]);

  return (
    <div
      class="offcanvas md offcanvas-end bg-gray-100 border-0"
      tabindex="-1"
      id="offcanvasSurveyResponse"
      aria-labelledby="offcanvasSurveyResponseLabel"
      style={{ visibility: "hidden" }}
      aria-hidden="true"
    >
      <div class="offcanvas-header justify-content-between p-4 pb-0">
        <h4 className="offcanvas-title fw-bold" id="addToChallengeLabel">
          {t("Response")}
        </h4>
        <button
          type="button"
          class="btn-close p-0 bg-white rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={() => {
            setquestionList([]);
            setSurveyResponsed(0);
            setViewInviteId(null);
          }}
        ></button>
      </div>
      <div class="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className={`d-flex flex-column ${
            surveyResponsed === 0 ? "h-25" : "h-100"
          }`}
        >
          {surveyResponsed === 0 ? (
            <div className="form-group mb-4 mb-md-5">
              {" "}
              <label htmlFor="" className="d-block fs-lg fw-semibold mb-2">
                Awaiting for response ....
              </label>
            </div>
          ) : null}

          {questionList.map((question, index) => {
            return (
              <div className="form-group mb-4 mb-md-5" key={index}>
                {question.surveyanswer == "" ? null : (
                  <label htmlFor="" className="d-block fs-lg fw-semibold mb-2">
                    {question.name}
                  </label>
                )}

                {question.surveyanswer ==
                "" ? null : question.questiontypeslug === "RATING" ? (
                  <ul className="d-flex">
                    {starCount.map((count, index) => {
                      return (
                        <li key={index}>
                          <span
                            className={`d-block material-symbols-outlined ${
                              index < parseInt(question.surveyanswer)
                                ? "text-primary icon-fill"
                                : "text-gray"
                            }  `}
                          >
                            star
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                ) : question.questiontypeslug === "SINGLE_SELECT" ? (
                  <ul className="d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    <li className="px-3 py-2 border border-gray-300 rounded-10">
                      {question.surveyanswer}
                    </li>
                  </ul>
                ) : question.questiontypeslug === "MULTI_SELECT" ? (
                  <ul className="d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {question.surveyanswer.split(", ").map((answer, index2) => {
                      return (
                        <li
                          className="px-3 py-2 border border-gray-300 rounded-10"
                          key={index2}
                        >
                          {answer}
                        </li>
                      );
                    })}
                  </ul>
                ) : question.questiontypeslug === "TEXT" ||
                  question.questiontypeslug === "COMMENTS" ? (
                  <p className="fs-md">{question.surveyanswer}</p>
                ) : null}
              </div>
            );
          })}

          <div className="action d-flex align-items-center gap-3 flex-wrap justify-content-between">
            <a
              href={`${url.FRONTEND_BASE_URL}survey/invite/${viewInviteId}`}
              target="_blank"
              className="btn btn-primary"
            >
              Go to Survey
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewResponsePopup;
