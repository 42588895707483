/* eslint-disable */
import Sildes from "Pages/Administration/Slides/Sildes";
import Dashboard from "Pages/Dashboard/Dashboard";
import Login from "Pages/Login/Login";
import Logout from "Pages/Login/Logout";
import SaveCategory from "Pages/Administration/Categories/Save/SaveCategory";
import Categories from "Pages/Administration/Categories/Manage/Categories";
import ModuleList from "Pages/Administration/Module/Manage/ModuleList";
import SaveModule from "Pages/Administration/Module/Save/SaveModule";
import ModuleActivity from "Pages/Administration/ModuleActivity/ModuleActivity";
import ModuleActivityPoints from "Pages/Administration/ModuleActivityPoints/ModuleActivityPoints";
import ModuleRoles from "Pages/Administration/ModuleRoles/ModuleRoles";
import ModulePrevilegeByRoles from "Pages/Administration/ModulePrevilegeByRoles/ModulePrevilegeByRoles";
import MailTemplateList from "Pages/Administration/MailTemplate/Manage/MailTemplateList";
import SaveMailTemplate from "Pages/Administration/MailTemplate/Save/SaveMailTemplate";
import CourseUploaded from "Pages/Administration/CourseUpload/CourseUploaded";
import AppUserList from "Pages/Crm/AppUsers/List/AppUserList";
import AppUserPersonalInfo from "Pages/Crm/AppUsers/Details/PersonalInfo/AppUserPersonalInfo";
import AppUserExperience from "Pages/Crm/AppUsers/Details/Experience/AppUserExperience";
import AppUserInterest from "Pages/Crm/AppUsers/Details/Interest/AppUserInterest";
import AppUserImprovements from "Pages/Crm/AppUsers/Details/Improvements/AppUserImprovements";
import AppUserSurvey from "Pages/Crm/AppUsers/Details/Surveys/AppUserSurvey";
import AppUserCourses from "Pages/Crm/AppUsers/Details/Courses/AppUserCourses";
import AppUserCertification from "Pages/Crm/AppUsers/Details/Certification/AppUserCertification";
import AppUserPassword from "Pages/Crm/AppUsers/Details/Password/AppUserPassword";
import AppUserAccount from "Pages/Crm/AppUsers/Details/Account/AppUserAccount";
import AppUserPrivacy from "Pages/Crm/AppUsers/Details/Privacy/AppUserPrivacy";
import AppUserEcoins from "Pages/Crm/AppUsers/Details/Ecoins/AppUserEcoins";
import MailConversation from "Pages/Crm/Conversation/MailConversation";
import CrmList from "Pages/Crm/List/CrmList";
import CrmTags from "Pages/Crm/Tags/CrmTags";
import ContactList from "Pages/Crm/Contacts/Manage/ContactList";
import ContactDetails from "Pages/Crm/Contacts/Details/ContactDetails";
import CrmListMember from "Pages/Crm/List/CrmListMember";
import CourseList from "Pages/Course/Manage/CourseList";
import SaveCourse from "Pages/Course/Details/Save/SaveCourse";
import CourseChapters from "Pages/Course/Details/Chapters/CourseChapters";
import CourseLessons from "Pages/Course/Details/Lessons/CourseLessons";
import LessonTasks from "Pages/Course/Details/LessonTasks/LessonTasks";
import CourseMembers from "Pages/Course/Details/Team/CourseMembers";
import CourseSubscribers from "Pages/Course/Details/Subscribers/CourseSubscribers";
import SubmittedTasks from "Pages/Course/Details/TaskSubmitted/SubmittedTasks";
import CourseCertifications from "Pages/Course/Details/Certifications/CourseCertifications";
import QuestionAsked from "Pages/Course/Details/QuestionAsked/QuestionAsked";
import AuthValidate from "Pages/Login/AuthValidate";
import EventList from "Pages/Event/Manage/EventList";
import GroupList from "Pages/Group/Manage/GroupList";
import GroupInfo from "Pages/Group/Details/GroupInfo/GroupInfo";
import { components } from "react-select";
import GroupGallery from "Pages/Group/Details/Gallery/GroupGallery";
import GroupMembers from "Pages/Group/Details/Members/GroupMembers";
import EventInfo from "Pages/Event/Details/EventInfo/EventInfo";
import EventGallery from "Pages/Event/Details/Gallery/EventGallery";
import EventMembers from "Pages/Event/Details/Members/EventMembers";
import FaqList from "Pages/Faq/FaqList";
import BlogList from "Pages/Blog/Manage/BlogList";
import BlogDetails from "Pages/Blog/Details/BlogDetails";
import ChallengeList from "Pages/Challenge/Manage/ChallengeList";
import SaveChallenge from "Pages/Challenge/Details/Save/SaveChallenge";
import ChallengeMember from "Pages/Challenge/Details/Members/ChallengeMember";
import ChallengeDocs from "Pages/Challenge/Details/Docs/ChallengeDocs";
import ChallengeProcess from "Pages/Challenge/Details/Process/ChallengeProcess";
import ChallengeResponse from "Pages/Challenge/Details/Response/ChallengeResponse";
import ChallengeMemberView from "Pages/Challenge/Manage/ChallengeMemberView";
import SurveyList from "Pages/Survey/Manage/SurveyList";
import SurveyQuestion from "Pages/Survey/Details/SurveyQuestions/SurveyQuestion";
import SurveyInvites from "Pages/Survey/Details/SurveyInvites/SurveyInvites";
import SurveyResponses from "Pages/Survey/Details/SurveyResponses/SurveyResponses";
/*======= public route start =======*/
const publicRoutes = [
  { path: "/", component: Login }, //login page,
  /* ======== validation start ======== */
  { path: "/auth/validate", component: AuthValidate }, //validate page
  /* ======== validation end ======== */
];
/*======= public route end =======*/

/*======= protected route start =======*/
const protectedRoutes = [
  /* ========== logout start ======== */
  {
    path: "/logout",
    component: Logout,
  },
  /* ========== logout end ======== */
  /* ======== validation start ======== */
  { path: "/auth/validate", component: AuthValidate }, //validate page
  /* ======== validation end ======== */

  /* ======== dashboard start ========== */
  {
    path: "/admin/dashboard",
    component: Dashboard,
  },
  /* ======== dashboard end ========== */

  /* ======== administration start ========== */
  {
    path: "/admin/slides/manage",
    component: Sildes,
  },
  {
    path: "/admin/category/manage",
    component: Categories,
  },
  {
    path: "/admin/category/add",
    component: SaveCategory,
  },
  {
    path: "/admin/module/manage",
    component: ModuleList,
  },
  {
    path: "/admin/module/create",
    component: SaveModule,
  },
  {
    path: "/admin/module/update/:id",
    component: SaveModule,
  },
  {
    path: "/admin/moduleactivity/manage",
    component: ModuleActivity,
  },
  {
    path: "/admin/moduleactivitypoints/manage",
    component: ModuleActivityPoints,
  },
  {
    path: "/admin/moduleroles/manage",
    component: ModuleRoles,
  },
  {
    path: "/admin/roleprivileges/manage",
    component: ModulePrevilegeByRoles,
  },
  {
    path: "/admin/communicationtemplate/manage",
    component: MailTemplateList,
  },
  {
    path: "/admin/communicationtemplate/create",
    component: SaveMailTemplate,
  },
  {
    path: "/admin/communicationtemplate/update/:id",
    component: SaveMailTemplate,
  },
  {
    path: "/admin/courses/uploaded",
    component: CourseUploaded,
  },
  /* ======== administration end ========== */

  /*======= Crm Start ======= */
  /*---- app user start ----*/
  {
    path: "/admin/contact/allusers",
    component: AppUserList,
  },
  {
    path: "/admin/userdetails/manage/personalinfo/:id",
    component: AppUserPersonalInfo,
  },
  {
    path: "/admin/userdetails/manage/experience/:id",
    component: AppUserExperience,
  },
  {
    path: "/admin/userdetails/manage/interest/:id",
    component: AppUserInterest,
  },
  {
    path: "/admin/userdetails/manage/improvements/:id",
    component: AppUserImprovements,
  },
  {
    path: "/admin/userdetails/manage/surveys/:id",
    component: AppUserSurvey,
  },
  {
    path: "/admin/userdetails/manage/courses/:id",
    component: AppUserCourses,
  },
  {
    path: "/admin/userdetails/manage/certification/:id",
    component: AppUserCertification,
  },
  {
    path: "/admin/userdetails/manage/password/:id",
    component: AppUserPassword,
  },
  {
    path: "/admin/userdetails/manage/account/:id",
    component: AppUserAccount,
  },
  {
    path: "/admin/userdetails/manage/privacy/:id",
    component: AppUserPrivacy,
  },
  {
    path: "/admin/userdetails/manage/ecoins/:id",
    component: AppUserEcoins,
  },

  /*---- app user end ----*/

  /*---- contact start ----*/
  {
    path: "/admin/contact/manage",
    component: ContactList,
  },
  {
    path: "/admin/contact/view/:id",
    component: ContactDetails,
  },
  /*---- contact end ----*/

  /*---- mail start ----*/
  {
    path: "/admin/messages/inbox",
    component: MailConversation,
  },
  {
    path: "/admin/messages/sent",
    component: MailConversation,
  },
  /*---- mail end ----*/

  /*---- list start ----*/
  {
    path: "/admin/list/manage",
    component: CrmList,
  },
  {
    path: "/admin/list/member/manage/:id",
    component: CrmListMember,
  },
  /*---- list end ----*/

  /*---- label start ----*/
  {
    path: "/admin/tags/manage",
    component: CrmTags,
  },
  /*---- label end ----*/

  /*======= Crm End ======= */

  /**====== Group start ======*/
  {
    path: "/admin/group/manage",
    component: GroupList,
  },
  {
    path: "/admin/group/create",
    component: GroupInfo,
  },
  {
    path: "/admin/group/update/:id",
    component: GroupInfo,
  },
  {
    path: "/admin/group/members/list/:id",
    component: GroupMembers,
  },
  {
    path: "/admin/group/addmedia/:id",
    component: GroupGallery,
  },
  /**====== Group start ======*/

  /**====== Event start ======*/
  {
    path: "/admin/event/manage",
    component: EventList,
  },
  {
    path: "/admin/event/create",
    component: EventInfo,
  },
  {
    path: "/admin/event/update/:id",
    component: EventInfo,
  },
  {
    path: "/admin/event/members/list/:id",
    component: EventMembers,
  },
  {
    path: "/admin/event/addmedia/:id",
    component: EventGallery,
  },
  /**====== Event start ======*/

  /**====== Challenge start ======*/
  {
    path: "/admin/challenges/manage",
    component: ChallengeList,
  },
  {
    path: "/admin/challenges/managebyrole",
    component: ChallengeMemberView,
  },
  {
    path: "/admin/challanges/save",
    component: SaveChallenge,
  },
  {
    path: "/admin/challanges/save/:id",
    component: SaveChallenge,
  },
  {
    path: "/admin/challanges/members/:id",
    component: ChallengeMember,
  },
  {
    path: "/admin/challanges/addmedia/:id",
    component: ChallengeDocs,
  },
  {
    path: "/admin/challanges/phases/:id",
    component: ChallengeProcess,
  },
  {
    path: "/admin/challanges/responses/report/:id",
    component: ChallengeResponse,
  },
  /**====== Challenge end ======*/

  /**====== course start ======*/
  {
    path: "/admin/course/manage",
    component: CourseList,
  },
  {
    path: "/admin/course/save",
    component: SaveCourse,
  },
  {
    path: "/admin/course/save/:id",
    component: SaveCourse,
  },
  {
    path: "/admin/course/chapters/manage/:id",
    component: CourseChapters,
  },
  {
    path: "/admin/course/lessons/manage/:id",
    component: CourseLessons,
  },
  {
    path: "/admin/course/lessons/tasks/manage/:id/:lid",
    component: LessonTasks,
  },
  {
    path: "/admin/course/membership/:id",
    component: CourseMembers,
  },
  {
    path: "/admin/course/subscribers/list/:id",
    component: CourseSubscribers,
  },
  {
    path: "/admin/course/lessons/subscribertasks/:id",
    component: SubmittedTasks,
  },
  {
    path: "/admin/course/subscribers/questions/:id",
    component: QuestionAsked,
  },
  {
    path: "/admin/course/subscribers/certification/:id",
    component: CourseCertifications,
  },

  /**====== course end ======*/

  /**====== blog start ====== */

  {
    path: "/admin/blog/post/list",
    component: BlogList,
  },
  {
    path: "/admin/blog/post/create",
    component: BlogDetails,
  },
  {
    path: "/admin/blog/post/update/:id",
    component: BlogDetails,
  },
  /**====== blog end ====== */

  /** Faq start */
  {
    path: "/admin/faq/manage",
    component: FaqList,
  },
  /** Faq end */
  /**====== Survey start ====== */
  {
    path: "/admin/survey/manage",
    component: SurveyList,
  },
  {
    path: "/admin/survey/question/list/:id",
    component: SurveyQuestion,
  },
  {
    path: "/admin/survey/invite/list/:id",
    component: SurveyInvites,
  },
  {
    path: "/admin/survey/response/list/:id",
    component: SurveyResponses,
  },

  /**====== Survey end ====== */
];
/*======= protected route end =======*/

export { publicRoutes, protectedRoutes };
