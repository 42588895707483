import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const GroupMemberHeader = ({
  updateGroupMember = () => {},
  reloadList = () => {},
  breadCrumbText = [],
}) => {
  const { t } = useTranslation();
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-2 col-lg-3 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadCrumbText} />
        </div>

        <div className="col-md-10 col-lg-9 d-flex gap-2 justify-content-end">
          <Link
            to="#"
            className="btn btn-secondary rounded-0 shadow-none  "
            onClick={reloadList}
          >
            <i className="material-icons">refresh</i>
          </Link>

          <Link
            to="#"
            type="button"
            className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
            data-bs-toggle="modal"
            data-bs-target="#saveGroupMemberModal"
            area-label="Invite saveGroupMemberModal"
          >
            <i className="material-icons me-2">add</i>
            {t("Invite Contact")}
          </Link>

          <div className="dropdown" id="module-mass-action">
            <button
              className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#addNewEmail"
                >
                  Send Mail
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => {
                    updateGroupMember("1");
                  }}
                >
                  Approve Selected
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => {
                    updateGroupMember("0");
                  }}
                >
                  Disallow Selected
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addTagModal"
                >
                  Add Label
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupMemberHeader;
