/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI components
import { MaterialReactTable } from "material-react-table";
import { MenuItem, Box } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import AppUserDetailsLayout from "../Common/AppUserDetailsLayout";
import ViewResponsePopup from "./Popup/ViewResponsePopup";

const AppUserSurveyBody = () => {
  const params = useParams();

  const { t, i18n } = useTranslation(); //for translation

  const moduleAccess = localStorage.getItem("moduleaccess");

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [isLoading, setIsLoading] = useState(false);
  const [inviteList, setinviteList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedInviteIds, setSelectedInviteIds] = useState([]);

  const [viewInviteId, setViewInviteId] = useState(null);

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("App User"), link: "/admin/appuser/list" },
    { title: t("Survey Invites") },
  ]);

  //* function for get user details
  const getAppUserDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("App User"), link: "/admin/appuser/list" },
          { title: t("Survey Invites") },
          { title: data.name + " " + data.surname },
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* function for get all invites
  const getAllInvites = async () => {
    setRowSelection({});
    setSelectedInviteIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_INVITES +
        `?token=${token}&filteruserid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setinviteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getAppUserDetails();
      getAllInvites();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        size: 50,
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSurveyResponse"
            aria-controls="offcanvasSurveyResponse"
            onClick={() => {
              setViewInviteId(row.original._id);
            }}
          >
            <i className="material-icons horz_icon">more_horiz</i>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "surveyname",
        header: t("Survey"),
      },
      {
        accessorKey: "totalquestioncount",
        header: t("Survey Question Count"),
      },
      {
        accessorKey: "questionsubmitted",
        header: t("Question Attended"),
      },
      {
        accessorKey: "invitedate",
        header: t("Date"),
      },
      {
        accessorKey: "tags",
        header: t("Tags"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tags != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInviteIds(selectedIdsArray);
    } else {
      setSelectedInviteIds([]);
    }
  }, [rowSelection]);

  return (
    <AppUserDetailsLayout moduleName="surveys" breadcrumbText={breadCrumbText}>
      <div class="table_area">
        {isLoading ? (
          <div className="placeholder-glow d-flex flex-column gap-4">
            <span className="placeholder placeholder-lg bg-secondary col-12"></span>
            <span className="placeholder placeholder-lg bg-secondary col-8"></span>
            <span className="placeholder placeholder-lg bg-secondary col-4"></span>
          </div>
        ) : (
          <div className="table-wrapper">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={inviteList} // data from api to be displayed
              positionActionsColumn="last"
              enableGrouping
              enableRowSelection // enable showing checkbox
              getRowId={(row) => row._id} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{ rowSelection }} //pass our managed row selection state to the table to use
              defaultColumn={{
                minSize: 20,
                maxSize: 200,
                size: 50, //make columns wider by default
              }}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
            />
          </div>
        )}
      </div>

      <ViewResponsePopup
        viewInviteId={viewInviteId}
        setViewInviteId={setViewInviteId}
      />
    </AppUserDetailsLayout>
  );
};

export default AppUserSurveyBody;
