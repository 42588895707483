/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*import url and gateway methods */
import { getData, putData } from "Utils/Gateway";
import * as url from "Helper/UrlHelper";

import CrmCommonLayout from "Components/Crm/Common/CrmCommonLayout";
import ContactListHeader from "./Header/ContactListHeader";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import ContactListFilterModal from "./Modal/ContactListFilterModal";
import AddToListModal from "Components/Common/Modal/AddToListModal";
import AddTagModal from "Components/Common/Modal/AddTagModal";
import CreateEmailPopup from "Components/Common/Popup/CreateEmailPopup";

const ContactListBody = () => {
  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("CRM") }, { title: t("Contact") }];

  const token = localStorage.getItem("token");

  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState("");
  const [selectedContactNames, setselectedContactNames] = useState("");

  //filter states
  const [filterName, setfilterName] = useState("");
  const [filterEmail, setfilterEmail] = useState("");
  const [filterPhone, setfilterPhone] = useState("");
  const [filterPostion, setfilterPostion] = useState("");
  const [filterCompany, setfilterCompany] = useState("");
  const [filterLabels, setfilterLabels] = useState([]);

  //** alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all contacts
  const getAllContactList = async () => {
    setRowSelection({});
    setSelectedContactIds([]);
    setSelectedUserIds([]);
    setSelectedUserEmails("");

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_CONTACTS +
        `?token=${token}&modulefrom=backend`;

      if (filterName != "") {
        requestURL = requestURL + `&filtername=${filterName}`;
      }

      if (filterEmail != "") {
        requestURL = requestURL + `&filteremail=${filterEmail}`;
      }

      if (filterPhone != "") {
        requestURL = requestURL + `&filterphone=${filterPhone}`;
      }

      if (filterPostion != "") {
        requestURL = requestURL + `&filterposition=${filterPostion}`;
      }

      if (filterCompany != "") {
        requestURL = requestURL + `&filtercompany=${filterCompany}`;
      }

      if (filterLabels.length > 0) {
        requestURL = requestURL + `&filterlabels=${filterLabels}`;
      }

      console.log("url of contact list------>", requestURL);

      const response = await getData(requestURL);

      console.log("response in contact list------>", response);

      if (response.status) {
        setContactList(response.data);
        setMessageType("success");
        resetFilterData();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);

      setIsLoading(false);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for unfriend contact
  const unfriendHandler = async (personId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_UNFRIEND_CONTACT +
        `?token=${token}&personid=${personId}`;

      const response = await putData(requestURL);

      console.log(response);

      if (response.status) {
        setMessageType("success");

        setTimeout(() => {
          getAllContactList();
        }, 2500);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterEmail("");
    setfilterPhone("");
    setfilterPostion("");
    setfilterCompany("");
    setfilterLabels([]);
  };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedContactIds([]);
    setSelectedUserIds([]);
    setSelectedUserEmails("");
  };

  useEffect(() => {
    if (
      filterName != "" ||
      filterEmail != "" ||
      filterPhone != "" ||
      filterPostion != "" ||
      filterCompany != "" ||
      filterLabels.length > 0
    ) {
      getAllContactList();
    }
  }, [
    filterName,
    filterEmail,
    filterPostion,
    filterCompany,
    filterPhone,
    filterLabels,
  ]);

  useEffect(() => {
    getAllContactList();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "contactname",
      header: t("Name"),
      size: 200,
      Cell: ({ row }) => (
        <div className="user d-flex align-items-center">
          <div className="profile d-flex align-items-center">
            <div className="img-container me-2">
              {row.original.ownerstatus === "1" &&
              row.original.memberstatus === "1" ? (
                <Link to="#">
                  <img
                    height={35}
                    width={35}
                    src={
                      row.original.image === ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + row.original.image
                    }
                    className="img-fluid lead-img rounded-circle"
                  />
                </Link>
              ) : (
                <span className="material-icons text-warning me-1">
                  pending_actions
                </span>
              )}
            </div>

            <div>
              <p className="name">
                <span>{row.original.name}</span>
              </p>
            </div>
          </div>
        </div>
      ),
    },

    {
      accessorKey: "email",
      header: t("Email"),
      size: 300,
    },
    {
      accessorKey: "phone",
      header: t("Phone"),
    },
    {
      accessorKey: "position",
      header: t("Position"),
      size: 250,
    },
    {
      accessorKey: "city",
      header: t("City"),
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      size: 200,
    },
    {
      accessorKey: "approvedate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectedContactIdArr = selectedIdsArray.map((id) => {
        const [contactId, email, contactUserId, contactName] = id.split("/");
        return contactId;
      });

      const selectedContactUserIdArr = selectedIdsArray.map((id) => {
        const [contactId, email, contactUserId, contactName] = id.split("/");
        return contactUserId;
      });

      const selectUserEmails = selectedIdsArray.map((id) => {
        const [contactId, email, contactUserId, contactName] = id.split("/");
        return email;
      });

      setSelectedContactIds(selectedContactIdArr);
      setSelectedUserIds(selectedContactUserIdArr);
      setSelectedUserEmails(selectUserEmails.join(","));
    } else {
      setSelectedContactIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CrmCommonLayout moduleName="contacts">
      <ContactListHeader
        reloadData={getAllContactList}
        breadCrumbText={breadcrumbText}
      />

      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={contactList} // data from api to be displayed
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) =>
              `${row._id}/${row.email}/${row.userid}/${row.name}`
            } // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{
              rowSelection,
            }} //pass our managed row selection state to the table to use
            initialState={{
              rowSelection,
            }}
            enableRowActions // show the action column
            enableColumnOrdering={true}
            renderRowActionMenuItems={({ row }) => {
              const [contactId, email, contactUserId, contactName] =
                row.id.split("/"); // Extracts the _id part

              const menuItems = [
                <MenuItem key="view">
                  <Link
                    className="dropdown-item"
                    to={`/admin/contact/view/${contactId}`}
                  >
                    {t("View Details")}
                  </Link>
                </MenuItem>,
              ];

              return menuItems;
            }} // action columns menu items
            //   onColumnVisibilityChange={onColumnVisiblityHandler}
            //   onColumnOrderChange={changeColumnOrderHandler}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
            muiSearchTextFieldProps={{
              placeholder: t("Search by name / email / phone / location"),
            }}
          />
        </div>
      )}

      <ContactListFilterModal
        setfilterName={setfilterName}
        setfilterEmail={setfilterEmail}
        setfilterPhone={setfilterPhone}
        setfilterPostion={setfilterPostion}
        setfilterCompany={setfilterCompany}
        setfilterLabels={setfilterLabels}
      />

      <AddToListModal
        afterModalClose={() => {
          setTimeout(() => {
            getAllContactList();
          }, 1500);
        }}
        selectedIds={selectedUserIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <AddTagModal
        moduleName="contact"
        selectedIds={selectedContactIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllContactList();
          }, 2000);
        }}
      />

      {/* ---- new mail modal ------- */}
      <CreateEmailPopup
        contactPersonEmail={selectedUserEmails}
        reloadList={afterEmailClose}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CrmCommonLayout>
  );
};

export default ContactListBody;
