import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const CategoryHeader = ({
  breadCrumbText = [],
  updateRankHandler = () => {},
  deleteCategories = () => {},
  // setFilterIsRoot = () => {},
  reloadData = () => {},
}) => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-2 col-lg-3 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadCrumbText} />
        </div>

        <div className="col-md-10 col-lg-9 d-flex gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-secondary rounded-0 shadow-none"
            onClick={reloadData}
          >
            <i className="material-icons">refresh</i>
          </button>

          <button
            type="button"
            className="btn btn-info rounded-0 shadow-none"
            data-bs-toggle="modal"
            data-bs-target="#categoryFilterModal"
          >
            <i className="material-icons">filter_alt</i>
          </button>

          <Link
            // to="/admin/category/add"
            className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
            data-bs-toggle="modal"
            data-bs-target="#saveCategoryModal"
          >
            <i className="material-icons me-2">add</i> Add New
          </Link>

          <div className="dropdown">
            <button
              className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
              {/* <li>
                  <Link
                    className="dropdown-item"
                    to="/admin/category/add"
                    data-bs-toggle="modal"
                    data-bs-target="#saveCategoryModal"
                  >
                    Add New
                  </Link>
                </li> */}
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={deleteCategories}
                >
                  Delete Selected
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={updateRankHandler}
                >
                  Update selected rank
                </Link>
              </li>

              {/* <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      setFilterIsRoot(true);
                    }}
                  >
                    Show Root Categories
                  </Link>
                </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryHeader;
