/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import { reactSelectStyle } from "Config/Config";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

const AppUserListFilterModal = ({
  setfilterName,
  setfilterEmail,
  setfilterPhone,
  setfilterPostion,
  setfilterLabels,
  setfilterCompany,
  roleList = [],
}) => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const [tagList, setTagList] = useState([]);

  const [roleValue, setroleValue] = useState(null);
  const [roleId, setroleId] = useState(null);
  const [name, setname] = useState("");
  const [surname, setsurname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [position, setposition] = useState("");
  const [city, setcity] = useState("");
  const [company, setcompany] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [tags, setTags] = useState([]);
  const [subscribeMailValue, setsubscribeMailValue] = useState(null);
  const [subscribeMailStatus, setSubscribeMailStatus] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select event tags
  const tagSelectionHandler = (data) => {
    settagValue(data);
    setTags(data.map((item) => item.value));
  };

  //function for select role
  // const roleSelectionHandler = (val) => {
  //   if (val) {
  //     setroleValue(val);
  //     setroleId(val.value);
  //   } else {
  //     setroleValue(null);
  //     setroleId(null);
  //   }
  // };

  //function for search
  const searchHandler = () => {
    setfilterName(name);
    setfilterEmail(email);
    setfilterPhone(phone);
    setfilterPostion(position);
    setfilterCompany(company);
    setfilterLabels(tags);

    // close the modal
    let filterModal = document.querySelector("#appUserFilter");
    let bootstrapModal = bootstrap.Modal.getInstance(filterModal);
    bootstrapModal.hide();
    resetHandler();
  };

  //function for reset
  const resetHandler = () => {
    setsubscribeMailValue(null);
    setSubscribeMailStatus("");
    setname("");
    setemail("");
    setphone("");
    setposition("");
    setcompany("");
    setTags([]);
    settagValue(null);
    setroleValue(null);
    setroleId(null);
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="modal fade"
      id="appUserFilter"
      aria-labelledby="appUserFilter"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4">
            <h2 className="text-green w-100" id="appUserFilter">
              Search By Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4 py-1">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* status  */}
              {/* <div className="form-row mb-3">
                <label htmlFor="status" className="fw-bold mb-2">
                  Status
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div> */}

              {/* role  */}
              {/* <div className="form-row mb-3">
                <label htmlFor="status" className="fw-bold mb-2">
                  Role
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isClearable
                  placeholder={t("Select Role")}
                  options={roleList}
                  value={roleValue}
                  onChange={(val) => roleSelectionHandler(val)}
                />
              </div> */}

              {/* name  */}
              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>

              {/* <div className="form-row mb-3">
                <label htmlFor="surname" className="fw-bold mb-2">
                  Surname
                </label>
                <input
                  type="text"
                  id="surname"
                  name="surname"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Surname"
                  value={surname}
                  onChange={(e) => setsurname(e.target.value)}
                />
              </div> */}

              {/* email  */}
              <div className="form-row mb-3">
                <label htmlFor="email" className="fw-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>

              {/* phone  */}
              <div className="form-row mb-3">
                <label htmlFor="phone" className="fw-bold mb-2">
                  Phone
                </label>
                <input
                  type="tel"
                  id="filterphone"
                  name="phone"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Phone"
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>

              {/* position  */}
              <div className="form-row mb-3">
                <label htmlFor="location" className="fw-bold mb-2">
                  Position
                </label>
                <input
                  type="text"
                  id="filterlocation"
                  name="state"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Position"
                  value={position}
                  onChange={(e) => setposition(e.target.value)}
                />
              </div>

              {/* company  */}
              <div className="form-row mb-3">
                <label htmlFor="location" className="fw-bold mb-2">
                  Company
                </label>
                <input
                  type="text"
                  id="filterlocation"
                  name="state"
                  className="form-control text-green rounded-0"
                  placeholder="Enter Position"
                  value={company}
                  onChange={(e) => setcompany(e.target.value)}
                />
              </div>

              {/* tags  */}
              <div className="form-row mb-3">
                <label htmlFor="tags" className="fw-bold mb-2">
                  Labels
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  placeholder={t("Select tags")}
                  options={tagList}
                  value={tagValue}
                  onChange={(val) => {
                    tagSelectionHandler(val);
                  }}
                />
              </div>

              {/* <div className="form-row mb-3">
                <label htmlFor="skills" className="fw-bold mb-2">
                  Skills
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div> */}

              {/* <div className="form-row mb-3">
                <label htmlFor="searching" className="fw-bold mb-2">
                  Searching
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div> */}

              {/* <div className="form-row mb-3">
                <label htmlFor="interest" className="fw-bold mb-2">
                  Interest
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div> */}

              {/* <div className="row mb-3">
                <div className="col-6">
                  <label htmlFor="startDate" className="fw-bold mb-2">
                    Start Date
                  </label>
                  <input
                    type="date"
                    name="fromdate"
                    id="startDate"
                    className="form-control text-green rounded-0"
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="endDate" className="fw-bold mb-2">
                    End Date
                  </label>
                  <input
                    type="date"
                    name="todate"
                    id="endDate"
                    className="form-control text-green rounded-0"
                  />
                </div>
              </div> */}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={searchHandler}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppUserListFilterModal;
