import React from "react";
// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";

const CourseListHeader = ({
  updateBulkHandler = () => {},
  deleteMassiveHandler = () => {},
  reloadData = () => {},
}) => {
  // const { t } = useTranslation();

  return (
    <div className="row align-items-center">
      <div className="col-sm-12">
        <div className="filter-container mb-3">
          <div className="d-flex flex-wrap justify-content-md-end align-items-center gap-2">
            <button
              type="button"
              className="btn btn-secondary rounded-0 shadow-none"
              onClick={reloadData}
            >
              <i className="material-icons">refresh</i>
            </button>
            {/* <button
              type="button"
              className="btn btn-info rounded-0 shadow-none"
              data-bs-toggle="modal"
              data-bs-target="#courselistfilterModal"
            >
              <i className="bi bi-funnel"></i>
            </button>
            <div className="dropdown">
              <button
                className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Action
              </button>
              <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={deleteMassiveHandler}
                  >
                    {t("Delete Selected")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={updateBulkHandler}
                  >
                    {t("Update Rank")}
                  </Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseListHeader;
