import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const SurveyInviteHeader = ({
  reloadList = () => {},
  name,
  copyUrlHandler = () => {},
  breadCrumbText = [],
}) => {
  const { t } = useTranslation(); //for translation

  const popupToggle = () => {
    document.body.classList.toggle("emailpopup_open");
  };

  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-2 col-lg-3 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadCrumbText} />
        </div>
        <div className="col-md-10 col-lg-9 d-flex gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-secondary rounded-0 shadow-none"
            onClick={reloadList}
          >
            <i className="material-icons">refresh</i>
          </button>

          <button
            type="button"
            className="btn btn-info rounded-0 shadow-none"
            id="module-filter"
            data-bs-toggle="modal"
            data-bs-target="#filterSurveyInviteModal"
          >
            <i className="material-icons">filter_alt</i>
          </button>

          {/* <button
          className="btn btn-secondary rounded-0 shadow-none me-2 d-flex align-items-center gap-2"
          onClick={copyUrlHandler}
        >
          <i className="material-icons">content_copy</i>

          {t("Copy Url")}
        </button> */}

          <Link
            to="#"
            className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
            id="add-new-item"
            onClick={popupToggle}
          >
            <i className="material-icons me-2">add</i> {t("Invite Users")}
          </Link>

          <div className="dropdown" id="module-mass-action">
            <button
              className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>
            <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addTagModal"
                >
                  {t("Add Label")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyInviteHeader;
