/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import CommonLayout from "Components/Common/Layout/CommonLayout";
import Loading from "Components/Common/Loading/Loading";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import SurveyCommonHeader from "../Common/SurveyCommonHeader";
import SaveQuestionModal from "./Modal/SaveQuestionModal";
import DeleteWarningModal from "Components/Common/Modal/DeleteWarningModal";

const SurveyQuestionBody = () => {
  const token = localStorage.getItem("token");
  const params = useParams();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t } = useTranslation(); //for translation

  const [name, setname] = useState("");

  const [questionTypes, setquestionTypes] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [editedQuestionId, setEditedQuestionId] = useState(null);
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("Survey"), link: "/admin/survey/manage" },
    { title: `Question / ${name}` },
  ];

  //function for get survey details
  const getSurveyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status) {
        setname(response.data.name);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all question types
  const getAllQuestionTypes = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_QUESTIONTYPES + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setquestionTypes(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all questions from survey details
  const getAllQuestions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);
      if (response.status) {
        setQuestionList(response.data?.questionlist);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for cancel delete
  const cancelDeleteHandler = () => {
    setDeleteQuestionId(null);
  };

  //function for delete question from survey
  const deleteQuestionHandler = async () => {
    try {
      let questionData = {
        surveyid: params.id,
        questionid: deleteQuestionId,
      };

      let requestUrl =
        url.API_BASE_URL + url.API_DELETE_QUESTION + `?token=${token}`;

      const response = await putData(requestUrl, questionData);

      if (response.status) {
        setDeleteQuestionId(null);
        let myModal = new bootstrap.Modal(
          document.getElementById("deleteSuccessModal")
        );
        myModal.show();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getSurveyDetails();
    getAllQuestionTypes();
    getAllQuestions();
  }, [params.id]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <SurveyCommonHeader moduleName="question" />

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadcrumbText} />

          {/* --- survey container start ---- */}
          <div className="survey-container">
            {questionList.map((question, index) => {
              return (
                <div
                  className="p-3 p-lg-4 border border-gray-100 bg-white rounded-10 shadow-sm mb-4"
                  key={index}
                >
                  <div
                    className="accordion"
                    id={`accordionPanelsStayOpenExample${index}`}
                  >
                    <div className="accordion-item bg-transparent border-0">
                      <div className="d-flex align-items-center gap-3">
                        <Link to="#" className="text-black">
                          <span className="d-block material-icons-outlined">
                            drag_indicator
                          </span>
                        </Link>
                        <h3 className="accordion-header">
                          <button
                            className="accordion-button gap-2 bg-transparent p-0 shadow-none"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#panelsStayOpen-collapse${index}`}
                            aria-expanded="true"
                            aria-controls={`#panelsStayOpen-collapse${index}`}
                          >
                            {question?.questiontype?.name}
                          </button>
                        </h3>
                        <div className="d-flex align-items-center gap-3 ms-auto">
                          <Link
                            to="#"
                            className="text-black"
                            data-bs-toggle="modal"
                            data-bs-target="#saveSurveyQuestionModal"
                            aria-controls="saveSurveyQuestionModal"
                            onClick={() => {
                              setEditedQuestionId(question._id);
                            }}
                          >
                            <span className="d-block material-icons-outlined">
                              edit
                            </span>
                          </Link>
                          <Link
                            to="#"
                            className="text-black"
                            data-bs-toggle="modal"
                            data-bs-target="#deletewarning"
                            aria-controls="deletewarning"
                            onClick={() => {
                              setDeleteQuestionId(question._id);
                            }}
                          >
                            <span className="d-block material-icons-outlined">
                              delete
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div
                        id={`panelsStayOpen-collapse${index}`}
                        className="accordion-collapse collapse show"
                      >
                        <div className="accordion-body p-0 pt-4">
                          <div className="form-group mb-4">
                            <label
                              htmlFor=""
                              className="d-block fs-md text-gray mb-2"
                            >
                              Question Name
                            </label>
                            <p className="fs-lg">{question.name}?</p>
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor=""
                              className="d-block fs-md text-gray mb-2"
                            >
                              Answer{" "}
                              {/* <span className="fs-sm fw-normal">(Correct)</span> */}
                            </label>
                            <p className="d-flex align-items-center gap-2 fs-lg">
                              <span className="d-block material-icons-outlined icon-md">
                                info
                              </span>
                              <span className="d-block">
                                {question.correctanswer}
                              </span>
                            </p>
                          </div>
                          {question.answers && question.answers.length > 0 ? (
                            <div className="form-group my-4">
                              <label
                                htmlFor=""
                                className="d-block fs-md text-gray mb-2"
                              >
                                Options
                              </label>
                              <ul className="d-flex flex-wrap gap-2 fs-sm">
                                {question.answers.map((item, index2) => {
                                  return (
                                    <li
                                      className="d-flex align-items-center gap-2 body-bg rounded-5 px-2 py-1"
                                      key={index2}
                                    >
                                      <span className="d-block">{item}</span>
                                      {/* <Link
                                        to="#"
                                        className="d-block material-icons-outlined icon-md text-gray"
                                      >
                                        close
                                      </Link> */}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="action d-flex align-items-center gap-3 flex-wrap justify-content-between mb-4">
              <Link
                to="#"
                className="btn btn-info rounded-0 shadow-none d-inline-flex align-items-center gap-1"
                data-bs-toggle="modal"
                data-bs-target="#saveSurveyQuestionModal"
                aria-controls="saveSurveyQuestionModal"
              >
                <span className="d-block material-icons-outlined">add</span>
                <span className="d-block">{t("Add Question")}</span>
              </Link>
              {/* <Link
                to="#"
                className="btn btn-primary d-inline-flex align-items-center gap-1"
                data-bs-toggle="modal"
                data-bs-target="#saveSurvey"
              >
                <span className="d-block material-icons-outlined">save</span>
                <span className="d-block">Save</span>
              </Link> */}
            </div>
          </div>

          {/* --- survey container end ---- */}
        </div>
      </section>

      <SaveQuestionModal
        questionList={questionList}
        questionTypes={questionTypes}
        afterPopupClose={getAllQuestions}
        selectedQuestionId={editedQuestionId}
        setSelectedQuestionId={setEditedQuestionId}
      />

      {/* ----------- delete warning modal ------------ */}
      <DeleteWarningModal
        onDelete={deleteQuestionHandler}
        onCancelDelete={cancelDeleteHandler}
        moduleName={t("question")}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default SurveyQuestionBody;
