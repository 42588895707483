import CrmCommonHeader from "./CrmCommonHeader";
import CommonLayout from "Components/Common/Layout/CommonLayout";

const CrmCommonLayout = ({
  children,
  breadcrumbText = [],
  moduleName = "",
  displayName = "",
}) => {
  return (
    <CommonLayout>
      <CrmCommonHeader moduleName={moduleName} />

      <section id="main_content" className="pb-4">
        <div className="container-fluid px-md-4">{children}</div>
      </section>
    </CommonLayout>
  );
};

export default CrmCommonLayout;
