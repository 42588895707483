/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*import url and gateway methods */
import { getData } from "Utils/Gateway";
import * as url from "Helper/UrlHelper";

import ContactTabHeader from "./Header/ContactTabHeader";
import AuthHeader from "Components/Common/Header/AuthHeader/AuthHeader";
import CrmCommonHeader from "Components/Crm/Common/CrmCommonHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import ContactTimelineTab from "./Tabs/ContactTimelineTab";
import ContactTaskTab from "./Tabs/ContactTaskTab";
import ConversationTab from "./Tabs/ConversationTab";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";

const ContactDetailsBody = () => {
  const { id } = useParams();
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("CRM") },
    { title: t("Contacts"), link: "/admin/contact/manage" },
    { title: t("Timeline") },
  ];

  const [contactData, setContactData] = useState({});
  const [contactPersonId, setContactPersonId] = useState(null);

  //** alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get contact details
  const getContactDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_CONTACT_DETAILS +
        `/${id}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setMessageType("success");
        setContactData(response.data);
        setContactPersonId(response.data.contactpersonid);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (id) {
      getContactDetails();
    }
  }, [id]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <>
      <AuthHeader />

      <CrmCommonHeader moduleName="contacts" />

      <section id="main_content" className="pb-4">
        <div className="details-wrapper">
          <div className="container-fluid px-md-4">
            <BreadCrumb breadCrumbText={breadcrumbText} displayName="" />
            <div className="tab-wrapper">
              <ContactTabHeader />

              <div className="tab-content" id="leaderDetailsTabContent">
                <div
                  className="tab-pane fade show active"
                  id="timeline-tab-pane"
                  role="tabpanel"
                  aria-labelledby="timeline-tab"
                  tabIndex="0"
                >
                  <ContactTimelineTab
                    contactData={contactData}
                    contactPersonId={contactPersonId}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    setMessageType={setMessageType}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="task-tab-pane"
                  role="tabpanel"
                  aria-labelledby="task-tab"
                  tabIndex="0"
                >
                  <ContactTaskTab
                    contactData={contactData}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    setMessageType={setMessageType}
                  />
                </div>
                <div
                  className="tab-pane fade"
                  id="mail-tab-pane"
                  role="tabpanel"
                  aria-labelledby="mail-tab"
                  tabIndex="0"
                >
                  <ConversationTab
                    contactData={contactData}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    setMessageType={setMessageType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </>
  );
};

export default ContactDetailsBody;
