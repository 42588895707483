/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData, postData } from "Utils/Gateway";

import ModuleOptions from "data/Prod/ModuleListOptions.json";

import { reactSelectStyle } from "Config/Config";

const UpdateSurveyModal = ({
  isEdit,
  setIsEdit,
  selectedSurveyId,
  setSelectedSurveyId,
  afterModalClose,
  setAlertMessage,
  setMessageType,
  setShowAlert,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); //for translation

  const [name, setname] = useState("");
  const [code, setcode] = useState("");
  const [questionIds, setquestionIds] = useState([]);

  const [moduleValue, setModuleValue] = useState(null);
  const [moduleSlug, setModuleSlug] = useState("");
  const [componentInput, setComponentInput] = useState("");
  const [componentList, setComponentList] = useState([]);
  const [componentValue, setComponentValue] = useState(null);
  const [selectedUser, setselectedUser] = useState(null);
  const [selectedContact, setselectedContact] = useState(null);
  const [selectedEvent, setselectedEvent] = useState(null);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedChallenge, setselectedChallenge] = useState(null);
  const [selectedCourse, setselectedCourse] = useState(null);

  const [isPosting, setIsPosting] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [codeError, setCodeError] = useState(false);

  const validationHandler = () => {
    let isValid = true;

    if (name.trim() === "") {
      setNameError(true);
      isValid = false;
    } else {
      setNameError(false);
    }

    if (code.trim() === "") {
      setCodeError(true);
      isValid = false;
    } else {
      setCodeError(false);
    }

    return isValid;
  };

  //function for get survey details
  const getSurveyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_DETAILS +
        `/${selectedSurveyId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setname(response.data.name);
        setcode(response.data.code);
        setquestionIds(response.data.questionids);

        if (response.data?.moduleslug && response.data?.moduleslug !== "") {
          for (let moduleData of ModuleOptions) {
            if (moduleData.value === response.data?.moduleslug) {
              moduleSelectionHandler(moduleData);

              if (response.data.componentid) {
                let componentValue = {
                  label: response.data.componenttitle,
                  value: response.data.componentid,
                };

                componentSelectionHandler(componentValue, moduleData.value);
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select module
  const moduleSelectionHandler = (val) => {
    if (val) {
      setModuleValue(val);
      setModuleSlug(val.value);
      if (val.value === "MOD_USER") {
        const userOption = [
          {
            label: `${userInfo.name} ${userInfo.surname}`,
            value: userInfo._id,
          },
        ];

        setComponentList(userOption);
      }
    } else {
      setModuleValue(null);
      setModuleSlug(val);
      setComponentInput("");
      setComponentList([]);
      setComponentValue(null);
      setselectedUser(null);
      setselectedContact(null);
      setselectedEvent(null);
      setselectedGroup(null);
      setselectedChallenge(null);
      setselectedCourse(null);
    }
  };

  //get componentlist
  const getComponentOptions = async () => {
    try {
      let requestUrl = url.API_BASE_URL;

      if (moduleSlug === "MOD_CONTACT") {
        requestUrl +=
          url.API_GET_USER_CONTACT_LIST_OPTIONS +
          `?token=${token}&userstring=${componentInput}`;
      }

      if (moduleSlug === "MOD_EVENT") {
        requestUrl +=
          url.API_GET_EVENT_LIST_OPTIONS +
          `?token=${token}&eventtitle=${componentInput}`;
      }

      if (moduleSlug === "MOD_GROUP") {
        requestUrl +=
          url.API_GET_ALL_GROUP_OPTIONS +
          `?token=${token}&groupstring=${componentInput}`;
      }

      if (moduleSlug === "MOD_CHALLENGE") {
        requestUrl +=
          url.API_GET_CHALLENGE_LIST_FOR_OPTIONS +
          `?token=${token}&filtertitle=${componentInput}`;
      }

      if (moduleSlug === "MOD_COURSE") {
        requestUrl +=
          url.API_GET_COURSE_LIST_FOR_OPTIONS +
          `?token=${token}&coursestring=${componentInput}`;
      }

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setComponentList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select component
  const componentSelectionHandler = (val, moduleSelectedSlug = "") => {
    console.log("Run function");
    if (val) {
      setComponentValue(val);

      if (moduleSelectedSlug === "MOD_USER") {
        setselectedUser(val.value);
      }

      if (moduleSelectedSlug === "MOD_CONTACT") {
        setselectedContact(val.value);
      }

      if (moduleSelectedSlug === "MOD_EVENT") {
        setselectedEvent(val.value);
      }

      if (moduleSelectedSlug === "MOD_GROUP") {
        setselectedGroup(val.value);
      }

      if (moduleSelectedSlug === "MOD_CHALLENGE") {
        setselectedChallenge(val.value);
      }

      if (moduleSelectedSlug === "MOD_COURSE") {
        setselectedCourse(val.value);
      }
    }
  };

  //function for add new survey
  const saveSurveyHandler = async () => {
    if (validationHandler()) {
      try {
        setIsPosting(true);

        let surveyData = {
          moduleslug: moduleSlug,
          name,
          code,
          user: null, // user means survey about this user.Must be an app user
          lead: null,
          contact: null,
          event: null,
          group: null,
          course: null,
          challenge: null,
        };

        if (moduleSlug === "MOD_USER") {
          surveyData.user = selectedUser;
        }

        if (moduleSlug === "MOD_CONTACT") {
          surveyData.contact = selectedContact;
        }

        if (moduleSlug === "MOD_EVENT") {
          surveyData.event = selectedEvent;
        }

        if (moduleSlug === "MOD_GROUP") {
          surveyData.group = selectedGroup;
        }

        if (moduleSlug === "MOD_CHALLENGE") {
          surveyData.challenge = selectedChallenge;
        }

        if (moduleSlug === "MOD_COURSE") {
          surveyData.course = selectedCourse;
        }

        console.log(surveyData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (isEdit) {
          requestUrl +=
            url.API_UPDATE_SURVEY + `/${selectedSurveyId}` + `?token=${token}`;
          response = await putData(requestUrl, surveyData);
        } else {
          surveyData.questions = questionIds;

          requestUrl += url.API_ADD_NEW_SURVEY + `?token=${token}`;
          response = await postData(requestUrl, surveyData);
        }

        console.log(response);

        setIsPosting(false);
        if (response.status) {
          //hide survey modal
          let bootstrapModal = document.querySelector("#copyUpdateSurveyModal");
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();

          setMessageType("success");
          resetHandler();
          afterModalClose();
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    }
  };

  //function for reset
  const resetHandler = () => {
    setname("");
    setcode("");
    setNameError(false);
    setCodeError(false);
    setModuleValue(null);
    setModuleSlug("");
    setComponentInput("");
    setComponentList([]);
    setComponentValue(null);
    setselectedUser(null);
    setselectedContact(null);
    setselectedEvent(null);
    setselectedGroup(null);
    setselectedChallenge(null);
    setselectedCourse(null);
    setIsEdit(false);
    setSelectedSurveyId(null);
  };

  useEffect(() => {
    if (selectedSurveyId) {
      getSurveyDetails();
    }
  }, [selectedSurveyId]);

  useEffect(() => {
    if (moduleValue && moduleValue.value === "MOD_USER") {
      componentSelectionHandler(
        {
          label: `${userInfo.name} ${userInfo.surname}`,
          value: userInfo._id,
        },
        "MOD_USER"
      );
    }
  }, [moduleValue]);

  useEffect(() => {
    if (moduleSlug !== "" && componentInput !== "") {
      getComponentOptions();
    }
  }, [moduleSlug, componentInput]);

  return (
    <div
      className="modal fade"
      id="copyUpdateSurveyModal"
      tabIndex="-1"
      aria-labelledby="copyUpdateSurveyModal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Save Survey")}
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- user select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="name"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Survey name")}
                </label>
                <input
                  className="form-control fs-sm shadow-none"
                  value={name}
                  onChange={(e) => {
                    setNameError(false);
                    setname(e.target.value);
                  }}
                />

                {nameError && (
                  <p className="fw-bold text-danger">
                    * {t("Please enter survey name")}
                  </p>
                )}
              </div>
              {/* ----- user select section end ----- */}

              {/* ----- role select section start ----- */}
              <div className="form-group mb-4">
                <label
                  htmlFor="Code"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Survey Code")}
                </label>
                <input
                  className="form-control fs-sm shadow-none"
                  value={code}
                  onChange={(e) => {
                    setCodeError(false);
                    setcode(e.target.value);
                  }}
                />
                {codeError && (
                  <p className="fw-bold text-danger">
                    * {t("Please enter survey code")}
                  </p>
                )}
              </div>
              {/* ----- role select section end ----- */}

              {/* <!-- ========== Start module select Section ========== --> */}
              <div className="form-group mb-4">
                <label
                  htmlFor="Code"
                  className="d-block fs-sm fw-semibold mb-2"
                >
                  {t("Module")}
                </label>
                <Select
                  styles={reactSelectStyle("6px", "4px")}
                  components={{ IndicatorSeparator: () => null }}
                  isClearable
                  options={ModuleOptions}
                  placeholder={t("Select Module")}
                  value={moduleValue}
                  onChange={(val) => {
                    moduleSelectionHandler(val);
                  }}
                />
              </div>
              {/* <!-- ========== End module select Section ========== --> */}

              {/* <!-- ========== Start component Section ========== --> */}
              {moduleValue ? (
                <div className="form-group mb-4">
                  <label
                    htmlFor="Code"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Component")}
                  </label>
                  <Select
                    styles={reactSelectStyle("6px", "4px")}
                    components={{ IndicatorSeparator: () => null }}
                    isClearable
                    placeholder={t("Search by name,email")}
                    options={componentList}
                    value={componentValue}
                    onChange={(val) => {
                      componentSelectionHandler(val, moduleSlug);
                    }}
                    onInputChange={(val) => {
                      if (moduleSlug !== "MOD_USER") {
                        setComponentInput(val);
                      }
                    }}
                  />
                </div>
              ) : null}
              {/* <!-- ========== End component Section ========== --> */}

              {/* ----- button section start ----- */}
              <div className="action d-flex align-items-center gap-3">
                <Link
                  to="#"
                  className="btn btn-secondary rounded-0 shadow-none"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  // onClick={resetHandler}
                >
                  {t("Cancel")}
                </Link>

                <button
                  type="button"
                  className="btn btn-info rounded-0 shadow-none ms-auto"
                  onClick={saveSurveyHandler}
                  disabled={isPosting ? true : false}
                  style={{
                    cursor: isPosting ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save")}
                  {isPosting && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* ----- button section end ----- */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSurveyModal;
