import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";

const GroupListHeader = ({
  refreshHandler = () => {},
  deleteHandler = () => {},
  updateBulkHandler = () => {},
  breadCrumbText = [],
}) => {
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        <div className="col-md-2 col-lg-3 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadCrumbText} />
        </div>

        <div className="col-md-10 col-lg-9 d-flex gap-2 justify-content-end">
          {/* <a
          className="btn border-0 rounded-0 shadow-none me-1 "
          href="javascript:moduleTour();"
        >
          <i className="material-icons">tour</i> Help
        </a> */}

          <button
            type="button"
            className="btn btn-secondary rounded-0 shadow-none"
            onClick={refreshHandler}
          >
            <i className="material-icons">refresh</i>
          </button>

          <button
            type="button"
            className="btn btn-info rounded-0 shadow-none"
            id="module-filter"
            data-bs-toggle="modal"
            data-bs-target="#groupfilterModal"
          >
            <i className="material-icons">filter_alt</i>
          </button>

          <Link
            to="/admin/group/create"
            className="btn btn-info d-flex align-items-center rounded-0 shadow-none"
            id="add-new-item"
          >
            <i className="material-icons">add</i> Add New
          </Link>

          <div className="dropdown" id="module-mass-action">
            <button
              className="btn btn-secondary rounded-0 shadow-none dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Action
            </button>
            <ul className="dropdown-menu dropdown-menu-end rounded-0 border-0">
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="modal"
                  data-bs-target="#addTagModal"
                >
                  Add Label
                </Link>
              </li>

              <li>
                <Link to="#" className="dropdown-item" onClick={deleteHandler}>
                  Delete Selected
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="dropdown-item"
                  onClick={updateBulkHandler}
                >
                  Update rank
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupListHeader;
