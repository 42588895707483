/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const SaveQuestionModal = ({
  questionList,
  questionTypes,
  afterPopupClose,
  selectedQuestionId,
  setSelectedQuestionId,
}) => {
  const token = localStorage.getItem("token");
  const params = useParams();
  const { t } = useTranslation(); //for translation
  /* question type selected  */
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [questionTypeSelectedValue, setQuestionTypeSelectedValue] =
    useState(null);
  const [showOptionalAnswerBlock, setShowOptionalAnswerBlock] = useState(false);
  const [code, setcode] = useState("");
  const [question, setQuestion] = useState("");
  const [correctAnswer, setcorrectAnswer] = useState("");
  const [order, setorder] = useState(1);

  // options answers
  const [answerOptions, setAnswerOptions] = useState([]);
  const [optionalAnswer, setOptionalAnswer] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  /* hooks for validation */
  const [validation, setValidation] = useState({
    typeWarning: false,
    questionWarning: false,
    codeWarning: false,
    uniqueCodeWarning: false,
    optionsWarning: false,
  });

  //function for get question details
  const getquestionDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_QUESTION_DETAILS +
        `/${selectedQuestionId}?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        const data = response.data;
        questionTypeSelectionHandler(data.questiontype);
        setcode(data.code);
        setQuestion(data.name);
        setorder(data.rank);
        setcorrectAnswer(data.correctanswer);
        optionalAnswerHandler(data.answers);
      }

      console.log(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select question type
  const questionTypeSelectionHandler = (val) => {
    setQuestionTypeSelectedValue(val);
    setSelectedQuestionType(val.value);

    setValidation((prevState) => ({
      ...prevState,
      typeWarning: false,
    }));

    if (val.label == "Single Select" || val.label == "Multi Select") {
      setShowOptionalAnswerBlock(true);
    } else {
      setShowOptionalAnswerBlock(false);
    }
  };

  //function for select optional answer
  const optionalAnswerHandler = (val) => {
    setOptionalAnswer(val);
    const answerList = val.map((answer) => answer.value);
    setAnswerOptions(answerList);
  };

  // function for check validation
  const validate = () => {
    let isValid = true;

    if (questionTypeSelectedValue === null) {
      setValidation((prevState) => ({ ...prevState, typeWarning: true }));
      isValid = false;
    }

    if (question === "") {
      setValidation((prevState) => ({
        ...prevState,
        questionWarning: true,
      }));
      isValid = false;
    }

    if (code === "") {
      setValidation((prevState) => ({ ...prevState, codeWarning: true }));
      isValid = false;
    }

    if (code !== "") {
      if (selectedQuestionId) {
        const selectedQuestion = questionList.find(
          (question) => question._id === selectedQuestionId
        );

        if (selectedQuestion) {
          if (selectedQuestion.code === code) {
            isValid = true;
          } else {
            const isDuplicate = questionList.some(
              (question) =>
                question._id !== selectedQuestionId && question.code === code
            );

            if (isDuplicate) {
              setValidation((prevState) => ({
                ...prevState,
                uniqueCodeWarning: true,
              }));
              isValid = false;
            }
          }
        }
      } else {
        const notUniqueCode = questionList.filter((question) => {
          return question.code === code;
        });

        if (notUniqueCode.length > 0) {
          setValidation((prevState) => ({
            ...prevState,
            uniqueCodeWarning: true,
          }));
          isValid = false;
        }
      }
    }

    if (questionTypeSelectedValue) {
      if (
        questionTypeSelectedValue.label == "Single Select" ||
        questionTypeSelectedValue.label == "Multi Select"
      ) {
        if (answerOptions.length === 0) {
          setValidation((prevState) => ({
            ...prevState,
            optionsWarning: true,
          }));
          isValid = false;
        }
      }
    }

    return isValid;
  };

  //function for reset
  const resetHandler = () => {
    setQuestion("");
    setcode("");
    setcorrectAnswer("");
    setorder(1);
    setSelectedQuestionType(null);
    setQuestionTypeSelectedValue(null);
    setAnswerOptions([]);
    setOptionalAnswer(null);
    setShowOptionalAnswerBlock(false);

    setValidation({
      typeWarning: false,
      questionWarning: false,
      codeWarning: false,
      uniqueCodeWarning: false,
      optionsWarning: false,
    });

    setSelectedQuestionId(null);
  };

  //function for save question
  const saveQuestionHandler = async () => {
    if (validate()) {
      setIsSaving(true);

      try {
        let questionData = {
          questiontype: selectedQuestionType,
          surveyid: params.id,
          code,
          name: question,
          correctanswer: correctAnswer,
          answers: answerOptions,
          rank: order,
        };
        console.log("Question data=======>", questionData);

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (selectedQuestionId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_QUESTION +
            `/${selectedQuestionId}` +
            `?token=${token}`;

          response = await putData(requestUrl, questionData);
        } else {
          requestUrl =
            requestUrl + url.API_ADD_QUESTION_TO_SURVEY + `?token=${token}`;

          response = await postData(requestUrl, questionData);
        }

        setIsSaving(false);

        if (response.status) {
          resetHandler();
          afterPopupClose();

          //hide survey modal
          let bootstrapModal = document.querySelector(
            "#saveSurveyQuestionModal"
          );
          let modal = bootstrap.Modal.getInstance(bootstrapModal);
          modal.hide();
        }
      } catch (error) {
        console.log("error", error.message);
      }
    }
  };

  useEffect(() => {
    if (selectedQuestionId) {
      getquestionDetails();
    }
  }, [selectedQuestionId]);

  return (
    <div
      className="modal fade"
      id="saveSurveyQuestionModal"
      tabIndex="-1"
      aria-labelledby="saveSurveyQuestionModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addMemberLabel">
              {t("Save Survey")}
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form-group mb-3 mb-md-4">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Question Type")}
                </label>
                <Select
                  styles={reactSelectStyle("6px", "4px")}
                  components={{ IndicatorSeparator: () => null }}
                  placeholder={t("Select question type")}
                  options={questionTypes}
                  value={questionTypeSelectedValue}
                  onChange={(val) => questionTypeSelectionHandler(val)}
                />
                {/* type warning */}
                {validation.typeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter question type")}!</span>
                    </p>
                  </div>
                )}
              </div>
              <div className="form-group mb-3 mb-md-4">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Code")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter")}
                  value={code}
                  onChange={(e) => {
                    setcode(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      codeWarning: false,
                      uniqueCodeWarning: false,
                    }));
                  }}
                />
                {validation.codeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter code")}!</span>
                    </p>
                  </div>
                )}

                {validation.uniqueCodeWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>
                        {t(
                          "Sorry ! This code already exist. Please enter different code"
                        )}
                        !
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className="form-group mb-3 mb-md-4">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Question to be asked")}
                </label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter")}
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      questionWarning: false,
                    }));
                  }}
                ></textarea>
                {/* type warning */}
                {validation.questionWarning && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter question")}!</span>
                    </p>
                  </div>
                )}
              </div>

              {showOptionalAnswerBlock ? (
                <div className="form-group mb-3 mb-md-4">
                  <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                    Optional Answers
                  </label>
                  <CreatableSelect
                    styles={reactSelectStyle("6px", "4px")}
                    components={{ IndicatorSeparator: () => null }}
                    isClearable
                    className="nosearch_select"
                    isMulti
                    value={optionalAnswer}
                    onChange={(val) => optionalAnswerHandler(val)}
                  />
                  {validation.optionsWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please type options or answers")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              ) : null}

              <div className="form-group mb-3 mb-md-4">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Correct Answers")}
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter")}
                  value={correctAnswer}
                  onChange={(e) => setcorrectAnswer(e.target.value)}
                />
              </div>

              <div className="form-group mb-3 mb-md-4">
                <label htmlFor="" className="d-block fs-sm fw-semibold mb-2">
                  {t("Order")}
                </label>
                <input
                  type="number"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Enter")}
                  value={order}
                  onChange={(e) => setorder(e.target.value)}
                />
              </div>

              <div className="action">
                <button
                  onClick={saveQuestionHandler}
                  type="button"
                  className="btn btn-info rounded-0 shadow-none fs-sm fw-normal"
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Submit")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveQuestionModal;
