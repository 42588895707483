/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const SurveyInviteListFilterModal = ({
  setfilterStartDate,
  setfilterEndDate,
  setfilterName,
  setfilterEmail,
  setfilterTags,
}) => {
  const { t } = useTranslation(); //for translation

  //get token form localstorage
  const token = localStorage.getItem("token");

  const [tagList, setTagList] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [tags, setTags] = useState([]);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const [errorMessage, seterrorMessage] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }
    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select event tags
  const tagSelectionHandler = (data) => {
    settagValue(data);
    setTags(data.map((item) => item.value));
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");
      if (startDate !== "" && endDate !== "") {
        setfilterStartDate(startDate);
        setfilterEndDate(endDate);
      } else {
        setfilterStartDate("");
        setfilterEndDate("");
      }

      if (fullName !== "") {
        setfilterName(fullName);
      } else {
        setfilterName(fullName);
      }

      if (email !== "") {
        setfilterEmail(email);
      } else {
        setfilterEmail("");
      }

      if (tags.length > 0) {
        setfilterTags(tags);
      } else {
        setfilterTags([]);
      }

      resetHandler();

      // close the modal
      let currentModal = document.querySelector("#filterSurveyInviteModal");
      let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
      bootstrapModal.hide();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //function for reset all states
  const resetHandler = () => {
    setStartDate("");
    setEndDate("");
    settagValue(null);
    setTags([]);
    setFullName("");
    setEmail("");
    seterrorMessage("");
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="modal fade"
      id="filterSurveyInviteModal"
      aria-labelledby="filterSurveyInviteModalLabel"
      data-bs-backdrop="static"
      data-bs-keyword="false"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0 mb-2">
            <h2 className="text-green w-100" id="eventfilterModalLabel">
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetHandler}
            ></button>
          </div>
          <div
            className="modal-body p-4"
            data-select2-id="select2-data-14-jpb5"
          >
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row mb-3">
                <div className="col-6">
                  <label htmlFor="startDate" className="fw-bold mb-2">
                    From Date
                  </label>
                  <input
                    type="date"
                    name="fromdate"
                    id="startDate"
                    className="form-control text-green rounded-0"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                      seterrorMessage("");
                    }}
                  />
                </div>

                <div className="col-6">
                  <label htmlFor="endDate" className="fw-bold mb-2">
                    To Date
                  </label>
                  <input
                    type="date"
                    name="todate"
                    id="endDate"
                    className="form-control text-green rounded-0"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        dateWarning: false,
                      }));
                      seterrorMessage("");
                    }}
                  />
                </div>

                {validation.dateWarning && (
                  <div className="error-message mt-3">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-symbols-outlined">warning</span>
                      <span>Please enter end date!</span>
                    </p>
                  </div>
                )}
              </div>

              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  {t("Name")}
                </label>
                <input
                  type="text"
                  id="fname"
                  name="query"
                  placeholder="Name"
                  className="form-control text-green rounded-0"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="name" className="fw-bold mb-2">
                  {t("Email")}
                </label>
                <input
                  type="text"
                  id="fname"
                  name="query"
                  placeholder="Email"
                  className="form-control text-green rounded-0"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="form-row mb-3">
                <label htmlFor="status" className="fw-bold mb-2">
                  labels
                </label>
                <Select
                  styles={reactSelectStyle("6px")}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  isMulti
                  placeholder={t("Select tags")}
                  options={tagList}
                  value={tagValue}
                  onChange={(val) => {
                    tagSelectionHandler(val);
                  }}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={searchHandler}
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyInviteListFilterModal;
