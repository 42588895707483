/* eslint-disable */
import { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";
import publishOptions from "data/Prod/PublishStatusOptions.json";

const ChallengeListFilterModal = ({
  setfilterRank,
  setFilterStartDate,
  setFilterEndDate,
  setFilterTitle,
  setFilterLocation,
  setFilterSkills,
  setFilterTags,
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [tagList, setTagList] = useState([]);
  const [skillList, setskillList] = useState([]);

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [title, settitle] = useState("");
  const [location, setLocation] = useState("");
  const [publishValue, setpublishValue] = useState(null);
  const [selectedPublished, setselectedPublished] = useState("");
  const [skillValue, setskillValue] = useState(null);
  const [selectedSkills, setselectedSkills] = useState([]);
  const [tagValue, settagValue] = useState(null);
  const [tags, setTags] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    endDateSmallerWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true; // Initialize the validity flag

    // Check if the start date is provided but the end date is missing
    if (startDate === "" && endDate !== "") {
      setValidation((prevState) => ({ ...prevState, startDateWarning: true }));
      // Indicate that the validation has failed
      isValid = false;
    }
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, endDateWarning: true }));

      isValid = false;
    }

    if (endDate !== "" && endDate < startDate) {
      setValidation((prevState) => ({
        ...prevState,
        endDateSmallerWarning: true,
      }));
      isValid = false;
    }
    // Return the validity flag
    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all skills
  const getAllSkillList = async () => {
    try {
      const parentSlug = "CAT_SKILL";
      const response = await getAllCategories(parentSlug);
      if (response) {
        setskillList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select event tags
  const tagSelectionHandler = (data) => {
    settagValue(data);
    setTags(data.map((item) => item.value));
  };

  //function for select publish
  const publishSelectionHandler = (val) => {
    if (val) {
      setpublishValue(val);
      setselectedPublished(val.value);
    } else {
      setpublishValue(null);
      setselectedPublished("");
    }
  };

  //function for select skills
  const skillSelectionHandler = (val) => {
    if (val) {
      setskillValue(val);
      setselectedSkills(val.map((item) => item.value));
    } else {
      setskillValue(null);
      setselectedSkills([]);
    }
  };

  const applyFilterHandler = () => {
    if (validationHandler()) {
      setFilterStartDate(startDate);
      setFilterEndDate(endDate);
      setFilterTitle(title);
      setFilterLocation(location);
      setFilterSkills(selectedSkills);
      setfilterRank(selectedPublished);
      setFilterTags(tags);

      resetHandler();

      const filterModal = document.querySelector("#challengelistfilterModal");

      const modal = bootstrap.Modal.getInstance(filterModal);
      modal.hide();
    }
  };

  const resetHandler = () => {
    setstartDate("");
    setendDate("");
    settitle("");
    setLocation("");
    setpublishValue(null);
    setselectedPublished("");
    setskillValue(null);
    setselectedSkills([]);
    settagValue(null);
    setTags([]);

    setValidation({
      startDateWarning: false,
      endDateWarning: false,
      endDateSmallerWarning: false,
    });
  };

  useEffect(() => {
    getAllTags();
    getAllSkillList();
  }, []);

  return (
    <div
      className="modal fade"
      id="challengelistfilterModal"
      aria-labelledby="challengelistfilterModalLabel"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2
              className="text-green w-100 mb-2"
              id="challengelistfilterModalLabel"
            >
              Filter
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body p-4 py-1 mt-2">
            <div className="form-row mb-3">
              <label htmlFor="language" className="fw-bold mb-2">
                {t("Status")}
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("Select Status")}
                options={publishOptions}
                value={publishValue}
                onChange={(val) => {
                  publishSelectionHandler(val);
                }}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="name" className="fw-bold mb-2">
                {t("Query / Title")}
              </label>
              <input
                type="text"
                id="fname"
                name="query"
                placeholder={t("Enter Query / Title")}
                className="form-control text-green rounded-0"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="name" className="fw-bold mb-2">
                {t("Location")}
              </label>
              <input
                type="text"
                id="fname"
                name="query"
                placeholder={t("Enter Location")}
                className="form-control text-green rounded-0"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            <div className="form-row mb-3">
              <label htmlFor="searching" className="fw-bold mb-2">
                {t("Skills")}
              </label>
              <Select
                isMulti
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                placeholder={t("Select Skills")}
                options={skillList}
                value={skillValue}
                onChange={(val) => {
                  skillSelectionHandler(val);
                }}
              />
            </div>

            {/* tags  */}
            <div className="form-row mb-3">
              <label htmlFor="tags" className="fw-bold mb-2">
                Labels
              </label>
              <Select
                styles={reactSelectStyle("6px")}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isMulti
                placeholder={t("Select tags")}
                options={tagList}
                value={tagValue}
                onChange={(val) => {
                  tagSelectionHandler(val);
                }}
              />
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="startDate" className="fw-bold mb-2">
                  {t("From Date")}
                </label>
                <input
                  type="date"
                  name="fromdate"
                  id="startDate"
                  className="form-control text-green rounded-0"
                  value={startDate}
                  onChange={(e) => {
                    setstartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                  }}
                />
              </div>

              <div className="col-6">
                <label htmlFor="endDate" className="fw-bold mb-2">
                  {t("To Date")}
                </label>
                <input
                  type="date"
                  name="todate"
                  id="endDate"
                  className="form-control text-green rounded-0"
                  value={endDate}
                  onChange={(e) => {
                    setendDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                  }}
                />
              </div>
            </div>

            {validation.endDateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-icons-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
            {validation.startDateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-icons-outlined">warning</span>
                  <span>Please enter start date!</span>
                </p>
              </div>
            )}
            {validation.endDateSmallerWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-icons-outlined">warning</span>
                  <span>End date should not be greater than start date!</span>
                </p>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-yellow text-justify  w-100"
              onClick={applyFilterHandler}
            >
              {t("Filter")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeListFilterModal;
