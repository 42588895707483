/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CrmCommonLayout from "Components/Crm/Common/CrmCommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import AppUserListHeader from "../Header/AppUserListHeader";
import AppUserListFilterModal from "../Modal/AppUserListFilterModal";
import Loading from "Components/Common/Loading/Loading";
import AddToListModal from "Components/Common/Modal/AddToListModal";
import AddTagModal from "Components/Common/Modal/AddTagModal";
import CreateEmailPopup from "Components/Common/Popup/CreateEmailPopup";

const AppUserListBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  /* ---- bread crumb text ---- */
  const breadCrumbText = [{ title: t("CRM") }, { title: t("Users") }];

  const [roleList, setRoleList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);

  //filter requirements
  const [filterName, setfilterName] = useState("");
  const [filterEmail, setfilterEmail] = useState("");
  const [filterPhone, setfilterPhone] = useState("");
  const [filterPostion, setfilterPostion] = useState("");
  const [filterCompany, setfilterCompany] = useState("");
  const [filterLabels, setfilterLabels] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserEmails, setSelectedUserEmails] = useState("");

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get user roles

  const getUserRoles = async (userId) => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_ROLES +
        `?token=${token}&filterfeatured=true`;

      const response = await getData(requestURL);

      if (response.status) {
        setRoleList(response.data);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setShowAlert(true);
      setAlertMessage(error.message);
      setMessageType("error");
    }
  };

  //function for getting all users
  const getAllAppUsers = async () => {
    setRowSelection({});
    setSelectedUserIds([]);
    setSelectedUserEmails("");

    setIsLoading(true);

    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_APP_USER +
        `?token=${token}&modulefrom=backend`;

      if (filterName != "") {
        requestURL = requestURL + `&filtername=${filterName}`;
      }

      if (filterEmail != "") {
        requestURL = requestURL + `&filteremail=${filterEmail}`;
      }

      if (filterPhone != "") {
        requestURL = requestURL + `&filterphone=${filterPhone}`;
      }

      if (filterPostion != "") {
        requestURL = requestURL + `&filterposition=${filterPostion}`;
      }

      if (filterCompany != "") {
        requestURL = requestURL + `&filtercompany=${filterCompany}`;
      }

      if (filterLabels.length > 0) {
        requestURL = requestURL + `&filterlabels=${filterLabels}`;
      }

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setUsers(response.data);
        resetFilterData();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedList = [...users];

    const rowIndex = updatedList.findIndex(
      (user) => user._id.toString() === row.original._id.toString()
    );

    if (rowIndex !== -1) {
      updatedList[rowIndex].rank = value;
      setUsers(updatedList);
    }
  };

  //function for bulk update
  const bulkUpdateHandler = async (userStatus = "1", rankOnly = false) => {
    if (selectedUserIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = users.filter((item) =>
          selectedUserIds.includes(item._id)
        );

        let updatedData = {
          userlist: listToBeUpdated,
        };

        if (!rankOnly) {
          updatedData.status = userStatus;
        }

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_BULK_USER + `?token=${token}`;

        const response = await putData(requestURL, updatedData);

        setIsUpdating(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllAppUsers();
          }, 1000);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one user.");
    }
    setShowAlert(true);
  };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedUserIds([]);
    setSelectedUserEmails("");
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterEmail("");
    setfilterPhone("");
    setfilterPostion("");
    setfilterCompany("");
    setfilterLabels([]);
  };

  useEffect(() => {
    getAllAppUsers();
    getUserRoles();
  }, []);

  useEffect(() => {
    if (
      filterName != "" ||
      filterEmail != "" ||
      filterPhone != "" ||
      filterPostion != "" ||
      filterCompany != "" ||
      filterLabels.length > 0
    ) {
      getAllAppUsers();
    }
  }, [
    filterName,
    filterEmail,
    filterPostion,
    filterCompany,
    filterPhone,
    filterLabels,
  ]);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "username",
      header: t("Name"),
      size: 300,
      Cell: ({ row }) => (
        <div className="user d-flex align-items-center">
          <div className="profile d-flex align-items-center">
            <div className="img-container me-2">
              <Link to="#">
                <img
                  height={35}
                  width={35}
                  src={
                    row.original.userimage === ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.userimage
                  }
                  className="img-fluid lead-img rounded-circle"
                />
              </Link>
            </div>

            <p className="name">
              <span
                className={row.original.status === "0" ? "text-danger" : ""}
              >
                {row.original.username}
              </span>

              {row.original.status === "0" ? (
                <span className="ms-1 material-icons-outlined text-warning">
                  error_outline
                </span>
              ) : row.original.connectstatus ? (
                <span className="ms-1 material-icons-outlined text-success">
                  how_to_reg
                </span>
              ) : null}
            </p>
          </div>
        </div>
      ),
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      size: 100,
      Cell: ({ row }) => (
        <input
          type="text"
          className="form-control rounded-0 shadow-none bg-white"
          value={row.original.rank}
          onChange={(e) => rankInputChangeHandler(row, e.target.value)}
        />
      ),
    },
    {
      accessorKey: "email",
      header: t("Email"),
      size: 300,
    },
    {
      accessorKey: "userrole",
      header: t("Role"),
    },
    {
      accessorKey: "position",
      header: t("Position"),
      size: 250,
    },
    {
      accessorKey: "company",
      header: t("Company"),
    },
    {
      accessorKey: "phone",
      header: t("Phone"),
    },
    {
      accessorKey: "city",
      header: t("City"),
    },
    {
      accessorKey: "country",
      header: t("Country"),
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      size: 200,
    },
    {
      accessorKey: "registereddate",
      header: t("Date"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [users, i18n.language]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      const selectUserIds = selectedIdsArray.map((id) => {
        const [userId] = id.split("-");
        return userId;
      });
      const selectUserEmails = selectedIdsArray.map((id) => {
        const [, userEmail] = id.split("-");
        return userEmail;
      });
      setSelectedUserIds(selectUserIds);
      const selectUserEmailString = selectUserEmails.join(",");
      setSelectedUserEmails(selectUserEmailString);
    } else {
      setSelectedUserIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CrmCommonLayout moduleName="appUsers">
      <AppUserListHeader
        breadCrumbText={breadCrumbText}
        reloadData={getAllAppUsers}
        bulkUpdateHandler={bulkUpdateHandler}
      />

      {isLoading ? (
        <div className="placeholder-glow d-flex flex-column gap-4">
          <span className="placeholder placeholder-lg bg-secondary col-12"></span>
          <span className="placeholder placeholder-lg bg-secondary col-8"></span>
          <span className="placeholder placeholder-lg bg-secondary col-4"></span>
        </div>
      ) : (
        <div className="table-wrapper">
          <MaterialReactTable
            columns={columns} // map columns to be displayed with api data,
            data={users} // data from api to be displayed
            enableGrouping // to enable grouping of column
            enableRowSelection // enable showing checkbox
            getRowId={(row) => `${row._id}-${row.email}`} // map which value to select with row checkbox
            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
            state={{
              rowSelection,
            }} //pass our managed row selection state to the table to use
            initialState={{
              rowSelection,
            }}
            enableRowActions // show the action column
            enableColumnOrdering={true}
            renderRowActionMenuItems={({ row }) => {
              const [appUserId] = row.id.split("-");

              const menuItems = [
                <MenuItem key="edit">
                  <Link
                    className="dropdown-item"
                    to={`/admin/userdetails/manage/personalinfo/${appUserId}`}
                  >
                    {t("Manage Profile")}
                  </Link>
                </MenuItem>,
                <MenuItem key="cv">
                  <Link
                    className="dropdown-item"
                    to={`/admin/userdetails/manage/experience/${appUserId}`}
                  >
                    {t("Manage Experiences")}
                  </Link>
                </MenuItem>,
                <MenuItem key="survey">
                  <Link
                    className="dropdown-item"
                    to={`/admin/userdetails/manage/surveys/${appUserId}`}
                  >
                    {t("Manage Surveys")}
                  </Link>
                </MenuItem>,
                <MenuItem key="course">
                  <Link
                    className="dropdown-item"
                    to={`/admin/userdetails/manage/courses/${appUserId}`}
                  >
                    {t("Manage Courses")}
                  </Link>
                </MenuItem>,
                <MenuItem key="password">
                  <Link
                    className="dropdown-item"
                    to={`/admin/userdetails/manage/password/${appUserId}`}
                  >
                    {t("Manage Password")}
                  </Link>
                </MenuItem>,
                <MenuItem key="privacy">
                  <Link
                    className="dropdown-item"
                    to={`/admin/userdetails/manage/privacy/${appUserId}`}
                  >
                    {t("Manage Privacy")}
                  </Link>
                </MenuItem>,
              ];

              return menuItems;
            }} // action columns menu items
            //   onColumnVisibilityChange={onColumnVisiblityHandler}
            //   onColumnOrderChange={changeColumnOrderHandler}
            muiTableContainerProps={{
              sx: {
                maxHeight: "60vh",
              },
            }}
            enableStickyHeader
            muiSearchTextFieldProps={{
              placeholder: t("Search by name / email / phone / location"),
            }}
          />
        </div>
      )}

      <AppUserListFilterModal
        roleList={roleList}
        setfilterName={setfilterName}
        setfilterEmail={setfilterEmail}
        setfilterPhone={setfilterPhone}
        setfilterPostion={setfilterPostion}
        setfilterCompany={setfilterCompany}
        setfilterLabels={setfilterLabels}
      />

      <AddToListModal
        afterModalClose={() => {
          setTimeout(() => {
            getAllAppUsers();
          }, 2000);
        }}
        selectedIds={selectedUserIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {/* ---- new mail modal ------- */}
      <CreateEmailPopup
        contactPersonEmail={selectedUserEmails}
        reloadList={afterEmailClose}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <AddTagModal
        moduleName="appuser"
        selectedIds={selectedUserIds}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllAppUsers();
          }, 2000);
        }}
      />

      {isUpdating && <Loading />}

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CrmCommonLayout>
  );
};

export default AppUserListBody;
