const ChallengeMemberViewHeader = ({ reloadList = () => {} }) => {
  return (
    <div className="row align-items-center">
      <div className="col-sm-12">
        <div className="filter-container mb-3">
          <div className="d-flex flex-wrap justify-content-md-end align-items-center gap-2">
            <button
              type="button"
              className="btn btn-secondary rounded-0 shadow-none"
              onClick={reloadList}
            >
              <i className="material-icons">refresh</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeMemberViewHeader;
