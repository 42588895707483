/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import GroupDetailsCommonHeader from "../Common/GroupDetailsCommonHeader";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import GroupMemberHeader from "./Header/GroupMemberHeader";
import SaveGroupMemberModal from "./Modal/SaveGroupMemberModal";
import Loading from "Components/Common/Loading/Loading";
import AddTagModal from "Components/Common/Modal/AddTagModal";
import CreateEmailPopup from "Components/Common/Popup/CreateEmailPopup";

const GroupMemberBody = () => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: groupId } = useParams();

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Group"), link: "/admin/group/manage" },
    { title: "" },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const [selectedUserEmails, setSelectedUserEmails] = useState("");

  const [isUpdating, setIsUpdating] = useState(false);

  // -- Alert states --
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get group details
  const getgroupDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_GROUP_DETAILS +
        `/${groupId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        setBreadCrumbText([
          { title: t("Group"), link: "/admin/group/manage" },
          { title: response.data.title },
        ]);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all member
  const getAllGroupMembers = async () => {
    setIsLoading(true);
    setRowSelection({});
    setSelectedMemberIds([]);
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_GROUP_MEMBERS +
        `?token=${token}&group=${groupId}`;

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMemberList(response.data);
        setShowAlert(true);
        setMessageType("success");
        setAlertMessage(response.message);
      }
    } catch (error) {
      console.log(error.message);
      setShowAlert(true);
      setMessageType("error");
      setAlertMessage(error.message);
    }
  };

  //updateGroupMember bulk
  const updateGroupMemberBulk = async (moderatorstatus = "1") => {
    if (selectedMemberIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = memberList.filter((item) =>
          selectedMemberIds.includes(item._id)
        );

        let apiData = {
          groupmembers: listToBeUpdated,
          group: groupId,
          moderatorstatus,
        };

        let requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_MASSIVE_GROUP_MEMBER +
          `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllGroupMembers();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one memebr");
    }
    setShowAlert(true);
  };

  const afterEmailClose = () => {
    setRowSelection({});
    setSelectedMemberIds([]);
    setSelectedUserEmails("");
  };

  useEffect(() => {
    if (groupId) {
      getgroupDetails();
      getAllGroupMembers();
    }
  }, [groupId]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),

        Cell: ({ row }) => {
          if (row.original.role === "Moderator") {
            return (
              <button className="action_btn_mui">
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          } else {
            return (
              <button
                className="action_btn_mui"
                data-bs-toggle="modal"
                data-bs-target="#saveGroupMemberModal"
                aria-controls="saveGroupMemberModal"
                onClick={() => {
                  setSelectedMemberId(row.original._id);
                }}
              >
                <span className="d-block material-icons-outlined horz_icon">
                  more_horiz
                </span>
              </button>
            );
          }
        },
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "name",
        header: t("Member"),
        size: 300,
        Cell: ({ row }) => (
          <div className="member-profile d-flex align-items-center">
            <div className="img-container me-3">
              <Link to="#">
                <img
                  src={
                    row.original.image == ""
                      ? "/images/default-user.png"
                      : url.SERVER_URL + row.original.image
                  }
                  className="img-fluid rounded-circle"
                  width="40"
                  height="40"
                />
              </Link>
            </div>
            <div className="info">
              <h6 className="name fw-bold d-flex align-items-center mb-1">
                <Link to="" className="text-primary">
                  {row.original.name}
                </Link>
              </h6>
              <Link to="#" className="email text-gray-600">
                {row.original.email}
              </Link>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "role",
        header: t("Role"),
        size: 200,
      },
      {
        accessorKey: "moderatorstatus",
        header: t("Status"),
        size: 250,
        Cell: ({ row }) => (
          <div className="d-flex gap-3">
            {row.original.moderatorstatus == "" ? (
              <p>-</p>
            ) : (
              <>
                <div className="moderator d-flex align-items-center">
                  <i
                    className={`material-icons ${
                      row.original.moderatorstatus == "1"
                        ? "text-success"
                        : row.original.moderatorstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    } me-2`}
                  >
                    check_circle
                  </i>
                  <span
                    className={
                      row.original.moderatorstatus == "1"
                        ? "text-success"
                        : row.original.moderatorstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    }
                  >
                    {t("Moderator")}
                  </span>
                </div>

                <div className="member d-flex align-items-center text-gray-600">
                  <i
                    className={`material-icons ${
                      row.original.memberstatus == "1"
                        ? "text-success"
                        : row.original.memberstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    } me-2`}
                  >
                    check_circle
                  </i>
                  <span
                    className={
                      row.original.memberstatus == "1"
                        ? "text-success"
                        : row.original.memberstatus == "0"
                        ? "text-gray-600"
                        : "text-warning"
                    }
                  >
                    {t("Member")}
                  </span>
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        accessorKey: "requestdate",
        header: t("Date"),
        size: 200,
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        size: 200,
      },
    ],
    [memberList, i18n.language]
  );

  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  //optionally, you can manage the row selection state yourself

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      const selectMemberIds = selectedIdsArray.map((id) => {
        const [memberId] = id.split("-");
        return memberId;
      });
      const selectUserEmails = selectedIdsArray.map((id) => {
        const [, userEmail] = id.split("-");
        return userEmail;
      });

      setSelectedMemberIds(selectMemberIds);

      const selectUserEmailString = selectUserEmails.join(",");
      setSelectedUserEmails(selectUserEmailString);
    } else {
      setSelectedMemberIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      {groupId ? (
        <GroupDetailsCommonHeader groupId={groupId} moduleName="members" />
      ) : null}

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <GroupMemberHeader
            reloadList={getAllGroupMembers}
            updateGroupMember={updateGroupMemberBulk}
            breadCrumbText={breadCrumbText}
          />

          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper course-lessons-table">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={memberList} // data from api to be displayed
                positionActionsColumn="last"
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => `${row._id}-${row.email}`} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                defaultColumn={{
                  minSize: 20,
                  maxSize: 400,
                  size: 100, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by Member Name"),
                }}
              />
            </div>
          )}
        </div>

        <SaveGroupMemberModal
          afterCloseModalHandler={getAllGroupMembers}
          selectedMemberId={selectedMemberId}
          setSelectedMemberId={setSelectedMemberId}
        />

        <AddTagModal
          moduleName="groupmember"
          selectedIds={selectedMemberIds}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          afterTagModalClose={() => {
            setTimeout(() => {
              getAllGroupMembers();
            }, 2000);
          }}
        />

        {/* ---- new mail modal ------- */}
        <CreateEmailPopup
          contactPersonEmail={selectedUserEmails}
          reloadList={afterEmailClose}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {isUpdating && <Loading />}

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </CommonLayout>
  );
};

export default GroupMemberBody;
