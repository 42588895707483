/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

const UpdateProcessModal = ({
  selectedProcessId,
  setSelectedProcessId,
  processList,
  setProcessList,
  setShowAlert,
  setMessageType,
  setAlertMessage,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [processTitle, setProcessTitle] = useState("");
  const [processLocation, setProcessLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [processOrder, setProcessOrder] = useState(processList.length + 1);

  const [isSaving, setIsSaving] = useState(false);

  const [validation, setValidation] = useState({
    titleError: false,
  });

  //function for validation
  const validationHandler = () => {
    let isValid = true;

    if (processTitle === "") {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    return isValid;
  };

  // Fetch process details on component mount
  const getProcessDetails = async () => {
    try {
      const requestURL =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_PROCESS_DETAILS +
        `/${selectedProcessId}?token=${token}`;

      const response = await getData(requestURL); // Change api endpoint as needed

      if (response.status) {
        const data = response.data;

        setProcessTitle(data.title);
        setProcessLocation(data.location);
        setStartDate(data.startdate);
        setEndDate(data.enddate);
        setProcessOrder(data.order);
      }
    } catch (error) {
      console.error("Error fetching process details:", error);
    }
  };

  //update process
  const updateProcessHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        const processData = {
          title: processTitle,
          location: processLocation,
          startdate: startDate,
          enddate: endDate,
          order: processOrder,
        };

        const requestURL =
          url.API_BASE_URL +
          url.API_UPDATE_CHALLENGE_PROCESS +
          `/${selectedProcessId}?token=${token}`;

        const response = await putData(requestURL, processData);

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");

          const result = response.data;

          setTimeout(() => {
            if (result) {
              setProcessList((prevData) => {
                const existingIndex = processList.findIndex(
                  (process) => process._id.toString() === result._id.toString()
                );

                if (existingIndex !== -1) {
                  // task exists, update the existing record
                  const updatedData = [...prevData];
                  updatedData[existingIndex] = result;
                  return updatedData;
                }
              });
            }

            // close the modal
            let currentModal = document.querySelector("#updateProcessModal");
            let bootstrapModal = bootstrap.Modal.getInstance(currentModal);
            bootstrapModal.hide();
          }, 1000);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setAlertMessage("Please enter process title");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  const resetHandler = () => {
    setProcessTitle("");
    setProcessLocation("");
    setStartDate("");
    setEndDate("");
    setProcessOrder(processList.length + 1);
  };

  const closeModalHandler = () => {
    resetHandler();
    setSelectedProcessId(null);
  };

  useEffect(() => {
    if (selectedProcessId) {
      getProcessDetails();
    }
  }, [selectedProcessId]);

  return (
    <div
      className="modal fade"
      id="updateProcessModal"
      tabIndex="-1"
      aria-labelledby="updateProcessModalLabel"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content border-0 rounded-0 bg-gray-100">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="text-green w-100 mb-0" id="updateProcessModalLabel">
              Process
            </h2>
            <button
              type="button"
              className="btn-close bg-yellow text-primary rounded-0 shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
          </div>
          <div className="modal-body px-4 py-5">
            <div className="hiring-process">
              <div className="hiring-fields bg-gray-100 mb-4">
                <div className="row gx-2 mb-3">
                  <div className="col-md-10">
                    <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 pe-3">
                      <input
                        type="text"
                        name="title"
                        className="form-control shadow-none rounded-0 border-0"
                        placeholder="Process Name"
                        value={processTitle}
                        onChange={(e) => {
                          setProcessTitle(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            titleError: false,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <input
                        type="number"
                        name="order"
                        className="form-control shadow-none rounded-0 border-0"
                        min="1"
                        placeholder="Order"
                        value={processOrder}
                        onChange={(e) => setProcessOrder(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row gx-2">
                  <div className="col-md-6">
                    <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 pe-3">
                      <input
                        type="text"
                        name="location"
                        className="form-control shadow-none rounded-0 border-0"
                        placeholder="Location"
                        value={processLocation}
                        onChange={(e) => setProcessLocation(e.target.value)}
                      />
                      <i className="d-block material-icons text-gray-600">
                        my_location
                      </i>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <input
                        type="date"
                        name="fromdate"
                        className="form-control border-0 rounded-none shadow-none"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                      <input
                        type="date"
                        name="todate"
                        className="form-control border-0 rounded-none shadow-none"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action d-flex flex-wrap align-items-center justify-content-between gap-3">
              {validation.titleError && (
                <div className="error-message">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>{t("Please enter title")}</span>
                  </p>
                </div>
              )}{" "}
              <button
                type="button"
                className="btn btn-yellow rounded-0 ms-auto"
                onClick={updateProcessHandler}
                disabled={isSaving ? true : false}
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
              >
                <i className="material-icons me-2">save</i> Update Process
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProcessModal;
