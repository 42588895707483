/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";
import { getAllCategories } from "Helper/CommonHelper/CommonHelper";

import CommonLayout from "Components/Common/Layout/CommonLayout";
import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import ChallengeDetailsCommonHeader from "../Common/Header/ChallengeDetailsCommonHeader";

import remoteOptions from "data/Prod/RemoteOptions.json";
import jobTypeOptions from "data/Prod/JobTypeOptions.json";
import jobExpertiseOptions from "data/Prod/JobAssociateOptions.json";
import languageOptions from "data/Prod/LanguageOptions.json";
import currencyOptions from "data/Prod/Currency.json";
import paymentOptions from "data/Prod/PaymentDuration.json";

const ChallengeDetailsBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const { id: challengeId } = useParams();
  const history = useHistory();

  const editor = useRef(null);

  const [breadCrumbText, setBreadCrumbText] = useState([
    { title: t("Challenges"), link: "/admin/challenges/manage" },
    { title: "" },
  ]);

  const [skillList, setSkillList] = useState([]);

  const [title, settitle] = useState("");
  const [code, setcode] = useState("");
  const [noOfPositions, setNoOfPositions] = useState("");
  const [remoteJobValue, setRemoteJobValue] = useState(null);
  const [remoteJob, setRemoteJob] = useState("");
  const [employmentTypeValue, setEmploymentTypeValue] = useState(null);
  const [employmentType, setEmploymentType] = useState("");
  const [expertiseLevelValue, setExpertiseLevelValue] = useState(null);
  const [expertiseLevel, setExpertiseLevel] = useState("");
  const [experienceYears, setExperienceYears] = useState("");
  const [payRateValue, setPayRateValue] = useState(null);
  const [payRate, setPayRate] = useState("");
  const [minRate, setminRate] = useState("");
  const [maxRate, setmaxRate] = useState("");
  const [currencyValue, setCurrencyValue] = useState(null);
  const [currency, setCurrency] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [aboutCompany, setAboutCompany] = useState("");
  const [aboutPosition, setAboutPosition] = useState("");
  const [requiredSkillValue, setrequiredSkillValue] = useState(null);
  const [requiredSkills, setrequiredSkills] = useState([]);
  const [optionalSkillValue, setoptionalSkillValue] = useState(null);
  const [optionalSkills, setoptionalSkills] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [languageValue, setLanguageValue] = useState(null);
  const [language, setLanguage] = useState("");
  const [jobLocation, setJobLocation] = useState("");

  const [isSaving, setIsSaving] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [validation, setValidation] = useState({
    titleError: false,
    codeError: false,
    summaryError: false,
    detailsError: false,
    companyError: false,
    skillError: false,
    currencyError: false,
    minSalaryError: false,
    maxSalaryError: false,
    salaryDiffError: false,
    paymentDurationError: false,
  });

  const descriptionEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setDescription(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const aboutCompanyEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setAboutCompany(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const aboutPositionEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    minHeight: 300,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setAboutPosition(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const descriptionEditorConfig = useMemo(
    () => descriptionEditorCustomConfig,
    []
  );

  const aboutCompanyEditorConfig = useMemo(
    () => aboutCompanyEditorCustomConfig,
    []
  );

  const aboutPositionEditorConfig = useMemo(
    () => aboutPositionEditorCustomConfig,
    []
  );

  //function for validation
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    if (code === "") {
      setValidation((prevState) => ({ ...prevState, codeError: true }));
      isValid = false;
    }

    if (summary === "") {
      setValidation((prevState) => ({ ...prevState, summaryError: true }));
      isValid = false;
    }

    if (description === "") {
      setValidation((prevState) => ({ ...prevState, detailsError: true }));
      isValid = false;
    }

    if (companyName === "") {
      setValidation((prevState) => ({ ...prevState, companyError: true }));
      isValid = false;
    }

    if (requiredSkills.length === 0) {
      setValidation((prevState) => ({ ...prevState, skillError: true }));
      isValid = false;
    }

    if (currencyValue === null) {
      setValidation((prevState) => ({ ...prevState, currencyError: true }));
      isValid = false;
    }

    if (minRate === "") {
      setValidation((prevState) => ({ ...prevState, minSalaryError: true }));
      isValid = false;
    }

    if (maxRate === "") {
      setValidation((prevState) => ({ ...prevState, maxSalaryError: true }));
      isValid = false;
    }

    if (
      minRate !== "" &&
      maxRate !== "" &&
      parseInt(minRate) > parseInt(maxRate)
    ) {
      setValidation((prevState) => ({ ...prevState, salaryDiffError: true }));
      isValid = false;
    }

    if (payRateValue === null) {
      setValidation((prevState) => ({
        ...prevState,
        paymentDurationError: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //get all skills
  const getAllSkills = async () => {
    try {
      const skillParentSlug = "CAT_SKILL";

      const response = await getAllCategories(skillParentSlug);

      if (response) {
        setSkillList(response);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select remote job
  const remoteJobSelectionHandler = (val) => {
    if (val) {
      setRemoteJobValue(val);
      setRemoteJob(val.value);
    } else {
      setRemoteJobValue(null);
      setRemoteJob("");
    }
  };

  //function for select emplyment type
  const employmentTypeSelectionHandler = (val) => {
    if (val) {
      setEmploymentTypeValue(val);
      setEmploymentType(val.value);
    } else {
      setEmploymentTypeValue(null);
      setEmploymentType("");
    }
  };

  //function for select expertise label
  const expertiseLevelSelectionHandler = (val) => {
    if (val) {
      setExpertiseLevelValue(val);
      setExpertiseLevel(val.value);
    } else {
      setExpertiseLevelValue(null);
      setExpertiseLevel("");
    }
  };

  //function for payment duration
  const paymentDurationSelectionHandler = (val) => {
    if (val) {
      setPayRateValue(val);
      setPayRate(val.value);
    } else {
      setPayRateValue(null);
      setPayRate("");
    }
  };

  //select currency type
  const currencySelectionHandler = (val) => {
    if (val) {
      setCurrencyValue(val);
      setCurrency(val.value);
    } else {
      setCurrencyValue(null);
      setCurrency("");
    }
  };

  //select required skill
  const requiredSkillSelectionHandler = (val) => {
    setrequiredSkillValue(val);
    setrequiredSkills(val.map((item) => item.value));
  };

  //select optional skill
  const optionalSkillSelectionHandler = (val) => {
    setoptionalSkillValue(val);
    setoptionalSkills(val.map((item) => item.value));
  };

  //function for select language
  const languageSelectionHandler = (val) => {
    if (val) {
      setLanguageValue(val);
      setLanguage(val.value);
    } else {
      setLanguageValue(null);
      setLanguage("");
    }
  };

  //function for get job details
  const getJobDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${challengeId}?token=${token}`;

      const response = await getData(requestURL);

      console.log("response in job details ----->", response);

      if (response.status) {
        const data = response.data;

        setBreadCrumbText([
          { title: t("Challenges"), link: "/admin/challenges/manage" },
          { title: response.data.title },
        ]);

        settitle(data.title);
        setcode(data.jobcode);
        setNoOfPositions(data.positioncount);
        if (data.remotejob) {
          for (let remote of remoteOptions) {
            if (remote.value === data.remotejob) {
              remoteJobSelectionHandler(remote);
            }
          }
        }

        if (data.jobtype) {
          for (let jobType of jobTypeOptions) {
            if (jobType.value === data.jobtype) {
              employmentTypeSelectionHandler(jobType);
            }
          }
        }

        if (data.experienceposition) {
          for (let expertiseLevel of jobExpertiseOptions) {
            if (expertiseLevel.value === data.experienceposition) {
              expertiseLevelSelectionHandler(expertiseLevel);
            }
          }
        }

        setExperienceYears(data.experienceyear);

        if (data.paymentduration) {
          for (let paymentDuration of paymentOptions) {
            if (paymentDuration.value === data.paymentduration) {
              paymentDurationSelectionHandler(paymentDuration);
            }
          }
        }

        setminRate(data.minsalary);
        setmaxRate(data.maxsalary);

        if (data.currency) {
          for (let currency of currencyOptions) {
            if (currency.value === data.currency) {
              currencySelectionHandler(currency);
            }
          }
        }

        setSummary(data.summary);
        setDescription(data.description);

        if (data.requireskillvalues) {
          requiredSkillSelectionHandler(data.requireskillvalues);
        }

        if (data.optionalskillvalues) {
          optionalSkillSelectionHandler(data.optionalskillvalues);
        }

        setAboutCompany(data.aboutcompany);
        setAboutPosition(data.aboutposition);

        setCompanyName(data.companyname);
        setCompanyAddress(data.companyaddress);

        if (data.language) {
          for (let language of languageOptions) {
            if (language.value === data.language) {
              languageSelectionHandler(language);
            }
          }
        }

        setJobLocation(data.location);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for save challenge
  const saveJobHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        const jobData = {
          title,
          jobcode: code,
          positioncount: noOfPositions,
          remotejob: remoteJob,
          jobtype: employmentType,
          experienceposition: expertiseLevel,
          experienceyear: experienceYears,
          paymentduration: payRate,
          minsalary: minRate,
          maxsalary: maxRate,
          currency,
          summary,
          description,
          aboutcompany: aboutCompany,
          aboutposition: aboutPosition,
          requiredskills: requiredSkills,
          optionalskills: optionalSkills,
          companyid: null,
          companyname: companyName,
          companyaddress: companyAddress,
          language,
          location: jobLocation,
        };

        console.log("jobData ---->", jobData);

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (challengeId) {
          requestURL =
            requestURL +
            url.API_UPDATE_CHALLENGE +
            `/${challengeId}?token=${token}`;

          response = await putData(requestURL, jobData);
        } else {
          requestURL =
            requestURL + url.API_CREATE_CHALLENGE + `?token=${token}`;

          response = await postData(requestURL, jobData);
        }

        console.log(response);

        setIsSaving(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            history.push(`/admin/challanges/save/${response.data._id}`);
          }, 1500);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill up all required fields"));
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (challengeId) {
      getJobDetails();
    }
  }, [challengeId]);

  useEffect(() => {
    getAllSkills();
  }, []);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      {challengeId && <ChallengeDetailsCommonHeader moduleName="details" />}

      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={breadCrumbText} />

          <div id="job_info_form_wrapper">
            <div className="job-info-form-container p-4 bg-gray-100">
              {/* <!-- ========== Start title Section ========== --> */}
              <div className="form-row mb-5">
                <label
                  htmlFor="jobCode"
                  className="d-block fw-bold text-primary mb-3 "
                >
                  Job Title
                </label>
                <input
                  type="text"
                  className={`form-control rounded-0 shadow-none ${
                    validation.titleError ? "border-danger border-1" : ""
                  }`}
                  placeholder="Job Title"
                  value={title}
                  onChange={(e) => {
                    settitle(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      titleError: false,
                    }));
                  }}
                />
                {validation.titleError && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter job title")}</span>
                    </p>
                  </div>
                )}
              </div>
              {/* <!-- ========== End title Section ========== --> */}

              {/* <!-- ========== Start experience Section ========== --> */}
              <div className="experience mb-5">
                <label
                  htmlFor="experience"
                  className="d-block fw-bold text-primary mb-4"
                >
                  Basic Info
                </label>
                <div className="row gx-2 mb-3">
                  <div className="col-md-6">
                    <div
                      className={`form-row d-flex align-items-center bg-white border ps-3 ${
                        validation.codeError
                          ? "border-danger "
                          : "border-gray-600"
                      }`}
                    >
                      <label
                        htmlFor="jobcode"
                        className="text-nowrap me-4 text-primary"
                      >
                        Job Code
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-0 shadow-none border-0"
                        placeholder="indra-job-01"
                        value={code}
                        onChange={(e) => {
                          setcode(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            codeError: false,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="vacancies"
                        className="text-nowrap me-4 text-primary"
                      >
                        Number of positions
                      </label>
                      <input
                        type="number"
                        className="form-control rounded-0 shadow-none border-0"
                        placeholder=""
                        min={1}
                        value={noOfPositions}
                        onChange={(e) => {
                          setNoOfPositions(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row gx-2 mb-3">
                  <div className="col-md-6">
                    <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="remote_job"
                        className="text-nowrap me-4 text-primary"
                      >
                        Remote Job
                      </label>
                      <Select
                        styles={reactSelectStyle("6px", "0px", false, false)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="w-100"
                        placeholder="Select Remote Job"
                        options={remoteOptions}
                        value={remoteJobValue}
                        onChange={(val) => {
                          remoteJobSelectionHandler(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="jobType"
                        className="text-nowrap me-4 text-primary"
                      >
                        Employment Type
                      </label>
                      <Select
                        isClearable
                        styles={reactSelectStyle("6px", "0px", false, false)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="w-100"
                        placeholder="Select Employment Type"
                        options={jobTypeOptions}
                        value={employmentTypeValue}
                        onChange={(val) => {
                          employmentTypeSelectionHandler(val);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row gx-2 mb-3">
                  <div className="col-md-6">
                    <div className="form-row mb-3 mb-md-0 d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="jobExperience"
                        className="text-nowrap me-4 text-primary"
                      >
                        Level of expertise
                      </label>
                      <Select
                        styles={reactSelectStyle("6px", "0px", false, false)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="w-100"
                        placeholder="Select Level of expertise"
                        options={jobExpertiseOptions}
                        value={expertiseLevelValue}
                        onChange={(val) => {
                          expertiseLevelSelectionHandler(val);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="yearOfExperience"
                        className="text-nowrap me-4 text-primary"
                      >
                        Experience in years
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-0 shadow-none border-0"
                        value={experienceYears}
                        onChange={(e) => {
                          setExperienceYears(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                  <label
                    htmlFor="payRate"
                    className="text-nowrap me-4 text-primary"
                  >
                    Pay Rate
                  </label>
                  <Select
                    styles={reactSelectStyle("6px", "0px", false, false)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className={`w-100 ${
                      validation.paymentDurationError
                        ? "border border-danger"
                        : ""
                    }`}
                    placeholder="Select Pay Rate"
                    options={paymentOptions}
                    value={payRateValue}
                    onChange={(val) => {
                      paymentDurationSelectionHandler(val);
                      setValidation((prevState) => ({
                        ...prevState,
                        paymentDurationError: false,
                      }));
                    }}
                  />

                  <input
                    type="number"
                    className={`form-control rounded-0 shadow-none ${
                      validation.minSalaryError
                        ? "border border-danger"
                        : "border-0"
                    }`}
                    placeholder="Minimum"
                    value={minRate}
                    onChange={(e) => {
                      setminRate(e.target.value.toString());
                      setValidation((prevState) => ({
                        ...prevState,
                        minSalaryError: false,
                        salaryDiffError: false,
                      }));
                    }}
                  />

                  <input
                    type="number"
                    className={`form-control rounded-0 shadow-none ${
                      validation.maxSalaryError
                        ? "border border-danger"
                        : "border-0"
                    }`}
                    placeholder="Maximum"
                    value={maxRate}
                    onChange={(e) => {
                      setmaxRate(e.target.value.toString());
                      setValidation((prevState) => ({
                        ...prevState,
                        maxSalaryError: false,
                        salaryDiffError: false,
                      }));
                    }}
                  />

                  <Select
                    styles={reactSelectStyle("6px", "0px", false, false)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className={`w-100 ${
                      validation.currencyError ? "border border-danger" : ""
                    }`}
                    placeholder="Select Currency"
                    options={currencyOptions}
                    value={currencyValue}
                    onChange={(val) => {
                      currencySelectionHandler(val);
                      setValidation((prevState) => ({
                        ...prevState,
                        currencyError: false,
                      }));
                    }}
                  />
                </div>

                <div className="d-flex align-items-center gap-2 mt-2">
                  {validation.codeError && (
                    <div className="error-message">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please enter job code")}</span>
                      </p>
                    </div>
                  )}

                  {validation.paymentDurationError && (
                    <div className="error-message">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please select pay rate")}</span>
                      </p>
                    </div>
                  )}

                  {validation.minSalaryError && (
                    <div className="error-message">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please enter minimum salary")}</span>
                      </p>
                    </div>
                  )}

                  {validation.maxSalaryError && (
                    <div className="error-message">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please enter maximum salary")}</span>
                      </p>
                    </div>
                  )}

                  {validation.salaryDiffError && (
                    <div className="error-message">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>
                          {t(
                            "Maximum salary should be greater than minimum salary"
                          )}
                        </span>
                      </p>
                    </div>
                  )}

                  {validation.currencyError && (
                    <div className="error-message">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please select currency")}</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* <!-- ========== End experience Section ========== --> */}

              {/* <!-- ========== Start details Section ========== --> */}
              <div className="job-description mb-5">
                <label
                  htmlFor="jobDescription"
                  className="d-block fw-bold text-primary mb-4"
                >
                  Detail Information
                </label>

                <div className="form-row mb-4">
                  <label
                    htmlFor="jobSummary"
                    className="d-block text-primary mb-3"
                  >
                    Summary
                  </label>
                  <textarea
                    className={`form-control rounded-0 shadow-none ${
                      validation.summaryError ? "border-danger" : ""
                    }`}
                    placeholder=""
                    rows="6"
                    value={summary}
                    onChange={(e) => {
                      setSummary(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        summaryError: false,
                      }));
                    }}
                  ></textarea>
                  {validation.summaryError && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please enter job summary")}</span>
                      </p>
                    </div>
                  )}
                </div>

                <div className="form-row mb-4">
                  <label
                    htmlFor="jobDetails"
                    className="d-block text-primary mb-3"
                  >
                    Job Description
                  </label>
                  <div
                    className={`border ${
                      validation.detailsError
                        ? "border-danger"
                        : "border-gray-600"
                    }`}
                  >
                    <JoditEditor
                      ref={editor}
                      value={description}
                      config={descriptionEditorConfig}
                      onBlur={(newContent) => {
                        setDescription(newContent);
                      }}
                      onChange={(newContent, event) => {}}
                    />
                  </div>
                  {validation.detailsError && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-icons-outlined">warning</span>
                        <span>{t("Please enter job details")}</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="form-row mb-4">
                  <label
                    htmlFor="aboutCompany"
                    className="d-block text-primary mb-3"
                  >
                    About Company
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={aboutCompany}
                    config={aboutCompanyEditorConfig}
                    onBlur={(newContent) => {
                      setAboutCompany(newContent);
                    }}
                    onChange={(newContent, event) => {}}
                  />
                </div>
                <div className="form-row mb-4">
                  <label
                    htmlFor="aboutPosition"
                    className="d-block text-primary mb-3"
                  >
                    About Position
                  </label>
                  <JoditEditor
                    ref={editor}
                    value={aboutPosition}
                    config={aboutPositionEditorConfig}
                    onBlur={(newContent) => {
                      setAboutPosition(newContent);
                    }}
                    onChange={(newContent, event) => {}}
                  />
                </div>
              </div>
              {/* <!-- ========== End details Section ========== --> */}

              {/* <!-- ========== Start skills Section ========== --> */}
              <div className="add-skills mb-5">
                <label
                  htmlFor="addSkills"
                  className="d-block fw-bold text-primary mb-3"
                >
                  Add Skills
                </label>
                <div
                  className={`form-row mb-4 d-flex align-items-center bg-white border ps-3 ${
                    validation.skillError ? "border-danger" : "border-gray-600"
                  }`}
                >
                  <span className="text-nowrap me-4 text-primary">
                    Required Skills
                  </span>
                  <Select
                    isMulti
                    styles={reactSelectStyle("6px", "0px", false, false)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="w-100"
                    placeholder="Select Required Skills"
                    options={skillList}
                    value={requiredSkillValue}
                    onChange={(val) => {
                      requiredSkillSelectionHandler(val);
                    }}
                  />
                </div>
                <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                  <span className="text-nowrap me-4 text-primary">
                    Optional Skills
                  </span>
                  <Select
                    isMulti
                    styles={reactSelectStyle("6px", "0px", false, false)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    className="w-100"
                    placeholder="Select Optional Skills"
                    options={skillList}
                    value={optionalSkillValue}
                    onChange={(val) => {
                      optionalSkillSelectionHandler(val);
                    }}
                  />
                </div>

                {validation.skillError && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>
                        {t("Please select at least one required skill")}
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {/* <!-- ========== End skills Section ========== --> */}

              {/* <!-- ========== Start company Section ========== --> */}
              <div className="company mb-5">
                <label
                  htmlFor="company"
                  className="d-block fw-bold text-primary mb-4"
                >
                  Company
                </label>

                <div className="row gx-2 mb-3">
                  <div className="col-md-6">
                    <div
                      className={`form-row d-flex align-items-center bg-white border ps-3 ${
                        validation.companyError
                          ? "border-danger"
                          : "border-gray-600"
                      }`}
                    >
                      <label
                        htmlFor="jobcode"
                        className={"text-nowrap me-4 text-primary"}
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-0 border-0 shadow-none"
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            companyError: false,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="vacancies"
                        className="text-nowrap me-4 text-primary"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-0 border-0 shadow-none"
                        placeholder=""
                        value={companyAddress}
                        onChange={(e) => {
                          setCompanyAddress(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            companyError: false,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                {validation.companyError && (
                  <div className="error-message mt-2">
                    <p className="d-flex align-items-center gap-1 text-danger">
                      <span className="material-icons-outlined">warning</span>
                      <span>{t("Please enter company name")}</span>
                    </p>
                  </div>
                )}
              </div>
              {/* <!-- ========== End company Section ========== --> */}

              {/* <!-- ========== Start other information Section ========== --> */}
              <div className="company mb-5">
                <label
                  htmlFor="company"
                  className="d-block fw-bold text-primary mb-4"
                >
                  Other Information
                </label>

                <div className="row gx-2">
                  <div className="col-md-6">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="country"
                        className="text-nowrap me-4 text-primary"
                      >
                        Language
                      </label>
                      <Select
                        isClearable
                        styles={reactSelectStyle("6px", "0px", false, false)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        className="w-100"
                        placeholder="Select Language"
                        options={languageOptions}
                        value={languageValue}
                        onChange={(val) => {
                          languageSelectionHandler(val);
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-row d-flex align-items-center bg-white border border-gray-600 ps-3">
                      <label
                        htmlFor="vacancies"
                        className="text-nowrap me-4 text-primary"
                      >
                        Job Location
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-0 shadow-none border-0"
                        value={jobLocation}
                        onChange={(e) => {
                          setJobLocation(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- ========== End other information Section ========== --> */}

              {/* <!-- ========== Start button Section ========== --> */}
              <div className="action-container d-flex align-items-center justify-content-end">
                <Link
                  to="/admin/challenges/manage"
                  className="btn btn-gray rounded-0 me-3"
                >
                  Cancel
                </Link>
                <button
                  type="button"
                  className="btn btn-yellow rounded-0"
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                  onClick={saveJobHandler}
                >
                  Save
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              {/* <!-- ========== End button Section ========== --> */}
            </div>
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default ChallengeDetailsBody;
