/* eslint-disable */
import { useState, useEffect, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, postData, putData, uploadSingleFile } from "Utils/Gateway";

import { reactSelectStyle } from "Config/Config";
import { getCurrentTime } from "Helper/CommonHelper/CommonHelper";

const ViewAndReplyEmailPopup = ({
  selectedMailId = null,
  setSelectedMailId = () => {},
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
  reloadList = () => {},
  repliedToUserEmail = "",
}) => {
  const { t } = useTranslation();
  const token = localStorage.getItem("token");

  // loggedin user information
  const currentUser = JSON.parse(localStorage.getItem("userInfo"));

  const [senderName, setsenderName] = useState("");
  const [senderEmail, setsenderEmail] = useState("");
  const [reciverName, setreciverName] = useState("");
  const [mailSendingDate, setmailSendingDate] = useState("");
  const [mailSendingTime, setmailSendingTime] = useState("");
  const [mailReadDate, setmailReadDate] = useState("");
  const [mailReadTime, setmailReadTime] = useState("");
  const [mailSubject, setmailSubject] = useState("");
  const [mailTemplateId, setmailTemplateId] = useState(null);

  const [mailDescription, setMailDescription] = useState("");

  const [repliedMailDescription, setrepliedMailDescription] = useState("");
  const [mailSending, setMailSending] = useState(false);

  const editor = useRef(null);

  // const editorCustomConfig = joditEditorConfig();
  const editorCustomConfig = {
    readonly: false,
    placeholder: "",
    height: 350,
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setrepliedMailDescription(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const editorConfig = useMemo(() => editorCustomConfig, []);

  //function for get mail details
  const getMailDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_MESSAGE_DETAILS +
        `/${selectedMailId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setsenderName(response.data.sendername);
        setsenderEmail(
          repliedToUserEmail === ""
            ? response.data.sendermail
            : repliedToUserEmail
        );
        setreciverName(response.data.receivername);
        setmailSendingDate(response.data.maildate);
        setmailSendingTime(response.data.mailtime);
        setmailReadDate(response.data.mailopeneddateformatted);
        setmailReadTime(response.data.mailopeningtime);
        setMailDescription(response.data.description);
        setmailSubject(response.data.subject);
        setmailTemplateId(response.data.messagetemplate);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for update mail for read/unread
  const updateCurrentMail = async () => {
    try {
      let updateMailData = {
        tracker: "2",
        mailopened: true,
        mailopeneddate: new Date().toISOString().split("T")[0],
        mailopeningtime: getCurrentTime(),
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_MESSAGE +
        `/${selectedMailId}?token=${token}`;

      const response = await putData(requestURL, updateMailData);

      console.log(response);

      if (response.status) {
        getMailDetails();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const replyMailHandler = async () => {
    const templateId = mailTemplateId ?? null;

    let mailData = {
      parentmessage: selectedMailId,
      receivermails: senderEmail,
      subject: `Re: ${mailSubject}`,
      sender: currentUser._id,
      description: repliedMailDescription,
      messagetemplate: templateId,
    };

    console.log("mailData=======>", mailData);

    try {
      setMailSending(true);

      let requestURL =
        url.API_BASE_URL + url.API_ADD_MESSAGE + `?token=${token}`;

      const response = await postData(requestURL, mailData);

      console.log("Mail Response", response);

      setMailSending(false);

      if (response.status) {
        setMessageType("success");

        resetHandler();

        reloadList();

        document.body.classList.toggle("reply_openPopup");
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
      setShowAlert(true);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  const resetHandler = () => {
    setsenderName("");
    setmailSubject("");
    setsenderEmail("");
    setreciverName("");
    setmailSendingDate("");
    setmailReadDate("");
    setmailTemplateId(null);
    setMailDescription("");
    setrepliedMailDescription("");
  };

  useEffect(() => {
    if (selectedMailId) {
      updateCurrentMail();
    }
  }, [selectedMailId]);

  return (
    <div className="email_poupPnl email_msg_rply_View">
      <div className="email_topPnl">
        <div className="emailtop_row">
          <div className="emailuser_outer">
            <div className="email_cross">
              <Link
                to="#"
                onClick={() => {
                  document.body.classList.toggle("reply_openPopup");
                  setSelectedMailId(null);
                }}
              >
                <i className="material-icons-round"> close</i>
              </Link>
            </div>
            <div className="email_userInfo">
              <h3 id="mailview-subject">{mailSubject}</h3>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="email_editorupload_pnl subjct_removePnl">
          <div className="email_msg_row">
            <div className="mail_infotxt">
              <ul>
                <li>
                  <label>From</label>
                  <span className="placeholder-glow" id="mailview-sender">
                    {senderName}
                  </span>
                </li>
                <li>
                  <label>To</label>
                  <span className="placeholder-glow" id="mailview-receiver">
                    {reciverName}
                  </span>
                </li>
                <li className="time_txt">
                  <label>Date</label>
                  <span className="placeholder-glow" id="mailview-created">
                    {mailSendingDate}
                  </span>{" "}
                  <span className="ms-2 text-gray-600">at</span>{" "}
                  <span className="placeholder-glow ms-2" id="mailview-created">
                    {mailSendingTime}
                  </span>
                  {/* <span className="placeholder-glow ms-4" id="mailview-read">
                    <span className="material-icons-outlined text-gray me-1">
                      {" "}
                      mark_email_read{" "}
                    </span>
                    {mailReadDate} {mailReadTime}
                  </span> */}
                </li>
              </ul>
            </div>
            <div
              className="email_message_body"
              id="mailview-content"
              style={{ paddingLeft: "14%" }}
            >
              <p dangerouslySetInnerHTML={{ __html: mailDescription }}></p>
            </div>
          </div>
          {/* <div className="email_msg_row reply_msgRow">
            <div className="emailtop_row">
              <div className="emailuser_outer">
                <div className="email_userInfo">
                  <h3>Reply to</h3>
                  <Link to="#" id="mailview-senderreply">
                    {senderEmail}
                  </Link>
                </div>
              </div>
            </div>

            <div className="edior_textPnl">
              <JoditEditor
                ref={editor}
                value={repliedMailDescription}
                config={editorConfig}
                onBlur={(newContent) => {
                  setrepliedMailDescription(newContent);
                }}
                onChange={(newContent, event) => {}}
              />
            </div>
          </div> */}
        </div>
        {/* <div className="bttm_send_pnl">
          <div className="upload_sendOuter reply_sendbtnOuter">
            <div className="email_uploadBtn"></div>
            <div className="dlete_sendBtnOuter">
              <button
                className="btn btn-info"
                onClick={replyMailHandler}
                style={{ cursor: mailSending ? "not-allowed" : "pointer" }}
                disabled={mailSending ? true : false}
              >
                {t("Send")}
                {mailSending ? (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <i className="material-icons ms-2">send</i>
                )}
              </button>
            </div>
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default ViewAndReplyEmailPopup;
