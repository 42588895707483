/* eslint-disable */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "Helper/UrlHelper";
import { getData, putData } from "Utils/Gateway";

import AlertNotification from "Components/Common/AlertNotification/AlertNotification";
import BreadCrumb from "Components/Common/BreadCrumb/BreadCrumb";
import Loading from "Components/Common/Loading/Loading";
import CommonLayout from "Components/Common/Layout/CommonLayout";
import ChallengeMemberViewHeader from "./Header/ChallengeMemberViewHeader";

const ChallengeMemberViewBody = () => {
  const { t, i18n } = useTranslation();

  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedJobIds, setSelectedJobIds] = useState([]);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get list
  const getAllChallenges = async () => {
    setRowSelection({});
    setSelectedJobIds([]);

    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE +
        `?token=${token}&modulefrom=backend&moduleview=member`;

      console.log("Job url", requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setJobList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getAllChallenges();
  }, []);

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "title",
      header: t("Name"),
      size: 200,
      Cell: ({ row }) => (
        <div class="event-name">
          <h6 class="mb-2">{row.original.title}</h6>
          <p class="text-gray">{row.original.jobcode}</p>
        </div>
      ),
    },
    {
      accessorKey: "moderatorname",
      header: t("moderator"),
    },
    {
      accessorKey: "reviewtotal",
      header: t("Responses"),
      size: 150,
      Cell: ({ row }) => (
        <div className="status">
          <p className="mb-2">
            <Link to="#" className="d-inline-block text-primary me-3">
              Submitted
            </Link>
            <span>{row.original.challengeresponsecount}</span>
          </p>

          <p className="mb-2">
            <Link to="#" className="d-inline-block text-success me-3">
              Accepted
            </Link>
            <span>{row.original.acceptedresponsecount}</span>
          </p>

          <p className="mb-2">
            <Link to="#" className="d-inline-block text-warning me-3">
              Reviewing
            </Link>
            <span>{row.original.pendingresponsecount}</span>
          </p>

          <p>
            <Link to="#" className="d-inline-block text-danger me-3">
              Rejected
            </Link>
            <span>{row.original.rejectedresponsecount}</span>
          </p>
        </div>
      ),
    },
    {
      accessorKey: "requiredskillstring",
      header: t("Skills"),
      size: 150,
      Cell: ({ row }) => (
        <div className="">
          {row.original.requiredskilllist.map((skill, index) => {
            return (
              <p key={index} className="text-primary">
                {skill.name}{" "}
              </p>
            );
          })}
        </div>
      ),
    },
    {
      accessorKey: "challengeprocessstring",
      header: t("Process"),
      size: 220,
      Cell: ({ row }) => (
        <div className="">
          {row.original.challengeprocesslist.map((process, index) => {
            return (
              <p key={index} className="text-primary">
                {process.title}
                {index !== row.original.challengeprocesslist.length - 1 && ","}
              </p>
            );
          })}
        </div>
      ),
    },
    {
      accessorKey: "postededdate",
      header: t("Added On"),
    },
  ];

  /* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [jobList, i18n.language]);

  useEffect(() => {
    // console.log("rowSelection", rowSelection);
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedJobIds(selectedIdsArray);
    } else {
      setSelectedJobIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <CommonLayout>
      <section id="main_content">
        <div className="container-fluid px-md-4">
          <BreadCrumb breadCrumbText={[{ title: t("Challenges") }]} />

          <ChallengeMemberViewHeader reloadList={getAllChallenges} />

          <div className="table-wrapper mb-5">
            <MaterialReactTable
              columns={columns} // map columns to be displayed with api data,
              data={jobList} // data from api to be displayed
              enableGrouping // to enable grouping of column
              enableRowSelection // enable showing checkbox
              getRowId={(row) => `${row._id}`} // map which value to select with row checkbox
              onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
              state={{
                rowSelection,
              }} //pass our managed row selection state to the table to use
              initialState={{
                rowSelection,
              }}
              enableRowActions // show the action column
              enableColumnOrdering={true}
              renderRowActionMenuItems={({ row }) => {
                const menuItems = [
                  <MenuItem key="responses">
                    <Link
                      className="dropdown-item"
                      to={`/admin/challanges/responses/report/${row.id}`}
                    >
                      {t("Responses")}
                    </Link>
                  </MenuItem>,
                ];

                return menuItems;
              }} // action columns menu items
              //   onColumnVisibilityChange={onColumnVisiblityHandler}
              //   onColumnOrderChange={changeColumnOrderHandler}
              muiTableContainerProps={{
                sx: {
                  maxHeight: "60vh",
                },
              }}
              enableStickyHeader
              muiSearchTextFieldProps={{
                placeholder: t("Search by name / moderator"),
              }}
            />
          </div>
        </div>
      </section>

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </CommonLayout>
  );
};

export default ChallengeMemberViewBody;
